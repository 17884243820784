import { useContext } from 'react';

import { ToastComponent } from '../../components/ToastComponent';
import {
  PUT_RECHARGECOUNTER,
  PUT_RECHARGECOUNTER_SUCCESS,
  PUT_RECHARGECOUNTER_ERROR,
} from '../../constants/constantsRed';
import { StoreContext } from '../../context/Provider';
import { getCurrentPosition } from '../../utils/utils';
import useApi from '../../services/request.service';

export const useRechargeCourter = () => {
  const { putRechargeCounterState } = useContext(StoreContext)
  const { genericApiCallApiBilling } = useApi();

  const putRechargeCounter = async (data: any) => {
    putRechargeCounterState.dispatch({ type: PUT_RECHARGECOUNTER });
    try {
      let coords = await getCurrentPosition();

      const headers = {
        locationGPS: `${coords.latitude}, ${coords.latitude}`,
        appId: 'web',
      };
      const result: any = await genericApiCallApiBilling(
        '/v1.0/sam-inventory/counters',
        'PUT',
        data,
        {},
        headers
      );
      if (result.status === 200) {
        const payload = result.data;
        putRechargeCounterState.dispatch({
          type: PUT_RECHARGECOUNTER_SUCCESS,
          payload: payload,
        });
        ToastComponent('success', '', 'Contadores agregados correctamente');
      }else{
        const message = result?.response?.data?.message;
        ToastComponent('warning', '', message);
        putRechargeCounterState.dispatch({
          type: PUT_RECHARGECOUNTER_ERROR,
          payload: result?.response?.data
        });
      }
      return result;
    } catch (error: any) {
      ToastComponent(
        'warning',
        'Error',
        'La operación falló, inetnet más tarde por favor.'
      );
      putRechargeCounterState.dispatch({
        type: PUT_RECHARGECOUNTER_ERROR,
        payload: error.message,
      });
      return 'error';
    }
  };

  return { putRechargeCounter };
};

import {
  AccountInfo,
  AuthenticationResult,
  AuthError,
  EventType,
  InteractionType,
  IPublicClientApplication,
  PublicClientApplication,
} from '@azure/msal-browser';
import { useAccount, useMsal } from '@azure/msal-react';
import axios from 'axios';
import { useEffect, useState } from 'react';
import urlJoin from 'url-join';

import {
  loginRequest,
  msalConfig,
  TOKEN_EXP_STORAGE,
  TOKEN_STORAGE,
} from './msalConstants';
import MsalLogin from './MsalLogin';
import Cookies from 'universal-cookie';
const cookies = new Cookies(null, { path: '/' });
class MsalSingleton {
  private static instance: MsalSingleton;
  private msalInstance: PublicClientApplication;

  private constructor(config: any) {
    this.msalInstance = new PublicClientApplication(config);
  }

  public static getInstance(config: any): MsalSingleton {
    if (!MsalSingleton.instance) {
      MsalSingleton.instance = new MsalSingleton(config);
    }
    return MsalSingleton.instance;
  }

  public getMsalInstance(): PublicClientApplication {
    return this.msalInstance;
  }
}

/**
 * Clase Auth Service tiene el propósito de gestionar la aplicación para centralizar el Login, Logoff y obtener token de acceso
 */
class AuthService {
  msal!: MsalSingleton;

  /**
   * Este inicializa la instancia del msal y habilita el seguimiento al callback de msal
   */
  createMsal = async () => {
    this.msal = MsalSingleton.getInstance(msalConfig);

    this.msal.getMsalInstance().addEventCallback(event => {
      // console.log('eventCallback', event, event.payload);
      if (
        event.eventType === EventType.LOGIN_SUCCESS &&
        event.interactionType === InteractionType.Redirect
      ) {
        //console.log('token success');
        const authResp = event.payload as AuthenticationResult;
        this.msal.getMsalInstance().setActiveAccount(authResp.account);
        localStorage.setItem('userId', authResp.account.localAccountId);
        localStorage.setItem(TOKEN_STORAGE, authResp.accessToken);
        localStorage.setItem(
          TOKEN_EXP_STORAGE,
          `${authResp.expiresOn?.valueOf()}`
        );
      }
    });
  };

  initConfiguration = async () => {
    try {
      const apiUrl = urlJoin(
        process.env.REACT_APP_SECURITYAPIURI || '',
        '/app/web/settings?encrypted=false'
      );

      var response: any = await axios.get(apiUrl, {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
      });

      let data = response.data[0].values;
      msalConfig.auth.clientId = data['cks:ClientId'];
      msalConfig.auth.tenantId = data['cks:TenantId'];
      msalConfig.auth.knownAuthorities = [`${data['cks:TenantName']}.b2clogin.com`];
      (msalConfig.auth.authority = `https://${data['cks:TenantName']}.b2clogin.com/${data['cks:TenantName']}.onmicrosoft.com/${
        data['cks:SignInPolicy']
      }`),
        (msalConfig.auth.redirectUri = window.location.href || '');
      msalConfig.auth.postLogoutRedirectUri =
        process.env.REACT_APP_REDIRECTURI || '';

      loginRequest.scopes = data['cks:Scopes'];
    } catch (error) {
      console.log('error del msal');
      console.log(error);
    }
  };

  /**
   * Cerrar sesión con redireccionamiento
   */
  async logout() 
  {
    try 
    {
      let lastColumns       = localStorage.getItem('columns_order')
      const account         = this.msal.getMsalInstance().getActiveAccount();

      localStorage.clear();
      if(account?.localAccountId != null)
      {
        cookies.set(`${account?.localAccountId}|ColumnOrder`, lastColumns ?? "", { maxAge: 30 * 24 * 60 * 60 });
      }
      await this.msal.getMsalInstance().logoutRedirect({ account: account });
    } catch (error) 
    {
      console.error('Error al cerrar sesión:', error);
    }
  }

  /**
   *Verifica si el token existente esta o no vencido
   * @returns si esta o no exporado el token actual. en caso de no haber token, regresa que si esta vencido
   */
  isTokenExpired() {
    const exp = localStorage.getItem(TOKEN_EXP_STORAGE);
    if (exp) {
      return Number(exp) - Date.now().valueOf() < 0;
    }
    return true;
  }

  /**
   *  Obtener token de autenticación
   *  obtiene token de manera silenciosa
   */
  async getAccessToken(
    instance: IPublicClientApplication,
    account: AccountInfo | null
  ) {
    //console.log('get token');
    if (this.isTokenExpired()) {
      //console.log('token expirado, a regenerarlo')

      try {
        //console.log('before tokenSilent',account);

        if (account && this.isTokenExpired()) {
          const response = await instance.acquireTokenSilent({
            ...loginRequest,
            account,
          });
          localStorage.setItem(TOKEN_STORAGE, response.accessToken);
          localStorage.setItem(
            TOKEN_EXP_STORAGE,
            `${response.expiresOn?.valueOf()}`
          );
        }
      } catch (error) {
        console.error('Error al obtener el token de acceso:', error);

        if (
          error instanceof AuthError &&
          error.errorCode === 'consent_required'
        ) {
          localStorage.removeItem(TOKEN_STORAGE);
          localStorage.removeItem(TOKEN_EXP_STORAGE);

          // Si se requiere el consentimiento del usuario, obtener el token interactuando con redireccionamiento
          await instance.acquireTokenRedirect({
            ...loginRequest,
          });
        } else {
          this.logout()
          console.error('Error al obtener el token de acceso:', error);
        }
      }
      return undefined;
    } else {
      const token = localStorage.getItem(TOKEN_STORAGE);
      return token;
    }
  }

  // Obtener datos del usuario
  getUserData() {
    const account = this.msal.getMsalInstance().getActiveAccount();
    if (account) {
      return {
        username: account.idTokenClaims?.preferred_username,
        name: account.idTokenClaims?.name,
        email: account.idTokenClaims?.emails,
        // Agregar más datos de usuario si es necesario
      };
    }
    return null;
  }
}

var authService = new AuthService();

// Hook personalizado para usar el objeto AuthService
function useAuthService() {
  const msalInstance = useMsal();
  const account = useAccount();

  // Incluir métodos adicionales en la clase AuthService según sea necesario

  return {
    msalInstance,
    account,
    logout: () => authService.logout(),
    getAccessToken: (
      instance: IPublicClientApplication,
      account: AccountInfo | null
    ) => authService.getAccessToken(instance, account),
    getUserData: () => authService.getUserData(),
  };
}

export { MsalLogin, useAuthService, authService, MsalSingleton };

import { ITypeChartOptions } from "../../../interfaces/ITypeChartOptions"
import { ITypeChart } from "../../../interfaces/ITypeChart"
import { OptionBar } from "./options/OptionBar"
import { OptionStackedBar } from "./options/OptionStackedBar"
import { OptionLine } from "./options/OptionLine"
import { OptionPie } from "./options/OptionPie"
import { OptionRadar } from "./options/OptionRadar"



const OptionsChart = (type:ITypeChartOptions) => {
    switch (type) {
        case ITypeChartOptions.Bar:
            return OptionBar()
        case ITypeChartOptions.StackedBar:
            return OptionStackedBar()
        case ITypeChartOptions.Line:
            return OptionLine()
        case ITypeChartOptions.Pie:
            return OptionPie()
        case ITypeChartOptions.Radar:
            return OptionRadar()
    }
}


export const DefaultTypeCharts = (): ITypeChart[] =>{
    return (
        [
            {id:1,type:"bar", label:"Barras", options: OptionsChart(ITypeChartOptions.Bar)},
            {id:2, type:"bar", label: "Barras apiladas", options:OptionsChart(ITypeChartOptions.StackedBar)},
            {id:3, type:"line", label: "Lineas", options:OptionsChart(ITypeChartOptions.Line)},
        ]
    )
}
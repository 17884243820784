import { GET_INVENTORY, GET_INVENTORY_ERROR, GET_INVENTORY_EXCEL, GET_INVENTORY_EXCEL_ERROR, GET_INVENTORY_EXCEL_SUCCESS, GET_INVENTORY_SUCCESS } from "../../../constants/inventory";

export const getInventory_Red = (state: any = false, actions: any) => {
  switch (actions.type) {
    case GET_INVENTORY:
      return {
        ...state,
        loading: true,
      };
    case GET_INVENTORY_ERROR:
      return {
        data: null,
        loading: false,
        error: null,
      };
    case GET_INVENTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: actions.payload,
      };
      case GET_INVENTORY_EXCEL:
      return {
        ...state,
        loading: true,
      };
    case GET_INVENTORY_EXCEL_ERROR:
      return {
        data: null,
        loading: false,
        error: null,
      };
    case GET_INVENTORY_EXCEL_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: actions.payload,
      };
    default:
      return state;
  }
};

import { FormikErrors, FormikTouched, FormikValues, useFormik } from 'formik';
import { classNames } from 'primereact/utils';
import { useContext, useEffect, useState } from 'react';
import { InterfaceInput } from '../../../../interfaces/interfaces';

import { ButtonComponent } from '../../../../components/ButtonComponen';
import { InputComponent } from '../../../../components/InputComponent';
import { StoreContext } from '../../../../context/Provider';
import { useDeviceTypesCatalogs } from '../../../../hooks/catalogs/useDeviceTypesCatalogs';
import { useEconomicNumbersCatalogs } from '../../../../hooks/catalogs/useEconomicNumbersCatalogs';
import { usePointsOfSalesCatalog } from '../../../../hooks/catalogs/usePointsOfSalesCatalog';
import { usegetProjectsCatalogs } from '../../../../hooks/catalogs/useProjectsCatalog';
import { useRoutesCatalogs } from '../../../../hooks/catalogs/useRoutesCatalogs';
import { useStationsCatalogs } from '../../../../hooks/catalogs/useStationsCatalogs';
import { useCatalogs } from '../../../../hooks/inventory/useCatalogs';
import { useEditSam } from '../../../../hooks/inventory/useEditSam';
import { IStations } from '../../../../interfaces/interfaceCatalogs';
import { IEditSam } from '../../../../interfaces/interfaceSamData';
import {
  DeviceTypes,
  ICetDevice,
  IPosDevice,
  IVrtDevice,
  objAsCauDevice,
  objAsCetDevice,
  objAsGranjaDevice,
  objAsPosDevice,
  objAsVrtDevice,
} from '../../../../interfaces/interfacesDeviceTypeForms';
import {
  createObjectAsInterface,
  eliminarNull,
  getCurrentPosition,
} from '../../../../utils/utils';
import { CauDeviceForm } from './forms/CauDeviceForm';
import { CetDeviceForm } from './forms/CetDeviceForm';
import { GranjaDeviceForm } from './forms/GranjaDeviceForm';
import { PosDeviceForm } from './forms/PosDeviceForm';
import { VrtDeviceForm } from './forms/VrtDeviceForm';

//Constants

const dropdownClassname =
  'w-full rounded-md border border-gray-600 text-gray-400 border-opacity-20 px-1.5 p-inputtext-sm mt-2 mb-7';

interface IInputForm {
  typedName:
    | 'IdSocket'
    | 'Address'
    | 'NumberDevice'
    | 'CarPlates'
    | 'EconomicNumber'
    | 'RouteName'
    | 'Observations'
    | 'Station'
    | 'Project'
    | 'TechnologicalOperator';
}

export type IRenderInput = InterfaceInput & IInputForm;

export const EditSamMenu = (props: IEditSam) => {
  let data = props.row;
  const { setOpenRightMenu, updateTable } = props;

  // states

  const [showMessage, setShowMessage] = useState(false);
  const [deviceTypes, setDeviceTypes] = useState(false);

  const [operatorsList, setOperatorsList] = useState<any>([]);
  const [economicNumbers, setEconomicNumbers] = useState<any>([]);
  const [stations, setStations] = useState<IStations[]>([]);
  const [projects, setProjects] = useState<any>([]);
  const [pos, setPos] = useState<any>([]);
  const [routes, setRoutes] = useState<any>([]);

  const { putEditSam } = useEditSam();

  const { getOperatorsCatalog } = useCatalogs();
  const { getDeviceTypesCatalog } = useDeviceTypesCatalogs();

  const [inputs, setInputs] = useState<
    IVrtDevice | ICetDevice | IPosDevice | any
  >();

  const {
    getEconomicNumbersCatalogState,
    getRoutesCatalogState,
    getStationsCatalogState,
    getProjectsCatalogState,
    getPointsOfSalesCatalogState,
  } = useContext(StoreContext);

  const { getEconomicNumbersCatalog } = useEconomicNumbersCatalogs();
  const { getRoutesCatalog } = useRoutesCatalogs();
  const { getStationsCatalog } = useStationsCatalogs();
  const { getProjectsCatalog } = usegetProjectsCatalogs();
  const { getPointsOfSalesCatalog } = usePointsOfSalesCatalog();

  const close = () => {
    setOpenRightMenu(false);
  };

  useEffect(() => {
    async function getDevices() {
      let response = await getDeviceTypesCatalog();
      if (response?.['status'] === 200) {
        setDeviceTypes(response.data.payload);
      }
    }
    getDevices();
    getEconomicNumbersCatalog();
    getRoutesCatalog();
    getStationsCatalog();
    getProjectsCatalog();
    selectForm();
    getPointsOfSalesCatalog();
  }, []);

  function selectForm() {
    let objInput: any;
    let filterObject: IVrtDevice | ICetDevice | IPosDevice;

    switch (data.DeviceTypeEnum) {
      case 1:
        objInput = getDeviceObj(DeviceTypes.VrtDevice);
        break;
      case 4:
        objInput = getDeviceObj(DeviceTypes.CetDevice);
        break;
      case 3:
      case 5:
      case 7:
        objInput = getDeviceObj(DeviceTypes.PosDevice);
        break;
      case 6:
        objInput = getDeviceObj(DeviceTypes.CauDevice);
        break;
      case 8:
        objInput = getDeviceObj(DeviceTypes.GranjaDevice);
        break;
      default:
        objInput = {};
        break;
    }
    filterObject = createObjectAsInterface(data, objInput);
    setInputs(filterObject);
  }

  useEffect(() => {
    if (getPointsOfSalesCatalogState.state.data !== null) {
      const { data } = getPointsOfSalesCatalogState.state;
      setPos(data);
    }
  }, [getPointsOfSalesCatalogState.state]);

  useEffect(() => {
    if (getProjectsCatalogState.state.data !== null) {
      const { data } = getProjectsCatalogState.state;
      setProjects(data);
    }
  }, [getProjectsCatalogState.state]);

  useEffect(() => {
    if (getStationsCatalogState.state.data !== null) {
      const { data } = getStationsCatalogState.state;
      setStations(data);
    }
  }, [getStationsCatalogState.state]);

  useEffect(() => {
    if (getEconomicNumbersCatalogState.state.data !== null) {
      const { data } = getEconomicNumbersCatalogState.state;
      setEconomicNumbers(data);
    }
  }, [getEconomicNumbersCatalogState.state]);

  useEffect(() => {
    if (getRoutesCatalogState.state.data !== null) {
      const { data } = getRoutesCatalogState.state;
      setRoutes(data);
    }
  }, [getRoutesCatalogState.state]);

  useEffect(() => {
    if (data !== undefined && data !== null) {
      let operatorFound: any = [];

      const getCatalogs = async () => {
        const res = await getOperatorsCatalog();
        if (res.status === 200) {
          operatorFound = res.data.message.find(
            (element: any) => data['TechnologicalOperator'] === element.name
          );

          setOperatorsList(res.data.message);
        }
      };

      getCatalogs();
    }
  }, []);

  const catalogObjectFactory: Partial<Record<DeviceTypes, any>> = {
    [DeviceTypes.VrtDevice]: objAsVrtDevice,
    [DeviceTypes.CetDevice]: objAsCetDevice,
    [DeviceTypes.PosDevice]: objAsPosDevice,
    [DeviceTypes.CauDevice]: objAsCauDevice,
    [DeviceTypes.GranjaDevice]: objAsGranjaDevice,
  };

  function getDeviceObj(type: DeviceTypes): any {
    const catalogObject = catalogObjectFactory[type];
    if (catalogObject) {
      return catalogObject;
    } else {
      throw new Error('Tipo de catálogo no reconocido');
    }
  }

  const addIdsToDevice = (device: any): any => {
    const route = routes.find((route: any) => route.name === device.RouteName);

    const economic = economicNumbers.find(
      (economic: any) => economic.economicNumber === device.EconomicNumber
    );

    return {
      ...device,
      routeId: route?.routeId,
      economicNumberId: economic?.economicNumberId,
    };
  };

  const handleSubmit = async (values: any) => {

/*     if (
      !isNaN(parseFloat(values.TechnologicalOperator)) &&
      isFinite(values.TechnologicalOperator)
    ) {
      values.technologicalOperatorId = Number(values.TechnologicalOperator);
      delete values.TechnologicalOperator;
    } */

    const addedRouteId = addIdsToDevice(values);

    const dataToSend = eliminarNull(addedRouteId);

    let coords = await getCurrentPosition();

    const headers = {
      locationGPS: `${coords.latitude}, ${coords.latitude}`,
      appId: 'web',
    };

    await putEditSam(dataToSend, headers);
    updateTable();
  };

  const formik = useFormik({
    initialValues: inputs,
    enableReinitialize: true,
    validate: (data: any) => {
      const errors: any = {};
      return errors;
    },
    onSubmit: data => {
      //setFormData(data);
      setShowMessage(true);
      handleSubmit(data);
    },
  });

  function isFormFieldValid(
    name: string,
    errors: FormikErrors<FormikValues>,
    touched: FormikTouched<FormikValues>
  ) {
    return !!(touched[name] && errors[name]);
  }

  function getFormErrorMessage(
    name: string,
    errors: FormikErrors<FormikValues>,
    touched: FormikTouched<FormikValues>
  ) {
    const error = errors[name];
    const errorMessage = typeof error === 'string' ? error : '';
    return (
      isFormFieldValid(name, errors, touched) && (
        <small className="p-error">{errorMessage}</small>
      )
    );
  }

  const renderInput = (props: IRenderInput) => {
    return (
      <div className="flex flex-col mt-3">
        <InputComponent
          label={props.label}
          type={props.type}
          name={props.name}
          onChange={formik.handleChange}
          value={formik?.values?.[props.typedName] || ''}
          //className="rounded-sm border border-gray-800 border-opacity-20 px-1.5 py-0.5 mt-2 mb-7 w-full"
          className={classNames({
            'p-invalid': isFormFieldValid(
              props.typedName,
              formik.errors,
              formik.touched
            ),
          })}
          placeholder={props.placeholder}
          maxLength={props.max}
        />
        {getFormErrorMessage(props.typedName, formik.errors, formik.touched)}
      </div>
    );
  };

  /*
   *Show dinamic fields by device type
   */
  const showDinamicFields = () => {
    const device = props.row.DeviceTypeEnum;
    switch (device) {
      case 1:
        return (
          <VrtDeviceForm
            formik={formik}
            renderInput={renderInput}
            operatorsList={operatorsList}
            stations={stations}
            projects={projects}
          />
        );
      case 4:
        return (
          <CetDeviceForm
            formik={formik}
            renderInput={renderInput}
            routes={routes}
            economicNumbers={economicNumbers}
            inputs={inputs}
          />
        );
      case 3:
      case 5:
      case 7:
        return (
          <PosDeviceForm formik={formik} renderInput={renderInput} pos={pos} />
        );
      case 6:
        return (
          <CauDeviceForm
            formik={formik}
            renderInput={renderInput}
            operatorsList={operatorsList}
          />
        );
      case 8:
        return (
          <GranjaDeviceForm
            formik={formik}
            renderInput={renderInput}
            operatorsList={operatorsList}
          />
        );
    }
  };

  return (
    <form
      className="flex w-full h-full mx-5 mt-7 flex-col content-center border-b border-gray-200 p-3"
      onSubmit={formik.handleSubmit}>
      <hr />

      {showDinamicFields()}

      <div className="flex justify-between my-5">
        <ButtonComponent
          name="Cancelar"
          label="Cancelar"
          className="  !bg-deleteButton !text-white !hover:bg-deleteButton border-none rounded-3xl mt-8 px-6 py-2"
          type="button"
          onClick={close}
        />
        <ButtonComponent
          name="Actualizar"
          label="Actualizar"
          className=" bg-official_green !hover:bg-official_green border-none rounded-3xl text-white mt-8 px-6 py-2"
          type="submit"
        />
      </div>
    </form>
  );
};

import { useContext } from 'react';

import { ToastComponent } from '../../components/ToastComponent';
import {
  GET_USER_HISTORY,
  GET_USER_HISTORY_SUCCESS,
  GET_USER_HISTORY_ERROR,
  LOADING_ON,
  LOADING_OFF,
} from '../../constants/constantsRed';
import { StoreContext } from '../../context/Provider';
import useApi from '../../services/request.service';
import { useAccount, useMsal } from '@azure/msal-react';
import { useAuthService } from '../../authentication/authService';

export const useUserHistory = () => {
  const context = useContext(StoreContext);
  const {
    dispatchLoading,
    userHistoryStateContext,
  } = context;
  const { genericApiCall } = useApi();

  const getUserHistory = async (query : string) => {
    dispatchLoading({ type: LOADING_ON });
    userHistoryStateContext.dispatch({ type: GET_USER_HISTORY });
    try {
      const result: any = await genericApiCall('/userHistory'+query, 'GET', {}, {}, {});
      if (result.status === 200) {
        const payload = result.data;
        userHistoryStateContext.dispatch
        ({
          type: GET_USER_HISTORY_SUCCESS,
          payload: payload,
        });
      }
      dispatchLoading({ type: LOADING_OFF });
      return result;
    } catch (error: any) {
      ToastComponent(
        'warning',
        'Error',
        'La operación falló, inténtelo más tarde por favor.'
      );
      userHistoryStateContext.dispatch({
        type: GET_USER_HISTORY_ERROR,
        payload: error.message,
      });
      dispatchLoading({ type: LOADING_OFF });
      return 'error';
    }
  };

  const exportUserHistory = async (query : string, accessToken : string) => {

    dispatchLoading({ type: LOADING_ON });

    
    let path = '/userHistory/export';
    if(query.length > 0){
      path+= query;
    }

    const apiUrl = process.env.REACT_APP_SECURITYAPIURI + path;

    const headers = new Headers({
      Authorization: `Bearer ${accessToken}`,
    });

    fetch(apiUrl, { method: 'GET', headers: headers })
    .then(response => {
      return response.blob();
    })
    .then(blob => {
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = "Historial de usuarios" + '.csv';
      a.click();
      URL.revokeObjectURL(url);
      dispatchLoading({ type: LOADING_OFF });

    })
    .catch(error => {
      console.error('Error al obtener el archivo XLSX', error);
      dispatchLoading({ type: LOADING_OFF });
    });


  };


  
  return {
    getUserHistory,
    exportUserHistory
  };
};

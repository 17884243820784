import { IInventoryProvider } from './service/InventoryServiceManagerInterface';

export interface IInventaryTableProps {
  serviceInvetory: IInventoryProvider;
}

export type ActivePopUpType = ActivePopUp.DisableSam | ActivePopUp.ModifyWhiteList | ActivePopUp.RechargeCounterHistory | ActivePopUp.RechargeCounterModal;

export enum ActivePopUp {
  DisableSam = "disableSam",
  ModifyWhiteList = "modifyWhiteList",
  RechargeCounterHistory = "rechargeCounterHistory",
  RechargeCounterModal = "rechargeCounterModal"
}
export interface ISamInfo {
  Id: number;
  SamId: string;
  WhiteList: boolean;
  Issued: boolean;
  IssuedPhase2: boolean;
  IssueOrderId: number | null;
  InventoryOrderId: number | null;
  LocationGpsObject: string;
  ProjectId: number;
  Project: string;
  LocationId: number | null;
  StationId: number | null;
  Station: string | null;
  RouteId: number;
  RouteName: string;
  RouteNumber: string;
  PcModuleId: number | null;
  PCModules: string | null;
  PointOfSaleId: number | null;
  PointOfSale: string | null;
  DeviceTypeId: number;
  DeviceType: string;
  DeviceTypeEnum: number;
  SamTypeId: number;
  SamType: string;
  SamStatus: string;
  TransportCompanyId: number;
  TransportCompanyName: string;
  TransportCompanyEUR: string;
  TechnologicalOperatorId: number;
  TechnologicalOperator: string;
  EconomicNumberId: number;
  EconomicNumber: string;
  Name: string;
  SamActivatedBy: string;
  CreatedBy: string;
  CreatedOn: string;
  CreatedFrom: string;
  ActivatedBy: string;
  ActivatedOn: string;
  Observations: string;
  CarPlates: string;
  Address: string;
  IdSocket: string;
  NumberDevice: string;
  AmountTransactionAdditional: number;
  HistoryEntries: any[] | null;
  Counters: any[];
  IssueOrder: any | null;
  InventoryOrder: any | null;
}
export interface IWhiteListSamProps {
  handlerUpdateData?: any;
  show: boolean;
  closeMenu?: any;
  selectedSam: any;
  setSelectedSam: any;
  getCounterBySamType: any;
  getCounterBySamId: any;
}

export interface ISendDataForm {
  amount: any;
  samId: any;
  counterTypeId: any
}

export interface IDataBodyForm {
  amount: any;
  counterTypeId: any
}
export const GET_SAM_COUNTER_CATALOG = 'GET_SAM_COUNTER_CATALOG';
export const GET_SAM_COUNTER_CATALOG_SUCCESS = 'GET_SAM_COUNTER_CATALOG_SUCCESS';
export const GET_SAM_COUNTER_CATALOG_ERROR = 'GET_SAM_COUNTER_CATALOG_ERROR';

export const PATCH_SAM_COUNTER_CATALOG = 'PATCH_SAM_COUNTER_CATALOG';
export const PATCH_SAM_COUNTER_CATALOG_SUCCESS = 'PATCH_SAM_COUNTER_CATALOG_SUCCESS';
export const PATCH_SAM_COUNTER_CATALOG_ERROR = 'PATCH_SAM_COUNTER_CATALOG_ERROR';

export const POST_SAM_COUNTER_CATALOG = 'POST_SAM_COUNTER_CATALOG';
export const POST_SAM_COUNTER_CATALOG_SUCCESS = 'POST_SAM_COUNTER_CATALOG_SUCCESS';
export const POST_SAM_COUNTER_CATALOG_ERROR = 'POST_SAM_COUNTER_CATALOG_ERROR';

export const PUT_SAM_COUNTER_CATALOG = 'PUT_SAM_COUNTER_CATALOG';
export const PUT_SAM_COUNTER_CATALOG_SUCCESS = 'PUT_SAM_COUNTER_CATALOG_SUCCESS';
export const PUT_SAM_COUNTER_CATALOG_ERROR = 'PUT_SAM_COUNTER_CATALOG_ERROR';

export const DELETE_SAM_COUNTER_CATALOG = 'DELETE_SAM_COUNTER_CATALOG';
export const DELETE_SAM_COUNTER_CATALOG_SUCCESS = 'DELETE_SAM_COUNTER_CATALOG_SUCCESS';
export const DELETE_SAM_COUNTER_CATALOG_ERROR = 'DELETE_SAM_COUNTER_CATALOG_ERROR';

export const GET_SAM_COUNTER_TYPES_CATALOG = 'GET_SAM_COUNTER_TYPES_CATALOG';
export const GET_SAM_COUNTER_TYPES_CATALOG_SUCCESS = 'GET_SAM_COUNTER_TYPES_CATALOG_SUCCESS';
export const GET_SAM_COUNTER_TYPES_CATALOG_ERROR = 'GET_SAM_COUNTER_TYPES_CATALOG_ERROR';

export const GET_SAM_CATALOG_VERSIONS = 'GET_SAM_CATALOG_VERSIONS';
export const GET_SAM_CATALOG_VERSIONS_SUCCESS = 'GET_SAM_CATALOG_VERSIONS_SUCCESS';
export const GET_SAM_CATALOG_VERSIONS_ERROR = 'GET_SAM_CATALOG_VERSIONS_ERROR';
import { useContext, useEffect } from 'react';

import { ToastComponent } from '../../components/ToastComponent';
import {
  GET_SETTINGS,
  GET_SETTINGS_SUCCESS,
  LOADING_OFF,
  LOADING_ON,
} from '../../constants/constantsRed';
import { StoreContext } from '../../context/Provider';
import useApi from '../../services/request.service';

export const useSettings = () => {
  const context = useContext(StoreContext);
  const { dispatchSettings, dispatchLoading } = context;

  const { genericApiCall } = useApi();

  const getPersonalData = async () => {
    dispatchLoading({ type: LOADING_ON });
    dispatchSettings({ type: GET_SETTINGS });
    try {
      const result = await genericApiCall('/users/profiles', 'GET', {}, {}, {});
      if (result.status === 200) {
        const payload = result.data;
        dispatchSettings({
          type: GET_SETTINGS_SUCCESS,
          payload: payload,
        });
        dispatchLoading({ type: LOADING_OFF });
      }

      return result.data;
    } catch (error: any) {
      ToastComponent('warning', 'Error', error.message);
      dispatchLoading({ type: LOADING_OFF });
    }
  };

  return {
    getPersonalData,
  };
};

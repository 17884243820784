import { getInventory_Red } from "../../Reducers/inventory/get-red"
import { getSamTypesRed } from "../../Reducers/sam/getSamTypesRed";
import { useReducer } from "react"
import { globalInitialState } from "../initialStateGlobal"
import { patchSamRed } from "../../../business/Reducers/sam/patchSamRed";
import { putSamRed } from "../../../business/Reducers/sam/putSamRed";
import { getSamByIdRed } from "../../../business/Reducers/sam/getSamByIdRed";
import { putRechargeCounterRed } from "../../../business/Reducers/sam/putRechargeCounterRed";

export const useGetInventoryState = () => {
  const [state, dispatch] = useReducer(getInventory_Red, globalInitialState);
  return { state, dispatch };
}

export const useGetInvetoryToExcelState = () => {
  const [state, dispatch] = useReducer(getInventory_Red, globalInitialState);
  return { state, dispatch };
}

export const useSamTypes = () => {
  const [state, dispatch] = useReducer(getSamTypesRed, globalInitialState);
  return { state, dispatch };
}

export const useGetSamById = () => {
  const [state, dispatch] = useReducer(getSamByIdRed, globalInitialState);
  return { state, dispatch };
}

export const usePatchEditSamState = () => {
  const [state, dispatch] = useReducer(patchSamRed, globalInitialState);
  return { state, dispatch };
}

export const usePutEditSamState = () => {
  const [state, dispatch] = useReducer(putSamRed, globalInitialState);
  return { state, dispatch };
}

export const usePutRechargeCountersState = () => {
  const [state, dispatch] = useReducer(putRechargeCounterRed, globalInitialState);
  return { state, dispatch }
}
import React from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const container = (title: any, subTitle: any) => {
  return (
    <div className="!flex !absolute !flex-col !w-[10%] !h-[20%]">
      <p className="!text-black !font-bold  ">{title}</p>
      <p className="!text-black !text-xs ">{subTitle}</p>
    </div>
  );
};
// restrict type to primary, secondary, success, info, warning, danger
export const ToastComponent = (
  type: any,
  title: any,
  subTitle: any,
  timer: number = 3000
) => {
  switch (type) {
    case 'success':
      return toast.success(subTitle, {
        position: 'bottom-right',
        autoClose: timer,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        //className: toastSuccess,
        //	theme: 'light',
      });
    case 'warning':
      return toast.warning(subTitle, {
        position: 'bottom-right',
        autoClose: timer,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        //className: toastSuccess,
        //	theme: 'light',
      });
    case 'error':
      return toast.error(subTitle, {
        position: 'bottom-right',
        autoClose: timer,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        //className: toastSuccess,
        //	theme: 'light',
      });
    default:
      return toast.info(subTitle, {
        position: 'bottom-right',
        autoClose: timer,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,

        //	theme: 'light',
      });
  }
};

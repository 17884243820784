export const transportCompaniesColumns = [
  { field: 'businessName', header: 'Nombre de compañía', sortable: true },
  { field: 'eur', header: 'EUR', sortable: true },
  { field: 'status', header: 'Estatus', sortable: true },
];

export const stationsColumns = [
  { field: 'stationId', header: 'Id de estación', sortable: true },
  { field: 'name', header: 'Nombre', sortable: true },
  { field: 'status', header: 'Estatus', sortable: true },
];

export const operatorsColumns = [
  {
    field: 'technologicalOperatorId',
    header: 'Id de operador tecnológico',
    sortable: true,
  },
  { field: 'name', header: 'Nombre', sortable: true },
  // { field: 'providerNumber', header: 'Número de proveedor', sortable: true },
  { field: 'address', header: 'Dirección', sortable: true },
  { field: 'phone', header: 'Teléfono', sortable: true },
  { field: 'status', header: 'Estatus', sortable: true },
];

export const locationsColumns = [
  { field: 'locationId', header: 'Locación', sortable: true },
  { field: 'status', header: 'Estatus', sortable: true },
];

export const pcModuleColumns = [
  { field: 'name', header: 'Nombre', sortable: true },
  { field: 'pcModuleId', header: 'Id de Módulo PC', sortable: true },
  { field: 'status', header: 'Estatus', sortable: true },
];

export const economicNumberColumns = [
  // { field: 'economicNumberId', header: 'Id de Número', sortable: true },
  { field: 'economicNumber', header: 'Número económico', sortable: true },
  { field: 'routeName', header: 'Ruta', sortable: true },
  { field: 'status', header: 'Estatus', sortable: true },
];

export const pointOfSaleColumns = [
  { field: 'pointOfSaleId', header: 'Id de punto de venta', sortable: true },
  { field: 'name', header: 'Nombre', sortable: true },
  { field: 'status', header: 'Estatus', sortable: true },
];

export const projectsColumns = [
  { field: 'id', header: 'Id de proyecto', sortable: true },
  { field: 'name', header: 'Nombre', sortable: true },
  { field: 'status', header: 'Estatus', sortable: true },
];

export const routesColumns = [
  /* { field: 'routeId', header: 'Id de ruta', sortable: true }, */
  { field: 'name', header: 'Nombre', sortable: true },
  { field: 'routeNumber', header: 'Número de ruta', sortable: true },
  { field: 'status', header: 'Estatus', sortable: true },
];

export const deviceTypesColumns = [
  /*  { field: 'deviceTypeId', header: 'Id de dispositivo', sortable: true }, */
  { field: 'name', header: 'Nombre', sortable: true },
  { field: 'number', header: 'Número', sortable: true },
  { field: 'status', header: 'Estatus', sortable: true },
];

import { LayoutContext } from '../../context/LayoutContext';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useContext, useEffect, useState } from 'react';
import { MdClose } from 'react-icons/md';

interface IRightMenuProps {
  children: any;
  setOpenRightMenu?: any;
  loading: boolean;
  rightMenuRef: any;
}
export const RightMenuComponent = (props: IRightMenuProps) => {
  const { children, setOpenRightMenu, loading, rightMenuRef } = props;

  const context = useContext(LayoutContext);
  const { changeStatusCloseMenu } = context;

  const handleCloseMenu = () => {
    setOpenRightMenu(false);
    changeStatusCloseMenu(false);
  };

  return (
    <div
      ref={rightMenuRef}
      className="flex w-[280px] lg:w-[500px] x:w-[500px] xl:w-[500px] xxl:w-[500px]  h-full z-10  right-0 top-0 flex-col absolute items-center bg-white shadow-[-6px_-1px_5px_#00000024]">
      <div
        className="w-full flex justify-end pt-2 pr-2"
        onClick={() => handleCloseMenu()}>
        <MdClose className="text-[30px] text-grey cursor-pointer" />
      </div>
      <div className="flex w-full h-full relative overflow-hidden flex-col items-center dark:bg-[#CECECE] ">
        {children}
        {loading && (
          <div className="flex w-[280px] lg:w-[500px] x:w-[500px] xl:w-[500px] xxl:w-[500px]  absolute h-full right-0 justify-center opacity-90  flex-col items-center bg-white shadow-[-6px_-1px_5px_#00000024] z-50">
            <ProgressSpinner
              style={{ width: '50px', height: '50px' }}
              strokeWidth="8"
              fill="var(--surface-ground)"
              animationDuration=".5s"
            />
          </div>
        )}
      </div>
    </div>
  );
};

import {
  GET_USERLIST,
  GET_USERLIST_SUCCESS,
  GET_USERLIST_ERROR,
} from '../../../constants/constantsRed';

export const getUserManagerRed = (
  state: any = false,
  actions: any
) => {
  switch (actions.type) {
    case GET_USERLIST:
      return {
        ...state,
        loading: true,
      };
    case GET_USERLIST_ERROR:
      return {
        data: null,
        loading: false,
        error: null,
      };
    case GET_USERLIST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: actions.payload,
      };
    default:
      return state;
  }
};

import { useContext, useEffect } from 'react';

import { ToastComponent } from '../../components/ToastComponent';
import {
    GET_USER_HISTORY_CARDS_ERROR,
  GET_USER_HISTORY_CARDS_SUCCESS,
  LOADING_OFF,
  LOADING_ON,
} from '../../constants/constantsRed';
import { StoreContext } from '../../context/Provider';
import useApi from '../../services/request.service';

export const useHistoryCard = () => {
  const context = useContext(StoreContext);
  const { historyCardStateContext, dispatchLoading } = context;

  const { genericApiCallPrepayCards } = useApi();

  const getPrepayCards = async (query : string) => {
    dispatchLoading({ type: LOADING_ON });
    try {
      const result = await genericApiCallPrepayCards('/cards'+query, 'GET', {}, {}, {});
      if (result.status === 200) {
        const payload = result.data;
        historyCardStateContext.dispatch({ type: GET_USER_HISTORY_CARDS_SUCCESS, payload: payload });
        dispatchLoading({ type: LOADING_OFF });
      }

      return result.data;
    } catch (error: any) {
      dispatchLoading({ type: LOADING_OFF });
      historyCardStateContext.dispatch({ type: GET_USER_HISTORY_CARDS_ERROR });
    }
  };

  return {
    getPrepayCards,
  };
};

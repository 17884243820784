import {
  PATCH_USER,
  PATCH_USER_SUCCESS,
  PATCH_USER_ERROR,
} from '../../../constants/constantsRed';

export const patchUserManagerRed = (state: any = false, actions: any) => {
  switch (actions.type) {
    case PATCH_USER:
      return {
        ...state,
        loading: true,
      };
    case PATCH_USER_ERROR:
      return {
        data: null,
        loading: false,
        error: null,
      };
    case PATCH_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: actions.payload,
      };
    default:
      return state;
  }
};

import { ICatalogProvider } from '../../../interfaces/service/CatalogServiceManagerInterface';
import { PopUpComponent } from '../../../components/PopUpComponent';

interface IDeleteItemCatalogProps {
  serviceCatalog: ICatalogProvider;
}

export const DeleteItemModal = (props: IDeleteItemCatalogProps) => {
  const {
    serviceCatalog
  } = props;

  const bodyModalContent = () => {
    return (
      <div className="w-full h-full flex pb-4 justify-center">
        <p>
          ¿Deseas eliminar <strong> {serviceCatalog.itemNameToDelete}</strong> del catálogo?
        </p>
      </div>
    );
  };

  return (
    <PopUpComponent
      header={"Eliminar registro"}
      showPopUp={serviceCatalog.showDeleteModal}
      onSubmit={() => serviceCatalog.deleteCatalogItem()}
      onClosePopUp={() => serviceCatalog.handlerCloseModal()}
      footer={true}
      body={bodyModalContent()}
    />
  );
};

import { useContext } from 'react';

import { ToastComponent } from '../../components/ToastComponent';
import {
  DELETE_ROL,
  DELETE_ROL_ERROR,
  DELETE_ROL_SUCCESS,
  POST_ROLE,
  POST_ROLE_ERROR,
  POST_ROLE_SUCCESS,
  PATCH_ROLE,
  PATCH_ROLE_ERROR,
  PATCH_ROLE_SUCCESS,
  GET_ROLES,
  GET_ROLES_SUCCESS,
  GET_ROLES_ERROR,
  LOADING_ON,
  LOADING_OFF,
} from '../../constants/constantsRed';
import { StoreContext } from '../../context/Provider';
import useApi from '../../services/request.service';

export const useRoles = () => {
  const context = useContext(StoreContext);
  const {
    dispatchLoading,
    dispatchGetRoles,
    dispatchPostRole,
    dispatchPatchRole,
    dispatchDeleteRole,
  } = context;
  const { genericApiCall } = useApi();

  const getRoles = async () => {
    dispatchLoading({ type: LOADING_ON });
    dispatchGetRoles({ type: GET_ROLES });
    try {
      const result: any = await genericApiCall('/roles', 'GET', {}, {}, {});
      if (result.status === 200) {
        const payload = result.data.payload;
        dispatchGetRoles({
          type: GET_ROLES_SUCCESS,
          payload: payload,
        });
      }
      dispatchLoading({ type: LOADING_OFF });
      return result;
    } catch (error: any) {
      ToastComponent(
        'warning',
        'Error',
        'La operación falló, inténtelo más tarde por favor.'
      );
      dispatchGetRoles({
        type: GET_ROLES_ERROR,
        payload: error.message,
      });
      dispatchLoading({ type: LOADING_OFF });
      return 'error';
    }
  };

  const postRole = async (data: any) => {
    dispatchLoading({ type: LOADING_ON });
    dispatchPostRole({ type: POST_ROLE });
    try {
      const result: any = await genericApiCall('/roles', 'POST', data, {}, {});
      if (result.status === 200) {
        const payload = result.data.payload;
        dispatchPostRole({
          type: POST_ROLE_SUCCESS,
          payload: payload,
        });
        ToastComponent('success', '', 'Role agregado con éxito.');
      }
      dispatchLoading({ type: LOADING_OFF });
      return result;
    } catch (error: any) {
      dispatchPostRole({
        type: POST_ROLE_ERROR,
        payload: error.message,
      });
      dispatchLoading({ type: LOADING_OFF });

      return 'error';
    }
  };

  const patchRole = async (data: any, id: string) => {
    dispatchPatchRole({ type: PATCH_ROLE });
    try {
      const result: any = await genericApiCall(
        '/roles/' + id,
        'PATCH',
        data,
        {},
        {}
      );
      if (result.status === 200) {
        const payload = result.data.payload;
        dispatchPatchRole({
          type: PATCH_ROLE_SUCCESS,
          payload: payload,
        });
        ToastComponent('success', '', 'Role editado con éxito.');
      }
      return result;
    } catch (error: any) {
      ToastComponent(
        'warning',
        'Error',
        'La operación falló, inténtelo más tarde por favor.'
      );
      dispatchPatchRole({
        type: PATCH_ROLE_ERROR,
        payload: error.message,
      });
      return 'error';
    }
  };

  const deleteRoles = async (id: any) => {
    dispatchDeleteRole({ type: DELETE_ROL });
    try {
      const result: any = await genericApiCall(
        '/roles/' + id,
        'DELETE',
        {},
        {},
        {}
      );
      if (result.status === 204) {
        const payload = result.data.payload;
        dispatchDeleteRole({
          type: DELETE_ROL_SUCCESS,
          payload: payload,
        });
        ToastComponent('success', '', 'Rol eliminado con éxito.');
      }
      return result;
    } catch (error: any) {
      let message = 'La operación falló, inténtelo más tarde por favor.';
      if (error.status === 400) message = error.message;
      ToastComponent('warning', 'Error', message);
      dispatchDeleteRole({
        type: DELETE_ROL_ERROR,
        payload: error.message,
      });
      return 'error';
    }
  };

  return {
    getRoles,
    postRole,
    patchRole,
    deleteRoles,
  };
};

import urlJoin from 'url-join';

import { PermitionsWebList } from '../permitsList';

interface Coordinates {
  latitude: number;
  longitude: number;
}

export const generateUrlBase = (section: string) => {
  const urlEnv = process.env.REACT_APP_SECURITYAPIURI || '';
  const url = urlJoin(urlEnv, section);

  return url;
};

export const generateUrlBaseApiBilling = (section: string) => {
  const urlEnv = process.env.REACT_APP_BILLINGAPIURI || '';
  const url = urlJoin(urlEnv, section);

  return url;
};

export const generateUrlBaseApiConfig = (section: string) => {
  const urlEnv = process.env.REACT_APP_CONFIGURATIONURI || '';
  const url = urlJoin(urlEnv, section);

  return url;
};

export const validateMac = (mac: string) => {
  const regExpLiteral =
    /^[0-9a-f]{1,2}([.:-])[0-9a-f]{1,2}(?:\1[0-9a-f]{1,2}){4}$/gim;
  return mac.match(regExpLiteral);
};

/**
 *Limpia un objeto de acuerdo a las propiedades de una interfaz
 * @param data El objeto que se va limpiar
 * @param interfaz que se necesita
 */
export const createObjectAsInterface = (data: any, interfaz: any): any => {
  const result: any = {};

  for (const key in interfaz) {
    if (interfaz.hasOwnProperty(key) && data.hasOwnProperty(key)) {
      result[key] = data[key];
    }
  }

  return result;
};

export const getCurrentPosition = (): Promise<Coordinates> => {
  return new Promise((resolve, reject) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position: GeolocationPosition) => {
          const coords: Coordinates = {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          };
          resolve(coords);
        },
        (error: GeolocationPositionError) => {
          reject(new Error(`Error al obtener la ubicación: ${error.message}`));
        },
        {
          enableHighAccuracy: true, // Aumenta la precisión
          timeout: 5000, // Tiempo máximo de espera
          maximumAge: 0 // Fuerza que siempre busque la nueva ubicación
        }
      );
    } else {
      reject(new Error('Tu navegador no soporta la API de geolocalización.'));
    }
  });
};

export const eliminarNull = (objet: any) => {
  return JSON.parse(
    JSON.stringify(objet, (clave, valor) => {
      if (valor !== null) {
        return valor;
      }
    })
  );
};

export function validatePermissions(permition: any) {
  const permitsUser = localStorage.getItem('permitions_user') || '0';

  let result = false;

  const int64A = BigInt(parseInt(permition));
  const int64B = BigInt(parseInt(permitsUser));

  const andPermit = int64A & int64B;

  if (int64A === andPermit) {
    result = true;
  }

  return result;
}

export function deleteDuplicates(arrary: any[], field: string) {
  const uniqueArray = arrary.filter(
    (obj, index, self) =>
      index === self.findIndex(item => item[field] === obj[field])
  );
  return uniqueArray;
}

/**
 *Recibe un array y le da formato de queryParams
 * @param cabecera El titulo del queryParam
 * @param list El listado que vamos a transformar
 * @returns {String} Una cadena con formato de queryParams
 */
export const createQueryParams = (cabecera: string, list: any): any => {
  if (!Array.isArray(list)) {
    return '';
  }

  const queryParams = list
    .map(valor => {
      const encodedValue = encodeURIComponent(valor);
      return `${encodeURIComponent(cabecera)}=${encodedValue}`;
    })
    .join('&');

  return queryParams;
};

/**
 *Valida que el usuario tenga al menos un permiso en la web. Se validan los permisos de lista
 * @param userPermission El permiso del usuario
 */
export const validateUserWebPermission = (): any => {
  let havepermissionToViewWeb = false;
  for (const iterator of PermitionsWebList) {
    let res = validatePermissions(iterator);
    if (res === true) {
      havepermissionToViewWeb = true;
      return;
    }
  }
  return havepermissionToViewWeb;
};

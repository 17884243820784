import { IStations } from '@/interfaces/interfaceCatalogs';
import { DropdownComponent } from '../../../../../components/DropdownComponen';
import { IRenderInput } from '../EditSamMenu';

interface IVrtDeviceFormProps {
  formik: any;
  renderInput: (props: IRenderInput) => void;
  operatorsList: any[];
  stations: IStations[];
  projects: any;
}

export const VrtDeviceForm = (props: IVrtDeviceFormProps) => {
  const { formik, renderInput, operatorsList, stations } = props;

  return (
    <>
      {renderInput({
        label: 'Número de VRT',
        type: 'text',
        name: 'NumberDevice',
        typedName: 'NumberDevice',
        placeholder: 'Operador',
        max: 10,
      })}

      <DropdownComponent
        label={'Operador tecnológico'}
        options={operatorsList}
        optionLabel={'name'}
        name="TechnologicalOperatorId"
        optionValue={'id'}
        className=" h-12 !w-full"
        onChange={formik.handleChange}
        value={formik?.values?.['TechnologicalOperatorId'] || ''}
      />

      <DropdownComponent
        label={'Proyecto'}
        options={operatorsList}
        optionLabel={'name'}
        name="ProjectId"
        optionValue={'id'}
        className=" h-12 !w-full"
        onChange={formik.handleChange}
        value={formik?.values?.['ProjectId'] || ''}
      />

      <DropdownComponent
        label={'Estación'}
        options={stations}
        optionLabel={'name'}
        name="StationId"
        optionValue={'id'}
        className=" h-12 !w-full"
        onChange={formik.handleChange}
        value={formik?.values?.['StationId'] || ''}
      />

      {renderInput({
        label: 'Observaciones',
        type: 'text',
        name: 'Observations',
        typedName: 'Observations',
        placeholder: 'Observaciones',
      })}
    </>
  );
};

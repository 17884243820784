import { ReactElement } from "react";
import { ICatalogProvider } from "./service/CatalogServiceManagerInterface";

export enum CatalogType {
  TransportCompanies = 'Compañías de transporte',
  Stations = 'Estaciones',
  Locations = 'Locaciones (locationId)',
  PcModules = 'Módulos PC',
  EconomicNumbers = 'Números económicos',
  Operators = 'Operadores tecnológicos',
  PointOfSales = 'Puntos de venta',
  Projects = 'Proyectos',
  Routes = 'Rutas',
  DeviceTypes = 'Tipos de dispositivo',
  SamTypes = 'Tipos de SAM',
  InitMenu = ''
}

export type CatalogConfigType = {
  fetchFunction: () => Promise<any>;
  columns?: any;
  excelPath: string;
  formatData?: (data: any) => any;
};

export interface ITableCatalogProps {
  serviceCatalog: ICatalogProvider;
}
export interface ICatalogList {
  name: string;
  icon: ReactElement;
  id?: number;
  document_name: string;
}

export interface ITransportCompanies {
  businessName: string;
  eur: string;
  status: boolean;
}

export interface IStations {
  stationId: string;
  name: string;
  status: boolean;
}

export interface IPcModules {
  pcModuleId: string;
  name: string;
  status: boolean;
}

export interface IPos {
  pointOfSaleId: string;
  name: string;
  status: boolean;
}

export interface IDeviceTypes {
  name: string;
  status: boolean;
}

export interface IRoutes {
  routeNumber: string;
  name: string;
  status: boolean;
}

export interface IOperators {
  technologicalOperatorId: string;
  name: string;
  providerNumber: number;
  address: string;
  phone: string;
  status: boolean;
}

export interface IEconomicNumber {
  economicNumber: string;
  routeId: string;
  status: boolean;
}

export interface IProject {
  projectId: string;
  name: string;
  status: boolean;
}

export interface ILocations {
  locationId: string;
  status: boolean;
}

export interface IServiceMenuComponent {
  dataRow?: any;
  selectMenu?: string;
  setOpenRightMenu?: any;
  updateTable?: any;
  serviceCatalog: ICatalogProvider;
  //updateTable:  (item:any) => {};
}

export interface ISamKeyVersion {
  versionId: number;
  version: string;
  releaseDate: string;
  isActive: boolean;
}

export type ISamVersions = {
  version: string;
}
import { cordinatesInventory, getActionsTemplate, statusCheckbox, checkBody } from "./BodyTemplatesTable";

export function columnsInventoryTable() {
  return [
    {
      field: 'SamId',
      header: 'SAM ID',
      sortable: true,
      width: '100px',
      isFilter: true,
      dataType: undefined,
    },
    {
      field: 'NumberDevice',
      header: 'Id de equipo',
      sortable: true,
      isFilter: true,
      dataType: undefined,
    },
    {
      field: 'SamStatus',
      header: 'Estatus',
      sortable: true,
      body: statusCheckbox,
      isFilter: true,
      dataType: 'boolean',
    },
    {
      field: 'WhiteList',
      header: 'Lista blanca',
      sortable: true,
      body: checkBody,
      isFilter: true,
      dataType: 'boolean',
    },
    {
      field: 'DeviceType',
      header: 'Dispositivo',
      sortable: true,
      isFilter: true,
      dataType: undefined,
    },
    {
      field: 'SamType',
      header: 'Tipo de SAM',
      sortable: true,
      isFilter: true,
      dataType: undefined,
    },
    {
      field: 'LocationGpsObject',
      header: 'Coordenadas',
      sortable: false,
      isFilter: false,
      dataType: undefined,
      body: cordinatesInventory,
    },
    {
      field: 'TechnologicalOperator',
      header: 'Operador',
      sortable: true,
      isFilter: true,
      dataType: undefined,
    },
    {
      field: 'TransportCompanyEUR',
      header: 'Compañía - EUR',
      sortable: true,
      isFilter: true,
      dataType: undefined,
    },
  ]
}

export function coulmsInventoryActions(
  editSamHandler: (row: any) => void,
  openDisableSam: (row: any) => void,
  openWhiteListSam: (row: any) => void,
  openRechargesCounterModalSingle: (row: any) => void) {
  return [
    {
      field: 'acciones',
      header: 'Acciones',
      sortable: false,
      isFilter: false,
      dataType: undefined,
      body: getActionsTemplate(
        editSamHandler,
        openDisableSam,
        openWhiteListSam,
        openRechargesCounterModalSingle
      ),
    },
  ]
}

export function columnsDisplayRechargeCounterHistory() {

  return [
    {
      field: 'createdDate',
      header: 'Fecha',
      sortable: true,
    },
    {
      field: 'createdBy',
      header: 'Operador',
      sortable: true,
    },
    {
      field: 'amount',
      header: 'Cantidad',
      sortable: true,
    },
    {
      field: 'address',
      header: 'Dirección',
      sortable: true,
    },
  ];
}
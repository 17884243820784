import React from 'react';
import { Button } from 'primereact/button';
import 'primeicons/primeicons.css';

type ButtonProps = {
  name?: string;
  className?: string;
  label?: string;
  icon?: string;
  iconPos?: 'top' | 'bottom' | 'left' | 'right' | undefined;
  onClick?: any;
  onSubmit?: any;
  type?: 'submit' | 'button' | 'reset';
  disbaled?: boolean;
  outlined?: boolean;
  severity?:
    | 'secondary'
    | 'success'
    | 'info'
    | 'warning'
    | 'danger'
    | 'help'
    | undefined;
};

export const ButtonComponent = (props: ButtonProps) => {
  return (
    <Button
      icon={props.icon} 
      iconPos={props.iconPos} 
      name={props.name}
      className={props.className}
      label={props.label}
      onClick={props.onClick}
      type={props.type}
      disabled={props.disbaled}
      outlined={props.outlined}
      severity={props.severity}
      onSubmit={props.onSubmit}
    />
  );
};

import { useContext } from 'react';

import { ToastComponent } from '../../components/ToastComponent';
import {
  GET_PROJECTS_CATALOG,
  GET_PROJECTS_CATALOG_SUCCESS,
  GET_PROJECTS_CATALOG_ERROR,
  POST_PROJECTS_CATALOG,
  POST_PROJECTS_CATALOG_SUCCESS,
  POST_PROJECTS_CATALOG_ERROR,
  PUT_PROJECTS_CATALOG,
  PUT_PROJECTS_CATALOG_SUCCESS,
  PUT_PROJECTS_CATALOG_ERROR,
  DELETE_PROJECTS_CATALOG,
  DELETE_PROJECTS_CATALOG_SUCCESS,
  DELETE_PROJECTS_CATALOG_ERROR,
} from '../../constants/constantsRed';
import { StoreContext } from '../../context/Provider';
import useApi from '../../services/request.service';

export const usegetProjectsCatalogs = () => {
  const {
    getProjectsCatalogState,
    postProjectsCatalogState,
    putProjectsCatalogState,
    deleteProjectsCatalogState
  } = useContext(StoreContext);

  const { genericApiConfiguration } = useApi();

  const getProjectsCatalog = async () => {
    getProjectsCatalogState.dispatch({ type: GET_PROJECTS_CATALOG });
    try {
      const result: any = await genericApiConfiguration(
        '/projects',
        'GET',
        {},
        {},
        {}
      );
      if (result.status === 200) {
        const payload = result.data.message;
        getProjectsCatalogState.dispatch({
          type: GET_PROJECTS_CATALOG_SUCCESS,
          payload: payload,
        });
      }
      return result;
    } catch (error: any) {
      ToastComponent(
        'warning',
        'Error',
        'La operación falló, inténtelo más tarde por favor.'
      );
      getProjectsCatalogState.dispatch({
        type: GET_PROJECTS_CATALOG_ERROR,
        payload: error.message,
      });
      return 'error';
    }
  };

  const postProjectsCatalog = async (data: any) => {
    postProjectsCatalogState.dispatch({ type: POST_PROJECTS_CATALOG });
    try {
      const result: any = await genericApiConfiguration(
        '/projects',
        'POST',
        data,
        {},
        {}
      );
      if (result.status === 200) {
        ToastComponent('success', '', 'Se ha añadido el registro correctamente');
        const payload = result.data.payload;
        postProjectsCatalogState.dispatch({
          type: POST_PROJECTS_CATALOG_SUCCESS,
          payload: payload,
        });
      }
      return result;
    } catch (error: any) {
      postProjectsCatalogState.dispatch({
        type: POST_PROJECTS_CATALOG_ERROR,
        payload: error.message,
      });
      return 'error';
    }
  };

  const putProjectsCatalog = async (data: any) => {
    putProjectsCatalogState.dispatch({ type: PUT_PROJECTS_CATALOG });
    try {
      const result: any = await genericApiConfiguration(
        '/projects',
        'PUT',
        data,
        {},
        {}
      );
      if (result.status === 200) {
        ToastComponent('success', '', 'Se ha editado el registro correctamente');
        const payload = result.data.payload;
        putProjectsCatalogState.dispatch({
          type: PUT_PROJECTS_CATALOG_SUCCESS,
          payload: payload,
        });
      }
      return result;
    } catch (error: any) {
      putProjectsCatalogState.dispatch({
        type: PUT_PROJECTS_CATALOG_ERROR,
        payload: error.message,
      });
      return 'error';
    }
  };

  const deleteProjectsCatalog = async (id: string) => {
    deleteProjectsCatalogState.dispatch({ type: DELETE_PROJECTS_CATALOG });
    try {
      const result: any = await genericApiConfiguration(
        '/projects/{id}',
        'DELETE',
        {},
        { ids: id },
        {}
      );

      if (result.status === 200) {
        ToastComponent(
          'success',
          'Succes',
          'Se eliminó correctamente el registro.'
        );
        const payload = result.data.payload;
        deleteProjectsCatalogState.dispatch({
          type: DELETE_PROJECTS_CATALOG_SUCCESS,
          payload: payload,
        });
      }
      return result;
    } catch (error: any) {
      deleteProjectsCatalogState.dispatch({
        type: DELETE_PROJECTS_CATALOG_ERROR,
        payload: error.message,
      });
      return 'error';
    }
  };

  return {
    getProjectsCatalog,
    postProjectsCatalog,
    putProjectsCatalog,
    deleteProjectsCatalog,
  };
};

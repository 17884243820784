import { IPos, IServiceMenuComponent } from '@/interfaces/interfaceCatalogs';
import { InputSwitchChangeEvent } from 'primereact/inputswitch';
import { classNames } from 'primereact/utils';
import { useEffect, useState } from 'react';

import { ButtonComponent } from '../../../../components/ButtonComponen';
import { InputComponent } from '../../../../components/InputComponent';
import { SwitchComponent } from '../../../../components/SwitchComponent';
import { usePointsOfSalesCatalog } from '../../../../hooks/catalogs/usePointsOfSalesCatalog';
import { createObjectAsInterface } from '../../../../utils/utils';
import { PosValidations } from './validations_form';
import useCustomFormik from './utils/formik_catalog_Config';

export const PosForm = (props: IServiceMenuComponent) => {
  const { serviceCatalog } = props;

  const renderMenu = (): any => {
    return (
      <form
        className="h-full w-full flex flex-col pt-5 px-6"
        onSubmit={serviceCatalog.formikPos.handleSubmit}>
        <div className="flex flex-col">
          <InputComponent
            label={'Id de Punto de venta'}
            type={'pointOfSaleId'}
            name={'pointOfSaleId'}
            onChange={serviceCatalog.formikPos.handleChange}
            value={serviceCatalog.formikPos?.values?.['pointOfSaleId'] || ''}
            placeholder={'Id de Punto de venta'}
            className={classNames({
              'p-invalid': serviceCatalog.isFormFieldValid('pointOfSaleId', serviceCatalog.formikPos.errors, serviceCatalog.formikPos.touched),
            })}
          />
          {serviceCatalog.getFormErrorMessage('pointOfSaleId', serviceCatalog.formikPos.errors, serviceCatalog.formikPos.touched)}
        </div>

        <div className="flex flex-col">
          <InputComponent
            label={'Nombre'}
            type={'name'}
            name={'name'}
            onChange={serviceCatalog.formikPos.handleChange}
            value={serviceCatalog.formikPos?.values?.['name'] || ''}
            placeholder={'Nombre'}
            className={classNames({
              'p-invalid': serviceCatalog.isFormFieldValid('name', serviceCatalog.formikPos.errors, serviceCatalog.formikPos.touched),

            })}
          />
          {serviceCatalog.getFormErrorMessage('name', serviceCatalog.formikPos.errors, serviceCatalog.formikPos.touched)}
        </div>

        <div className="flex flex-col">
          <SwitchComponent
            checked={serviceCatalog.formikPos?.values?.['status'] || 'false'}
            onChange={(e: InputSwitchChangeEvent) => {
              serviceCatalog.formikPos.setFieldValue('status', e.value);
            }}
            label={'Estatus'}
            className={classNames({
              'p-invalid': serviceCatalog.isFormFieldValid('status', serviceCatalog.formikPos.errors, serviceCatalog.formikPos.touched),
            })}
          />
          {serviceCatalog.getFormErrorMessage('status', serviceCatalog.formikPos.errors, serviceCatalog.formikPos.touched)}
        </div>

        <ButtonComponent className="mt-10" label={serviceCatalog.buttonLabel} type="submit" />
      </form>
    );
  };

  return (
    <div className="flex w-full h-full flex-col items-center bg-white  shadow-[-6px_-1px_5px_#00000024] z-10 right-0">
      <div className="flex w-full h-full overflow-y-auto">{renderMenu()}</div>
    </div>
  );
};

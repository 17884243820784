import { PopUpComponent } from '../../../components/PopUpComponent';
import { InputComponent } from '../../../components/InputComponent';
import { IInventoryProvider } from '../../../interfaces/service/InventoryServiceManagerInterface';
import { ActivePopUp } from '../../../interfaces/interfaceInventory';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { CheckboxComponent } from '../../../components/CheckboxComponent';
import { ChangeEvent } from 'react';

interface ICaptureLimitComunicationModal {
  serviceInvetory: IInventoryProvider;
}

export const CaptureLimitComunicationModal = (props: ICaptureLimitComunicationModal) => {
  const { serviceInvetory } = props;

  const bodyContent = () => {
    return (
        <div>
            {serviceInvetory.triggerHeaderBody !== '' ?<span> Configuración {serviceInvetory.triggerHeaderBody}.</span>:''}
            <div className="my-1">
                <div className="flex items-center justify-start text-center space-x-4">
                    <div className="flex w-1/4">
                        <CheckboxComponent
                            checked={serviceInvetory.isTimeChecked}
                            onChange={() => serviceInvetory.onTimeCheckboxChange()}
                        />
                        <span className="mx-1">Iniciar vía tiempo</span>
                    </div>
                    <span className="w-1/4">Al pasar</span>
                    <div className="w-1/4">
                        <InputComponent
                            name="cuantity"
                            type="number"
                            value={serviceInvetory.timeValue.toString()}
                            disabled={!serviceInvetory.isTimeChecked}
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                serviceInvetory.handleInputTimeChange(e.target.value)
                            }
                        />
                    </div>
                    <span className="w-1/4">minutos.</span>
                </div>
            </div>

            <div>
                <div className='flex items-center justify-start text-center space-x-4'>
                    <div className="flex w-1/4">
                        <CheckboxComponent
                            checked={serviceInvetory.isQuantityChecked}
                            onChange={() => serviceInvetory.onQuantityCheckboxChange()}
                        />
                        <span className='mx-1'>Iniciar vía cantidad</span>
                    </div>
                    <span className='w-1/4'>Cuando queden</span>
                    <div className='w-1/4'>
                        <InputComponent
                            name='cuantity'
                            type='number'
                            value={serviceInvetory.quantityValue.toString()}
                            disabled={!serviceInvetory.isQuantityChecked}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => serviceInvetory.handleInputQuantityChange(e.target.value)}
                        />
                    </div>
                    <span className='w-1/4'>transacciones.</span>
                </div>
            </div>
        </div>
    );
  };

  return (
    <PopUpComponent
      header={serviceInvetory.popUpHeader}
      showPopUp={serviceInvetory.activePopUp === ActivePopUp.CaptureLimitComunication && serviceInvetory.showPopUp}
      onSubmit={() => serviceInvetory.handleSubmitComunicationTriggers()}
      onClosePopUp={() => serviceInvetory.closePopUp()}
      footerOnlyCancel={false}
      footer={true}
      body={bodyContent()}
    />
  );
};

export enum DeviceTypes {
    VrtDevice = 'VrtDeviceType',
    CetDevice = 'CetDeviceType',
    PosDevice = 'PosDeviceType',
    CauDevice = 'CauDeviceType',
    GranjaDevice = 'GranjaDeviceType',
}
export interface IVrtDevice {
    SamId: string;
    NumberDevice: string;
    RouteName: string;
    Observations: string;
    WhiteList: boolean;
    Issued: boolean;
    ProjectId: number;
    StationId: number;
    TechnologicalOperatorId: number;
    routeId: number;
}


export const objAsVrtDevice: IVrtDevice = {
    SamId: '',
    NumberDevice: '',
    RouteName: '',
    Observations: '',
    WhiteList: false,
    Issued: false,
    ProjectId: 0,
    StationId: 0,
    TechnologicalOperatorId: 0,
    routeId: 0,
};

export interface ICetDevice {
    SamId: string;
    NumberDevice: string;
    RouteId: number;
    EconomicNumberId: number;
    CarPlates: string;
    Observations: string;
}


export const objAsCetDevice: ICetDevice = {
    SamId: '',
    NumberDevice: '',
    RouteId: 0,
    EconomicNumberId: 0,
    CarPlates: '',
    Observations: '',
}

export interface IPosDevice {
    SamId: string;
    NumberDevice: string;
    PointOfSaleId: number;
    Address: string;
    Observations: string;
}


export const objAsPosDevice: IPosDevice = {
    SamId: '',
    NumberDevice: '',
    PointOfSaleId: 0,
    Address: '',
    Observations: '',
}

export interface ICauDevice {
    SamId: string;
    NumberDevice: string;
    Address: string;
    Observations: string;
}


export const objAsCauDevice: ICauDevice = {
    SamId: '',
    NumberDevice: '',
    Address: '',
    Observations: '',
}

export interface IGranjaDevice {
    SamId: string;
    NumberDevice: string;
    IdSocket: string;
    Address: string;
    Observations: string;
}


export const objAsGranjaDevice: IGranjaDevice = {
    SamId: '',
    NumberDevice: '',
    IdSocket: '',
    Address: '',
    Observations: '',
}
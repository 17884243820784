import { getCounterBySamType } from "../../../business/Reducers/counterFromSam/getCounterBySamType";
import { useReducer } from "react";
import { globalInitialState } from "../initialStateGlobal";
import { getCounterBySamId } from "../../../business/Reducers/counterFromSam/getCounterBySamId";

export const useCountersFromSamTypeState = () =>{
    const [state, dispatch] = useReducer(getCounterBySamType, globalInitialState);
    return {state, dispatch};
}

export const useCountersFromSamIdState = () =>{
    const [state, dispatch] = useReducer(getCounterBySamId, globalInitialState);
    return {state, dispatch};
}
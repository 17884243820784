import { BasicSelectComponent } from '../../../../../components/BasicSelectComponentComponent';
import { IRenderInput } from '../EditSamMenu';

interface IGranjaDeviceFormProps {
  formik: any;
  renderInput: (props: IRenderInput) => void;
  operatorsList: any[];
}

export const GranjaDeviceForm = (props: IGranjaDeviceFormProps) => {
  const { formik, renderInput, operatorsList } = props;

  return (
    <>
      {renderInput({
        label: 'ID APP',
        type: 'text',
        name: 'NumberDevice',
        typedName: 'NumberDevice',
        placeholder: 'Operador',
        max:10
      })}

      {renderInput({
        label: 'ID socket',
        type: 'text',
        name: 'IdSocket',
        typedName: 'IdSocket',
        placeholder: 'Número de socket',
        max:10
      })}

      {renderInput({
        label: 'Dirección',
        type: 'text',
        name: 'Address',
        typedName: 'Address',
        placeholder: 'Dirección',
      })}

      {renderInput({
        label: 'Observaciones',
        type: 'text',
        name: 'Observations',
        typedName: 'Observations',
        placeholder: 'Observaciones',
      })}
    </>
  );
};

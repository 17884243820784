import { useContext } from 'react';

import { ToastComponent } from '../../components/ToastComponent';
import {
  GET_PCMODULES_CATALOG,
  GET_PCMODULES_CATALOG_SUCCESS,
  GET_PCMODULES_CATALOG_ERROR,
} from '../../constants/constantsRed';
import { StoreContext } from '../../context/Provider';
import useApi from '../../services/request.service';

export const usePcModulesCatalog = () => {
  const context = useContext(StoreContext);
  const { dispatchGetPcModulesCatalog } = context;
  const { genericApiConfiguration } = useApi();

  const getPcModulesCatalog = async () => {
    dispatchGetPcModulesCatalog({ type: GET_PCMODULES_CATALOG });
    try {
      const result: any = await genericApiConfiguration(
        '/pc-modules',
        'GET',
        {},
        {},
        {}
      );
      if (result.status === 200) {
        const payload = result.data.payload;
        dispatchGetPcModulesCatalog({
          type: GET_PCMODULES_CATALOG_SUCCESS,
          payload: payload,
        });
      }
      return result;
    } catch (error: any) {
      ToastComponent(
        'warning',
        'Error',
        'La operación falló, inténtelo más tarde por favor.'
      );
      dispatchGetPcModulesCatalog({
        type: GET_PCMODULES_CATALOG_ERROR,
        payload: error.message,
      });
      return 'error';
    }
  };

  return { getPcModulesCatalog };
};

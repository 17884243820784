import { useAccount, useMsal } from '@azure/msal-react';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

import { useAuthService } from '../authentication/authService';
import {
  generateUrlBase,
  generateUrlBaseApiBilling,
  generateUrlBaseApiConfig,
  generateUrlBasePrepayCards,
} from '../utils/utils';
import { ToastComponent } from '../components/ToastComponent';

class ExceptionApi extends Error {
  status: number;
  name: string;
  constructor(message: string, status = 500) {
    super(message);
    this.status = status;
    this.name = 'ExceptionApi';
  }
}

const statusMessages: { [key: number]: string } = {
  200: 'OK',
  201: 'Created',
  204: 'No Content',
  400: 'Bad Request',
  401: 'Unauthorized',
  403: 'Forbidden',
  404: 'Not Found',
  500: 'Internal Server Error',
  502: 'Bad Gateway',
  503: 'Service Unavailable',
  504: 'Gateway Timeout',
};

const createException = (statusCode: number, response: any) => {
  // const message = statusMessages[statusCode] || 'Unknown Error';
  let message = 'La operación falló, inténtelo más tarde por favor.';
  if (Array.isArray(response.data?.message)) {
    message = response.data?.message[0];
  } else if (typeof response.data?.message === 'string') {
    message = response.data?.message;
  } else if (response.data?.success === false) {
    message = response.data?.message;
  }

  if (message?.includes('Cannot insert duplicate key'))
  {
    let start_index = message.indexOf("(");
    if (start_index > 1)
    {
	    let end_index = message.indexOf(")");
      if (end_index > 1)
      {
        let value = message.substring(start_index + 1, end_index);

        message = 'No se puede insertar una clave duplicada. El valor de clave duplicado es (' + value + ').'
      }
      else
        message = 'No se puede insertar una clave duplicada.'
    }
    else
      message = 'No se puede insertar una clave duplicada.'
  }

  if (message?.includes('Object reference not set to an instance of an object.')){
    message='El archivo que intentó importar contiene errores, verifique e inténtelo de nuevo.';
  }

  ToastComponent('warning', 'Error', message, 6000);

  return new ExceptionApi(response.data.message, statusCode);
};

export default function useApi() {
  const { instance } = useMsal();
  const { getAccessToken } = useAuthService();
  const account = useAccount();

  const errorHandlers = (error: any): ExceptionApi => {
    const errorMessage = new ExceptionApi(error.message);
    return errorMessage;
  };

  // Obtener el valor de la zona horaria usando el hook personalizado del contexto

  const d = new Date();
  const diff = d.getTimezoneOffset();
  const timeOffset = diff / 60;

  // Generic API call
  const genericApiCall = async (
    url: string,
    method: AxiosRequestConfig['method'],
    data: any = {},
    params: AxiosRequestConfig['params'] = {},
    headers: AxiosRequestConfig['headers'] = {},
    attempt = 1,
    otherURl?: any
  ): Promise<AxiosResponse> => {
    try {
      const accessToken = await getAccessToken(instance, account);
      const ses = sessionStorage.getItem('sessionState');

      const combinedHeaders = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${accessToken}`,
        sessionState: ses,
        timeOffset: timeOffset,
        enumApp: 3,
        ...headers,
      };

      const urlConfig = generateUrlBase(url);

      const configAxios: AxiosRequestConfig = {
        params: params,
        method: method,
        url: urlConfig,
        headers: combinedHeaders,
        data: data,
      };

      const response = await axios(configAxios);
      return response;
    } catch (error: any) {
      const { response } = error;
      const { status } = response;

      if (status === 500 || status === 400 || status === 404) {
        throw createException(status, response);
      }

      if (attempt <= 2) {
        return genericApiCall(url, method, data, params, headers, attempt + 1);
      }

      return error;
    }
  };

  const genericApiCallPrepayCards = async (
    url: string,
    method: AxiosRequestConfig['method'],
    data: any = {},
    params: AxiosRequestConfig['params'] = {},
    headers: AxiosRequestConfig['headers'] = {},
    attempt = 1,
    otherURl?: any
  ): Promise<AxiosResponse> => {
    try {
      const accessToken = await getAccessToken(instance, account);
      const ses = sessionStorage.getItem('sessionState');

      const combinedHeaders = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${accessToken}`,
        sessionState: ses,
        timeOffset: timeOffset,
        enumApp: 3,
        ...headers,
      };

      const urlConfig = generateUrlBasePrepayCards(url);

      const configAxios: AxiosRequestConfig = {
        params: params,
        method: method,
        url: urlConfig,
        headers: combinedHeaders,
        data: data,
      };

      const response = await axios(configAxios);
      return response;
    } catch (error: any) {
      const { response } = error;
      const { status } = response;

      if (status === 500 || (status >= 400 || status <= 499)) {
        throw createException(status, response);
      }

      if (attempt <= 2) {
        return genericApiCallPrepayCards(url, method, data, params, headers, attempt + 1);
      }

      return error;
    }
  };


  const genericApiCallApiBilling = async (
    url: string,
    method: AxiosRequestConfig['method'],
    data: any = {},
    params: AxiosRequestConfig['params'] = {},
    headers: AxiosRequestConfig['headers'] = {},
    attempt = 1
  ): Promise<AxiosResponse | Error> => {
    try {
      const accessToken = await getAccessToken(instance, account);
      const ses = sessionStorage.getItem('sessionState');

      const combinedHeaders = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${accessToken}`,
        sessionState: ses,
        timeOffset: timeOffset,
        enumApp: 3,
        ...headers,
      };

      const urlConfig = generateUrlBaseApiBilling(url);

      const configAxios: AxiosRequestConfig = {
        params: params,
        method: method,
        url: urlConfig,
        headers: combinedHeaders,
        data: data,
      };

      const response = await axios(configAxios);
      return response;
    } catch (error: any) {
      const { response } = error;
      const { status } = response;

      if (status === 500 || status === 404) {
        throw createException(status, response);
      }

      if (attempt <= 2) {
        return genericApiCallApiBilling(
          url,
          method,
          data,
          params,
          headers,
          attempt + 1
        );
      }

      return error;
    }
  };

  const genericApiConfiguration = async (
    url: string,
    method: AxiosRequestConfig['method'],
    data: any = {},
    params: AxiosRequestConfig['params'] = {},
    headers: AxiosRequestConfig['headers'] = {},
    attempt = 1
  ): Promise<AxiosResponse | Error> => {
    try {
      const accessToken = await getAccessToken(instance, account);
      const ses = sessionStorage.getItem('sessionState');

      const combinedHeaders = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${accessToken}`,
        sessionState: ses,
        timeOffset: timeOffset,
        enumApp: 3,
        ...headers,
      };

      const urlConfig = generateUrlBaseApiConfig(url);

      const configAxios: AxiosRequestConfig = {
        params: params,
        method: method,
        url: urlConfig,
        headers: combinedHeaders,
        data: data,
      };

      const response = await axios(configAxios);
      return response;
    } catch (error: any) {
      const { response } = error;
      const { status } = response;

      if (status === 500 || status === 404 || status === 409) {
        throw createException(status, response);
      }

      if (attempt <= 2) {
        return genericApiConfiguration(
          url,
          method,
          data,
          params,
          headers,
          attempt + 1
        );
      }

      return error;
    }
  };

  const genericApiSecurity = async (
    url: string,
    method: AxiosRequestConfig['method'],
    data: any = {},
    params: AxiosRequestConfig['params'] = {},
    headers: AxiosRequestConfig['headers'] = {},
    attempt = 1
  ): Promise<AxiosResponse | Error> => {
    try {
      const accessToken = await getAccessToken(instance, account);
      const ses = sessionStorage.getItem('sessionState');

      const combinedHeaders = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${accessToken}`,
        sessionState: ses,
        timeOffset: timeOffset,
        enumApp: 3,
        ...headers,
      };

      const urlConfig = generateUrlBaseApiConfig(url);

      const configAxios: AxiosRequestConfig = {
        params: params,
        method: method,
        url: urlConfig,
        headers: combinedHeaders,
        data: data,
      };

      const response = await axios(configAxios);
      return response;
    } catch (error: any) {
      const { response } = error;
      const { status } = response;

      if (status === 500 || status === 404) {
        throw createException(status, response);
      }

      if (attempt <= 2) {
        return genericApiConfiguration(
          url,
          method,
          data,
          params,
          headers,
          attempt + 1
        );
      }

      return error;
    }
  };

  const dowloadExcel = async (
    pathSendExcel: string,
    document_name: string,
    template?: string
  ) => {
    const path = pathSendExcel + '/export' + template;
    const apiUrl = process.env.REACT_APP_CONFIGURATIONURI + path;

    const accessToken = await getAccessToken(instance, account);

    const headers = new Headers({
      Authorization: `Bearer ${accessToken}`,
    });

    fetch(apiUrl, { method: 'GET', headers: headers })
      .then(response => {
        return response.blob();
      })
      .then(blob => {
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = document_name + '.xlsx';
        a.click();
        URL.revokeObjectURL(url);
      })
      .catch(error => {
        console.error('Error al obtener el archivo XLSX', error);
      });
  };

  const dowloadExcelCounters = async (
  ) => {
    const path =  '/v1.0/counters/export';
    const apiUrl = process.env.REACT_APP_BILLINGAPIURI + path;

    const accessToken = await getAccessToken(instance, account);

    const headers = new Headers({
      Authorization: `Bearer ${accessToken}`,
    });

    fetch(apiUrl, { method: 'GET', headers: headers })
      .then(response => {
        return response.blob();
      })
      .then(blob => {
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download =  'Tipos de SAM.csv';
        a.click();
        URL.revokeObjectURL(url);
      })
      .catch(error => {
        console.error('Error al obtener el archivo XLSX', error);
      });
  };

  const dowloadExcelHistorySam = async (
    document_name: string,
    query: string,
  ) => {
    let path = '/v1.0/histories/export';
    if(query.length > 0){
      path+= query;
    }

    const apiUrl = process.env.REACT_APP_BILLINGAPIURI + path;

    const accessToken = await getAccessToken(instance, account);

    const headers = new Headers({
      Authorization: `Bearer ${accessToken}`,
    });
    const ses = sessionStorage.getItem('sessionState');

    const combinedHeaders = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      sessionState: ses,
      timeOffset: timeOffset,
      Authorization: `Bearer ${accessToken}`,
      enumApp: 3,
      ...headers,
    };

    fetch(apiUrl, { method: 'GET', headers: headers })
    .then(response => {
      return response.blob();
    })
    .then(blob => {
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = document_name + '.csv';
      a.click();
      URL.revokeObjectURL(url);
    })
    .catch(error => {
      console.error('Error al obtener el archivo XLSX', error);
    });
  };

  const dowloadExcelHistoryUser= async (
    document_name: string,
    query: string,
  ) => {
    let path = '/userHistory/export';
    if(query.length > 0){
      path+= query;
    }

    const apiUrl = process.env.REACT_APP_SECURITYAPIURI + path;

    const accessToken = await getAccessToken(instance, account);

    const headers = new Headers({
      Authorization: `Bearer ${accessToken}`,
    });

    fetch(apiUrl, { method: 'GET', headers: headers })
    .then(response => {
      return response.blob();
    })
    .then(blob => {
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = document_name + '.csv';
      a.click();
      URL.revokeObjectURL(url);
    })
    .catch(error => {
      console.error('Error al obtener el archivo XLSX', error);
    });
  };


  return {
    genericApiCall,
    genericApiCallApiBilling,
    genericApiConfiguration,
    dowloadExcel,
    dowloadExcelHistorySam,
    dowloadExcelHistoryUser,
    dowloadExcelCounters,
    genericApiCallPrepayCards
  };
}

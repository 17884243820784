import { MultiSelect } from 'primereact/multiselect';
import React, { useState } from 'react';

type DropdowsProps = {
  label?: string;
  name?: string;
  className?: string;
  placeholder?: string;
  onChange?: any;
  options: any[];
  optionLabel: string;
  value?: any;
  disabled?: boolean;
  max?: number;
  itemTemplate?: any;
  selectedItemsLabel?: string;
};

export const MultiSelectComponent = (props: DropdowsProps) => {
  const {
    label,
    name,
    className,
    placeholder,
    onChange,
    options,
    optionLabel,
    value,
    disabled,
    max,
    itemTemplate,
    selectedItemsLabel,
  } = props;
  let classMultiSelect = `${className} w-full md:w-20rem text-center`;
  return (
    <div className="flex justify-content-center w-full flex-col">
      <p className="font-medium text-md">{label}</p>
      <MultiSelect
        value={value}
        onChange={onChange}
        options={options}
        optionLabel={optionLabel}
        placeholder={placeholder}
        selectedItemsLabel={selectedItemsLabel}
        maxSelectedLabels={0}
        className={classMultiSelect}
        itemTemplate={itemTemplate}
      />
    </div>
  );
};

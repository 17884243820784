import { IRechargeCounterModal } from '@/interfaces/interfaceUserMenu';
import { classNames } from 'primereact/utils';
import { InputComponent } from '../../components/InputComponent';
import { PopUpComponent } from '../../components/PopUpComponent';
import { ButtonComponent } from '../../components/ButtonComponen';
import { InputNumberComponent } from '../../components/InputNumberComponent';

export const FormProfileDataModal = (props: IRechargeCounterModal) => {
  const { serviceHeader } = props;

  const bodyContent = () => {
    return (
      <form
        className="h-full w-full flex flex-col pt-5 px-6 gap-3"
        onSubmit={serviceHeader.formikPersonalData.handleSubmit}>
        <div className="flex flex-col">
          <InputComponent
            label={'Nombre'}
            type={'text'}
            name={'firstName'}
            onChange={serviceHeader.formikPersonalData.handleChange}
            value={serviceHeader.formikPersonalData?.values?.['firstName'] || ''}
            placeholder={'Nombre'}
            className={classNames({
              'p-invalid': serviceHeader.isFormFieldValid(
                'firstName',
                serviceHeader.formikPersonalData.errors,
                serviceHeader.formikPersonalData.touched
              ),
            })}
          />
          {serviceHeader.getFormErrorMessage(
            'firstName',
            serviceHeader.formikPersonalData.errors,
            serviceHeader.formikPersonalData.touched
          )}
        </div>
        <div className="flex flex-col">
          <InputComponent
            label={'Apellido'}
            type={'text'}
            name={'lastName'}
            onChange={serviceHeader.formikPersonalData.handleChange}
            value={serviceHeader.formikPersonalData?.values?.['lastName'] || ''}
            placeholder={'Apellido'}
            className={classNames({
              'p-invalid': serviceHeader.isFormFieldValid(
                'lastName',
                serviceHeader.formikPersonalData.errors,
                serviceHeader.formikPersonalData.touched
              ),
            })}
          />
          {serviceHeader.getFormErrorMessage(
            'lastName',
            serviceHeader.formikPersonalData.errors,
            serviceHeader.formikPersonalData.touched
          )}
        </div>
        <div className="flex flex-col">
          <InputComponent
            label={'Correo'}
            type={'email'}
            name={'email'}
            onChange={serviceHeader.formikPersonalData.handleChange}
            value={serviceHeader.formikPersonalData?.values?.['email'] || ''}
            placeholder={'Correo'}
            disabled={true}
            className={classNames({
              'p-invalid': serviceHeader.isFormFieldValid(
                'email',
                serviceHeader.formikPersonalData.errors,
                serviceHeader.formikPersonalData.touched
              ),
            })}
          />
          {serviceHeader.getFormErrorMessage(
            'email',
            serviceHeader.formikPersonalData.errors,
            serviceHeader.formikPersonalData.touched
          )}
        </div>
        <div className="flex flex-col">
          <InputNumberComponent
            label={'Número celular'}
            name={'phone'}
            onChange={serviceHeader.formikPersonalData.handleChange}
            value={serviceHeader.formikPersonalData?.values?.['phone'] || ''}
            placeholder={'Número celular'}
            className={classNames({
              'p-invalid': serviceHeader.isFormFieldValid(
                'phone',
                serviceHeader.formikPersonalData.errors,
                serviceHeader.formikPersonalData.touched
              ),
            })}
            useGrouping={false}
          />
          {serviceHeader.getFormErrorMessage(
            'phone',
            serviceHeader.formikPersonalData.errors,
            serviceHeader.formikPersonalData.touched
          )}
        </div>
        <div className="flex flex-col">
          <InputComponent
            label={'Rol'}
            type={'text'}
            name={'roleName'}
            onChange={serviceHeader.formikPersonalData.handleChange}
            value={serviceHeader.formikPersonalData?.values?.['roleName'] || ''}
            placeholder={'Rol'}
            disabled={true}
            className={classNames({
              'p-invalid': serviceHeader.isFormFieldValid(
                'roleName',
                serviceHeader.formikPersonalData.errors,
                serviceHeader.formikPersonalData.touched
              ),
            })}
          />
          {serviceHeader.getFormErrorMessage(
            'roleName',
            serviceHeader.formikPersonalData.errors,
            serviceHeader.formikPersonalData.touched
          )}
        </div>
        <div className='mt-4'>
         <ButtonComponent className="mt-4" label={'Guardar'} type={'submit'} loading={serviceHeader.patchUserManagerState.loading}/>
        </div>
      </form>
    );
  };

  return (
    <PopUpComponent
      header={`Información del usuario`}
      showPopUp={serviceHeader.showProfileModal}
      onClosePopUp={() => serviceHeader.closePersonalModal()}
      footer={false}
      body={bodyContent()}
    />
  );
};

import { createContext, FormEvent, useContext, useEffect, useRef, useState } from "react";
import { StoreContext } from "../../context/Provider";
import { LayoutContext } from "../../context/LayoutContext";
import { ICatalogProvider } from "../../interfaces/service/CatalogServiceManagerInterface";
import { CatalogType, ICatalogList, IDeviceTypes, IEconomicNumber, ILocations, IOperators, IPos, IProject, IRoutes, IStations, ITransportCompanies } from "../../interfaces/interfaceCatalogs";
import { elementList } from "../../pages/Catalogs/components/catalogListHelper/helper";
import { ICounterTypes, ISamCounterTypes, TableStatusType } from "../../interfaces/interfaceCounterTypes";
import { deviceTypesColumns, economicNumberColumns, locationsColumns, operatorsColumns, pointOfSaleColumns, projectsColumns, routesColumns, stationsColumns, transportCompaniesColumns } from "../../pages/Catalogs/components/forms/utils/getColumns";
import { useDeviceTypesCatalogs } from "../../hooks/catalogs/useDeviceTypesCatalogs";
import { useLocationCatalogs } from "../../hooks/catalogs/useLocationsCatalogs";
import { usePcModulesCatalog } from "../../hooks/catalogs/usePcModulesCatalog";
import { useOperatorsCatalogs } from "../../hooks/catalogs/useOperatorsCatalogs";
import { useEconomicNumbersCatalogs } from "../../hooks/catalogs/useEconomicNumbersCatalogs";
import { usePointsOfSalesCatalog } from "../../hooks/catalogs/usePointsOfSalesCatalog";
import { usegetProjectsCatalogs } from "../../hooks/catalogs/useProjectsCatalog";
import { useRoutesCatalogs } from "../../hooks/catalogs/useRoutesCatalogs";
import { useStationsCatalogs } from "../../hooks/catalogs/useStationsCatalogs";
import { useSamCounterCatalogs } from "../../hooks/catalogs/useSamCounterCatalog";
import { useTransportCompaniesCatalogs } from "../../hooks/catalogs/useTransportCompaniesCatalogs";
import useApi from "../../services/request.service";
import { FormikErrors, FormikTouched, FormikValues } from "formik";
import { DeviceTypesValidations, economicNumberValidations, locationsValidation, operatorsValidations, PosValidations, ProjectValidations, RoutesValidations, stationValidations, validationsTransportCompanies } from "../../pages/Catalogs/components/forms/validations_form";
import useCustomFormik from "../../pages/Catalogs/components/forms/utils/formik_catalog_Config";

export const CatalogContextSM = createContext<ICatalogProvider>({} as ICatalogProvider);

export const CatalogProvider = (props: any) => {
  //Index States/Ref
  const [openRightMenu, setOpenRightMenu] = useState(false);
  const [dataRow, setDataRow] = useState<any>(null);
  const [selectMenu, setSelectMenu] = useState<CatalogType>(CatalogType.InitMenu);
  const [updateCatalogTable, setUpdateCatalogTable] = useState<any>(null);
  const [documentName, setDocumentName] = useState<string>('');
  const [catalog, setCatalog] = useState<CatalogType>(CatalogType.InitMenu);
  const menuRef = useRef<HTMLDivElement>(null);
  //CatalogList States
  const [selectCatalog, setSelectCatalog] = useState<string>('');

  const [catalogList, setCatalogList] = useState<ICatalogList[]>([])
  //TableCatalog States
  const [sort, setSort] = useState([{ field: '', order: -1 }]);
  const [columns, setColumns] = useState<any>([]);
  const [dataCatalogTable, setDataCatalogTable] = useState<any>([]);
  const [tableTitle, setTableTitle] = useState<string>('sin datos');
  const [pathSendExcel, setPathSendExcel] = useState<string>('');
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [itemNameToDelete, setItemNameToDelete] = useState<string>('');
  const [itemIdToDelete, setItemIdToDelete] = useState<number>();
  const [showCloseRightMenuModal, setShowCloseRightMenuModal] = useState<boolean>(false)


  const [file, setFile] = useState<any | null>(null);

  //form
  const [buttonLabel, setButtonLabel] = useState<string>('Agregar');
  const [inputs, setInputs] = useState<any>();
  const [routes, setRoutes] = useState<any>();
  const [currentFormik, setCurrentFormik] = useState<any>(null);
  const [currentData, setCurrentData] = useState<any>();

  const {
    getLocationCatalogState,
    postLocationsCatalogState,
    putLocationsCatalogState,
    deleteLocationsCatalogState,

    getTransportCompaniesCatalogState,
    postTransportCompaniesCatalogState,
    putTransportCompaniesCatalogState,
    deleteTransportCompaniesCatalogState,

    getStationsCatalogState,
    postStationsCatalogState,
    putStationsCatalogState,
    deleteStationsCatalogState,

    getEconomicNumbersCatalogState,
    postEconomicNumbersCatalogState,
    putEconomicNumbersCatalogState,
    deleteEconomicNumbersCatalogState,

    getOperatorsCatalogState,
    postOperatorsCatalogState,
    putOperatorsCatalogState,
    deleteOperatorsCatalogState,

    getPointsOfSalesCatalogState,
    postPosCatalogState,
    putPosCatalogState,
    deletePosCatalogState,

    getProjectsCatalogState,
    postProjectsCatalogState,
    putProjectsCatalogState,
    deleteProjectsCatalogState,

    getRoutesCatalogState,
    postRoutesCatalogState,
    putRoutesCatalogState,
    deleteRoutesCatalogState,

    getDeviceTypesCatalogState,
    postExportDeviceTypesCatalogState,
    postDeviceTypesCatalogState,
    putDeviceTypesCatalogState,
    deleteDeviceTypesCatalogState,

    catalogSamCounter,
    patchSamCounter,
  } = useContext(StoreContext);

  const { changeStatusCloseMenu } = useContext(LayoutContext)

  const {
    getTransportCompaniesCatalog,
    putTransportCompaniesCatalog,
    postTransportCompaniesCatalog,
    deleteTransportCompaniesCatalog
  } = useTransportCompaniesCatalogs();
  const {
    getStationsCatalog,
    postStationsCatalog,
    putStationsCatalog,
    deleteStationsCatalog
  } = useStationsCatalogs();
  const {
    getLocationCatalog,
    postLocationsCatalog,
    putLocationsCatalog,
    deleteLocationsCatalog
  } = useLocationCatalogs();
  const {
    getEconomicNumbersCatalog,
    postEconomicNumbersCatalog,
    putEconomicNumbersCatalog,
    deleteEconomicNumbersCatalog
  } = useEconomicNumbersCatalogs();
  const {
    getOperatorsCatalog,
    postOperatorsCatalog,
    putOperatorsCatalog,
    deleteOperatorsCatalog
  } = useOperatorsCatalogs();
  const {
    getPointsOfSalesCatalog,
    postPointsOfSalesCatalog,
    putPointsOfSalesCatalog,
    deletePointsOfSalesCatalog
  } = usePointsOfSalesCatalog();
  const {
    getProjectsCatalog,
    postProjectsCatalog,
    putProjectsCatalog,
    deleteProjectsCatalog
  } = usegetProjectsCatalogs();
  const {
    getRoutesCatalog,
    postRoutesCatalog,
    putRoutesCatalog,
    deleteRoutesCatalog
  } = useRoutesCatalogs();
  const {
    postExportDeviceTypesCatalog,
    getDeviceTypesCatalog,
    postDeviceTypesCatalog,
    putDeviceTypesCatalog,
    deleteDeviceTypesCatalog
  } = useDeviceTypesCatalogs();
  const { getSamCounterCatalog } = useSamCounterCatalogs();

  const { getPcModulesCatalog } = usePcModulesCatalog();


  const { dowloadExcel, dowloadExcelCounters } = useApi();

  // UseEffect del catalogo seleccionado
  useEffect(() => {
    if (getTransportCompaniesCatalogState.state.data !== null) {
      const { data } = getTransportCompaniesCatalogState.state;
      setColumns(transportCompaniesColumns);
      setPathSendExcel('/transport-companies');
      setDataCatalogTable(data);
    }
  }, [getTransportCompaniesCatalogState.state]);

  useEffect(() => {
    if (getStationsCatalogState.state.data !== null) {
      const { data } = getStationsCatalogState.state;
      setColumns(stationsColumns);
      setPathSendExcel('/stations');
      setDataCatalogTable(data);
    }
  }, [getStationsCatalogState.state]);

  useEffect(() => {
    if (getLocationCatalogState.state.data !== null) {
      const { data } = getLocationCatalogState.state;
      setColumns(locationsColumns);
      setPathSendExcel('/locations');
      setDataCatalogTable(data);
    }
  }, [getLocationCatalogState.state]);

  useEffect(() => {
    if (getEconomicNumbersCatalogState.state.data !== null) {
      const { data } = getEconomicNumbersCatalogState.state;
      setColumns(economicNumberColumns);
      setPathSendExcel('/economic-numbers');
      setDataCatalogTable(data);
    }
  }, [getEconomicNumbersCatalogState.state]);

  useEffect(() => {
    if (getOperatorsCatalogState.state.data !== null) {
      const { data } = getOperatorsCatalogState.state;
      setColumns(operatorsColumns);
      setPathSendExcel('/operators');
      setDataCatalogTable(data);
    }
  }, [getOperatorsCatalogState.state]);

  useEffect(() => {
    if (getPointsOfSalesCatalogState.state.data !== null) {
      const { data } = getPointsOfSalesCatalogState.state;
      setColumns(pointOfSaleColumns);
      setPathSendExcel('/pos');
      setDataCatalogTable(data);
    }
  }, [getPointsOfSalesCatalogState.state]);

  useEffect(() => {
    if (getProjectsCatalogState.state.data !== null) {
      const { data } = getProjectsCatalogState.state;
      setColumns(projectsColumns);
      setPathSendExcel('/projects');
      setDataCatalogTable(data);
    }
  }, [getProjectsCatalogState.state]);

  useEffect(() => {
    if (getRoutesCatalogState.state.data !== null) {
      const { data } = getRoutesCatalogState.state;
      setColumns(routesColumns);
      setPathSendExcel('/routes');
      setDataCatalogTable(data);
      changeStatusCloseMenu(true);
      setRoutes(data)
    }
  }, [getRoutesCatalogState.state]);

  useEffect(() => {
    if (getDeviceTypesCatalogState.state.data !== null) {
      const { data } = getDeviceTypesCatalogState.state;
      setColumns(deviceTypesColumns);
      setPathSendExcel('/device-types');
      setDataCatalogTable(data);
    }
  }, [getDeviceTypesCatalogState.state]);

  useEffect(() => {
    if (catalogSamCounter.state.data !== null) {
      const { data } = catalogSamCounter.state;
      const counterColumns = generateDinamicColumns(data);
      setColumns(counterColumns);
      const counterFormatedData = generateFormatedCounterdata(data);
      setDataCatalogTable(counterFormatedData);
      setPathSendExcel('/sam-counter');
    }
  }, [catalogSamCounter.state]);


  //Index Effect
  useEffect(() => {
    const handleClick = (event: MouseEvent) => handleClickOutside(event, inputs, currentData)
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    }
  }, [inputs, currentData]);

  //form Effect
  useEffect(() => {
    if (!dataRow || dataRow?.length === 0) {
      setButtonLabel('Agregar');
    } else {
      setButtonLabel('Actualizar');
    }
  }, [dataRow]);

  //TableCatalogComponents Effect
  useEffect(() => {
    if (catalog) {
      setTableTitle(catalog);
      if (tableTitle !== catalog) {
        // getTableData();
        const inputFile = document.getElementById('file') as HTMLInputElement;
        if (inputFile) {
          inputFile.value = '';
          setFile(null);
        }
      }
    }
  }, [catalog]);

  //Custom UseEffect
  useEffect(() => {
    const defaultCatalogList = elementList();
    setCatalogList(defaultCatalogList);
  }, []);

  //Index functions
  function handleClickOutside(e: MouseEvent, initialValue: any, currentValue: any) {
    if (
      !document.querySelector('.flex.w-full.h-full.relative.overflow-hidden.flex-col.items-center.dark\\:bg-\\[\\#CECECE\\]')?.contains(e.target as Node) &&
      !document.querySelector('.p-dropdown-panel')?.contains(e.target as Node) &&
      !document.querySelector('.p-multiselect-panel')?.contains(e.target as Node) &&
      !document.querySelector('.p-dialog-mask')?.contains(e.target as Node) &&
      !document.querySelector('.p-datepicker')?.contains(e.target as Node) &&
      !document.querySelector('.Toastify')?.contains(e.target as Node)
    ) {
      if (initialValue && currentValue) {
        verifyForm(initialValue, currentValue);
      }
    }

    if (document.querySelector('.p-dialog-mask')) {
      setOpenRightMenu(true);
      changeStatusCloseMenu(true);
    }
  }

  function selectDocumentName(catalog: string) {
    setDocumentName(catalog);
  };

  //funciones de CatalogList
  function catalogSelect(item: any) {
    setCatalog(item.name);
    setSelectCatalog(item.name);
    setDocumentName(item.document_name);
    setOpenRightMenu(false);
    getData(item.name)
  };

  async function handleSubmitExcel(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();

    if (file) {
      const formData = new FormData();
      formData.append('file', file);

      let response = await postExportDeviceTypesCatalog(
        pathSendExcel,
        formData
      );
      if (response.status === 200) {
        getData(catalog);
      }
      setFile(null);
    }
  }

  function handleFileChange(event: any) {
    const fileInput = event.target.files;
    if (event.target.files && event.target.files[0]) {
      setFile(event.target.files[0]);
      event.target.value = null;
    }
  }

  function updateTable() {
    getData(catalog);
    setShowDeleteModal(false);
    setOpenRightMenu(false);
  }

  function openTableRightMenu(row?: any) {
    if (row !== undefined) {
      setDataRow(row)
      setInputs(row);
    } else {
      const typeData = getCatalogObject(catalog)
      setDataRow([]);
      setInputs(typeData);
    }

    setOpenRightMenu(true);
    setSelectMenu(catalog);
    setUpdateCatalogTable(() => updateTable);
  }

  //funciones de TableCatalog
  function generateDinamicColumns(data: ISamCounterTypes[]) {
    const mappedColumn = data.map((items: ISamCounterTypes) => {
      let nameField = {
        field: 'Name',
        header: 'Nombre',
        sortable: true
      }
      let codeField = {
        field: 'Code',
        header: 'Codigo',
        sortable: true
      }

      const counterFields = items.CounterTypes.map((item: ICounterTypes) => {
        return {
          field: 'Status' + item.Address,
          header: item.Address,
          sortable: true
        }
      });
      return [codeField, nameField, ...counterFields]
    });
    return mappedColumn[0];
  }

  function handlerCloseModal() {
    setShowDeleteModal(false);
  }

  function exportTemplateExcel() {
    dowloadExcel(pathSendExcel, documentName, '/template');
  }

  function exportExcel() {
    if (pathSendExcel === "/sam-counter")
      dowloadExcelCounters();
    else
      dowloadExcel(pathSendExcel, documentName, '');
  }

  function openDeleteModal(row?: any) {
    setShowDeleteModal(true);
    let item = getItemNameToDelete(row);
    let id = getItemIdToDelete(row);
    setItemNameToDelete(item);
    setItemIdToDelete(id);
  };

  function closeConfirmCloseRightMenuModal() {
    setShowCloseRightMenuModal(false);
  }

  function confirmCloseRightMenuModal() {
    setOpenRightMenu(false);
    setShowCloseRightMenuModal(false)
    changeStatusCloseMenu(false);
    setCurrentData(undefined)
    resetAllForms();
  }

  function verifyForm(initialValue: any, currentValue: any) {
    const compare = compareObjects(initialValue, currentValue);
    if (!compare) {
      setOpenRightMenu(true);
      setShowCloseRightMenuModal(true)
    } else {
      console.log("Ningún formulario tiene datos modificados");
    }
  }

  function generateFormatedCounterdata(data: ISamCounterTypes[]) {
    const result: TableStatusType[] = [];

    data.forEach((item: ISamCounterTypes) => {
      const formattedItem: TableStatusType = {
        Code: item.Code,
        Name: item.Name,
        SamTypeId: item.SamTypeId
      };

      item.CounterTypes.forEach((counter: ICounterTypes) => {
        const statusKey = `Status${counter.Address}`;
        const statusId = counter.Address + counter.Id;
        formattedItem[statusKey] = counter.Status;
        formattedItem[statusId] = counter.Id;
      });

      result.push(formattedItem);
    });
    return result;
  };

  function BodyTemplate(item: any, key: any, tableBooleanValue: (text: boolean) => JSX.Element, labelTemplate: (text: any) => JSX.Element) {
    let textColor = item[key.field];

    if (typeof item[key.field] === 'boolean' && catalog === CatalogType.SamTypes) {
      return tableBooleanValue(textColor);
    }

    if (
      key.field === 'id' &&
      item['samTypeName'] === null &&
      item['transportCompanyName'] === null
    )
      return labelTemplate(textColor);

    return textColor;
  };

  //funciones para obtener los catalogos
  async function getTransportCompanies() {
    return await getTransportCompaniesCatalog();
  };

  async function getStations() {
    return await getStationsCatalog();
  };

  async function getLocations() {
    return await getLocationCatalog();
  };

  async function getPcModules() {
    return await getPcModulesCatalog();
  };

  async function getEconomicNumbers() {
    await getRoutes();
    return await getEconomicNumbersCatalog();
  };

  async function getOperators() {
    return await getOperatorsCatalog();
  };

  async function getPointsOfSale() {
    return await getPointsOfSalesCatalog();
  };

  async function getProjects() {
    return await getProjectsCatalog();
  };

  async function getRoutes() {
    return await getRoutesCatalog();
  };

  async function getDeviceTypes() {
    return await getDeviceTypesCatalog();
  };

  async function getSamCounter() {
    return await getSamCounterCatalog();
  };

  const catalogGetFunctions: { [key in CatalogType]?: () => Promise<void> } = {
    [CatalogType.TransportCompanies]: getTransportCompanies,
    [CatalogType.Stations]: getStations,
    [CatalogType.Locations]: getLocations,
    [CatalogType.PcModules]: getPcModules,
    [CatalogType.EconomicNumbers]: getEconomicNumbers,
    [CatalogType.Operators]: getOperators,
    [CatalogType.PointOfSales]: getPointsOfSale,
    [CatalogType.Projects]: getProjects,
    [CatalogType.Routes]: getRoutes,
    [CatalogType.DeviceTypes]: getDeviceTypes,
    [CatalogType.SamTypes]: getSamCounter,
  };

  async function getData(titleProp: CatalogType) {
    const catalogFunction = catalogGetFunctions[titleProp];
    if (catalogFunction) {
      await catalogFunction();
    }
  };

  async function postTransportCompanies(values: any) {
    return await postTransportCompaniesCatalog(values);
  };

  async function postStations(values: any) {
    return await postStationsCatalog(values);
  };

  async function postLocations(values: any) {
    return await postLocationsCatalog(values);
  };

  async function postPcModules() {
    // return await getPcModulesCatalog();
  };

  async function postEconomicNumbers(values: any) {
    return await postEconomicNumbersCatalog(values)
  };

  async function postOperators(values: any) {
    return await postOperatorsCatalog(values);
  };

  async function postPointsOfSale(values: any) {
    return await postPointsOfSalesCatalog(values);
  };

  async function postProjects(values: any) {
    return await postProjectsCatalog(values);
  };

  async function postRoutes(values: any) {
    return await postRoutesCatalog(values);
  };

  async function postDeviceTypes(values: any) {
    return await postDeviceTypesCatalog(values);
  };

  async function postSamCounter() {
    // return await getSamCounterCatalog();
  };

  const catalogPostFunctions: { [key in CatalogType]?: (values: any) => Promise<void> } = {
    [CatalogType.TransportCompanies]: postTransportCompanies,
    [CatalogType.Stations]: postStations,
    [CatalogType.Locations]: postLocations,
    [CatalogType.PcModules]: postPcModules,
    [CatalogType.EconomicNumbers]: postEconomicNumbers,
    [CatalogType.Operators]: postOperators,
    [CatalogType.PointOfSales]: postPointsOfSale,
    [CatalogType.Projects]: postProjects,
    [CatalogType.Routes]: postRoutes,
    [CatalogType.DeviceTypes]: postDeviceTypes,
    [CatalogType.SamTypes]: postSamCounter,
  }

  async function putTransportCompanies(values: any) {
    return await putTransportCompaniesCatalog(values);
  };

  async function putStations(values: any) {
    return await putStationsCatalog(values);
  };

  async function putLocations(values: any) {
    return await putLocationsCatalog(values);
  };

  async function putPcModules() {
    // return await getPcModulesCatalog();
  };

  async function putEconomicNumbers(values: any) {
    return await putEconomicNumbersCatalog(values)
  };

  async function putOperators(values: any) {
    return await putOperatorsCatalog(values);
  };

  async function putPointsOfSale(values: any) {
    return await putPointsOfSalesCatalog(values);
  };

  async function putProjects(values: any) {
    return await putProjectsCatalog(values);
  };

  async function putRoutes(values: any) {
    return await putRoutesCatalog(values);
  };

  async function putDeviceTypes(values: any) {
    return await putDeviceTypesCatalog(values);
  };

  async function putSamCounter() {
    // return await getSamCounterCatalog();
  };

  const catalogPutFunctions: { [key in CatalogType]?: (values: any) => Promise<void> } = {
    [CatalogType.TransportCompanies]: putTransportCompanies,
    [CatalogType.Stations]: putStations,
    [CatalogType.Locations]: putLocations,
    [CatalogType.PcModules]: putPcModules,
    [CatalogType.EconomicNumbers]: putEconomicNumbers,
    [CatalogType.Operators]: putOperators,
    [CatalogType.PointOfSales]: putPointsOfSale,
    [CatalogType.Projects]: putProjects,
    [CatalogType.Routes]: putRoutes,
    [CatalogType.DeviceTypes]: putDeviceTypes,
    [CatalogType.SamTypes]: putSamCounter,
  }

  // funciones para formulario
  async function handleSubmitForm(values: any) {
    resetAllForms();

    if (buttonLabel === 'Actualizar') {
      let id = dataRow.id;
      values['id'] = id;
      let currentPut = catalogPutFunctions[catalog];
      if (currentPut) {
        return await currentPut(values).finally(async () => {
          updateTable();
        });
      }
    } else if (buttonLabel === 'Agregar') {
      let currentPost = catalogPostFunctions[catalog];
      if (currentPost) {
        return await currentPost(values).finally(async () => {
          updateTable();
        })
      }
    }
  };

  function onFormChange(changedFields: { [key: string]: any }, values: any) {
    setCurrentData(values)
  };


  function isFormFieldValid(
    name: string,
    errors: FormikErrors<FormikValues>,
    touched: FormikTouched<FormikValues>
  ) {
    return !!(touched[name] && errors[name]);
  }

  function getFormErrorMessage(
    name: string,
    errors: FormikErrors<FormikValues>,
    touched: FormikTouched<FormikValues>
  ) {
    const error = errors[name];
    const errorMessage = typeof error === 'string' ? error : '';
    return (
      isFormFieldValid(name, errors, touched) && (
        <small className="p-error">{errorMessage}</small>
      )
    );
  };

  async function deleteTransportCompanies(values: any) {
    return await deleteTransportCompaniesCatalog(values);
  };

  async function deleteStations(values: any) {
    return await deleteStationsCatalog(values);
  };

  async function deleteLocations(values: any) {
    return await deleteLocationsCatalog(values);
  };

  async function deletePcModules() {
    // return await getPcModulesCatalog();
  };

  async function deleteEconomicNumbers(values: any) {
    return await deleteEconomicNumbersCatalog(values)
  };

  async function deleteOperators(values: any) {
    return await deleteOperatorsCatalog(values);
  };

  async function deletePointsOfSale(values: any) {
    return await deletePointsOfSalesCatalog(values);
  };

  async function deleteProjects(values: any) {
    return await deleteProjectsCatalog(values);
  };

  async function deleteRoutes(values: any) {
    return await deleteRoutesCatalog(values);
  };

  async function deleteDeviceTypes(values: any) {
    return await deleteDeviceTypesCatalog(values);
  };

  async function deleteSamCounter() {
    // return await getSamCounterCatalog();
  };

  const catalogDeleteFunctions: { [key in CatalogType]?: (values: any) => Promise<void> } = {
    [CatalogType.TransportCompanies]: deleteTransportCompanies,
    [CatalogType.Stations]: deleteStations,
    [CatalogType.Locations]: deleteLocations,
    [CatalogType.PcModules]: deletePcModules,
    [CatalogType.EconomicNumbers]: deleteEconomicNumbers,
    [CatalogType.Operators]: deleteOperators,
    [CatalogType.PointOfSales]: deletePointsOfSale,
    [CatalogType.Projects]: deleteProjects,
    [CatalogType.Routes]: deleteRoutes,
    [CatalogType.DeviceTypes]: deleteDeviceTypes,
    [CatalogType.SamTypes]: deleteSamCounter,
  }

  async function deleteCatalogItem() {
    const deleteItemFromCatalog = catalogDeleteFunctions[catalog];
    if (deleteItemFromCatalog) {
      await deleteItemFromCatalog(itemIdToDelete).finally(async () => {
        handlerCloseModal();
        updateTable();
      })
    }
  }

  function getItemNameToDelete(item: any) {
    return item?.name || item?.economicNumber || item?.businessName || item?.locationId || '';
  }
  function getItemIdToDelete(item: any) {
    return item?.id || item?.economicNumberId || item?.routeId || item?.deviceTypeId
  }
  //constantes a mover de Index
  const context = useContext(StoreContext);
  const {
    getPcModulesCatalogState,
    getExportCatalogState,
  } = context;

  const loading =
    getOperatorsCatalogState.state.loading ||
    getLocationCatalogState.state.loading ||
    getDeviceTypesCatalogState.state.loading ||
    postExportDeviceTypesCatalogState.state.loading ||
    getEconomicNumbersCatalogState.state.loading ||
    getPcModulesCatalogState.loading ||
    getPointsOfSalesCatalogState.state.loading ||
    getProjectsCatalogState.state.loading ||
    getRoutesCatalogState.state.loading ||
    getStationsCatalogState.state.loading ||
    getTransportCompaniesCatalogState.state.loading ||
    getExportCatalogState.loading ||
    deleteTransportCompaniesCatalogState.state.loading ||
    deleteProjectsCatalogState.state.loading ||
    deleteRoutesCatalogState.state.loading ||
    deleteStationsCatalogState.state.loading ||
    deleteOperatorsCatalogState.state.loading ||
    deleteLocationsCatalogState.state.loading ||
    deleteDeviceTypesCatalogState.state.loading ||
    deleteEconomicNumbersCatalogState.state.loading ||
    deletePosCatalogState.state.loading ||
    postPosCatalogState.state.loading ||
    putPosCatalogState.state.loading ||
    postProjectsCatalogState.state.loading ||
    putProjectsCatalogState.state.loading ||
    postRoutesCatalogState.state.loading ||
    putRoutesCatalogState.state.loading ||
    postStationsCatalogState.state.loading ||
    putStationsCatalogState.state.loading ||
    postTransportCompaniesCatalogState.state.loading ||
    putTransportCompaniesCatalogState.state.loading ||
    postOperatorsCatalogState.state.loading ||
    putOperatorsCatalogState.state.loading ||
    postLocationsCatalogState.state.loading ||
    putLocationsCatalogState.state.loading ||
    postDeviceTypesCatalogState.state.loading ||
    putDeviceTypesCatalogState.state.loading ||
    postEconomicNumbersCatalogState.state.loading ||
    putEconomicNumbersCatalogState.state.loading ||
    catalogSamCounter.state.loading ||
    patchSamCounter.state.loading;
  ;


  const objAsTransportCompaniesInterface: ITransportCompanies = {
    businessName: '',
    eur: '',
    status: false,
  };
  const objAsStationsInterface: IStations = {
    stationId: '',
    name: '',
    status: false,
  };
  const objAsLocationsInterface: ILocations = {
    locationId: '',
    status: false,
  };
  const objAsEcnomicNumberInterface: IEconomicNumber = {
    economicNumber: '',
    routeId: '',
    status: false,
  };
  const objAsOperatorsInterface: IOperators = {
    technologicalOperatorId: '',
    name: '',
    providerNumber: 0,
    address: '',
    phone: '',
    status: false,
  };
  const objAsPosInterface: IPos = {
    pointOfSaleId: '',
    name: '',
    status: false,
  };
  const objAsProjectsInterface: IProject = {
    projectId: '',
    name: '',
    status: false,
  };
  const objAsRoutesInterface: IRoutes = {
    routeNumber: '',
    name: '',
    status: false,
  };
  const objAsDeviceTypesInterface: IDeviceTypes = {
    name: '',
    status: false,
  };
  const catalogObjectFactory: Partial<Record<CatalogType, any>> = {
    [CatalogType.TransportCompanies]: objAsTransportCompaniesInterface,
    [CatalogType.Stations]: objAsStationsInterface,
    [CatalogType.Locations]: objAsLocationsInterface,
    [CatalogType.PcModules]: () => console.log("Acción para Módulos PC"),
    [CatalogType.EconomicNumbers]: objAsEcnomicNumberInterface,
    [CatalogType.Operators]: objAsOperatorsInterface,
    [CatalogType.PointOfSales]: objAsPosInterface,
    [CatalogType.Projects]: objAsProjectsInterface,
    [CatalogType.Routes]: objAsRoutesInterface,
    [CatalogType.DeviceTypes]: objAsDeviceTypesInterface,
  };

  function getCatalogObject(type: CatalogType): any {
    const catalogObject = catalogObjectFactory[type];
    if (catalogObject) {
      return catalogObject;
    } else {
      throw new Error("Tipo de catálogo no reconocido");
    }
  }
  //const de formularios
  const formikTransportCompanies = useCustomFormik(
    inputs,
    validationsTransportCompanies,
    handleSubmitForm,
    onFormChange
  );

  const formikStations = useCustomFormik(
    inputs,
    stationValidations,
    handleSubmitForm,
    onFormChange
  );

  const formikLocations = useCustomFormik(
    inputs,
    locationsValidation,
    handleSubmitForm,
    onFormChange
  );

  const formikEconomicNumber = useCustomFormik(
    inputs,
    economicNumberValidations,
    handleSubmitForm,
    onFormChange
  );

  const formikOperators = useCustomFormik(
    inputs,
    operatorsValidations,
    handleSubmitForm,
    onFormChange
  );

  const formikPos = useCustomFormik(
    inputs,
    PosValidations,
    handleSubmitForm,
    onFormChange
  );

  const formikProject = useCustomFormik(
    inputs,
    ProjectValidations,
    handleSubmitForm,
    onFormChange
  );

  const formikRoutes = useCustomFormik(
    inputs,
    RoutesValidations,
    handleSubmitForm,
    onFormChange
  );

  const formikDeviceTypes = useCustomFormik(
    inputs,
    DeviceTypesValidations,
    handleSubmitForm,
    onFormChange
  );

  const formikInstances: Partial<Record<CatalogType, any>> = {
    [CatalogType.TransportCompanies]: formikTransportCompanies,
    [CatalogType.Stations]: formikStations,
    [CatalogType.Locations]: formikLocations,
    [CatalogType.PcModules]: () => console.log("Acción para Módulos PC"),
    [CatalogType.EconomicNumbers]: formikEconomicNumber,
    [CatalogType.Operators]: formikOperators,
    [CatalogType.PointOfSales]: formikPos,
    [CatalogType.Projects]: formikProject,
    [CatalogType.Routes]: formikRoutes,
    [CatalogType.DeviceTypes]: formikDeviceTypes
  }

  function resetAllForms(): void {
    Object.values(formikInstances).forEach((formik) => {
      if (formik && typeof formik.resetForm === 'function') {
        formik.resetForm();
      }
    });
    setCurrentData(undefined)
    setOpenRightMenu(false);
  }

  function compareObjects(initialValue: any, currentValue: any): boolean {
    const commonKeys = Object.keys(initialValue).filter(key => key in currentValue);
    for (const key of commonKeys) {
      if (initialValue[key] !== currentValue[key]) {
        return false;
      }
    }
    return true;
  }



  return (
    <CatalogContextSM.Provider
      value={{
        menuRef,
        dataRow,
        selectMenu,
        tableTitle,
        updateCatalogTable,
        catalog,
        catalogList,
        selectCatalog,
        sort,
        columns,
        file,
        dataCatalogTable,
        inputs,
        buttonLabel,
        routes,
        showDeleteModal,
        itemNameToDelete,
        showCloseRightMenuModal,
        formikTransportCompanies,
        formikStations,
        formikLocations,
        formikEconomicNumber,
        formikOperators,
        formikPos,
        formikProject,
        formikRoutes,
        formikDeviceTypes,

        //modificar loading y rightmenu
        loading,
        openRightMenu,
        setOpenRightMenu,

        catalogSelect,
        openTableRightMenu,
        openDeleteModal,
        setSort,
        handleSubmitExcel,
        handleFileChange,
        handleSubmitForm,
        BodyTemplate,
        exportTemplateExcel,
        exportExcel,
        isFormFieldValid,
        getFormErrorMessage,
        updateTable,
        handlerCloseModal,
        deleteCatalogItem,
        closeConfirmCloseRightMenuModal,
        confirmCloseRightMenuModal,
      }}>
      {props.children}
    </CatalogContextSM.Provider>)
}
import * as Yup from 'yup';

export const validationsTransportCompanies = Yup.object({
  businessName: Yup.string()
    .min(3, 'Debe tener una longitud mayor')
    .max(200, 'La longitud maxima es 200')
    .required('Campo requerido'),
  eur: Yup.string()
    .required('Campo requerido')
    .max(100, 'La longitud maxima es 100'),
  status: Yup.string().required('Campo requerido'),
});

export const stationValidations = Yup.object({
  stationId: Yup.string()
    .required('Campo requerido')
    .max(15, 'La longitud maxima es 15'),
  name: Yup.string()
    .required('Campo requerido')
    .max(100, 'La longitud maxima es 100'),
  status: Yup.boolean().required('Campo requerido'),
});

export const DeviceTypesValidations = Yup.object({
  name: Yup.string()
    .required('Campo requerido')
    .max(100, 'La longitud maxima es 100'),
  status: Yup.boolean().required('Campo requerido'),
});

export const RoutesValidations = Yup.object({
  routeNumber: Yup.string()
    .required('Campo requerido')
    .max(30, 'La longitud maxima es 30'),
  name: Yup.string()
    .required('Campo requerido')
    .max(100, 'La longitud maxima es 100'),
  status: Yup.boolean().required('Campo requerido'),
});

export const ProjectValidations = Yup.object({
  name: Yup.string()
    .required('Campo requerido')
    .max(100, 'La longitud maxima es 100'),
  status: Yup.boolean().required('Campo requerido'),
});

export const economicNumberValidations = Yup.object({
  economicNumber: Yup.string()
    .required('Campo requerido')
    .max(15, 'La longitud maxima es 15'),
  routeId: Yup.string().required('Campo requerido'),
  status: Yup.boolean().required('Campo requerido'),
});

export const operatorsValidations = Yup.object({
  technologicalOperatorId: Yup.string()
    .required('Campo requerido')
    .max(15, 'La longitud maxima es 15'),
  name: Yup.string()
    .required('Campo requerido')
    .max(100, 'La longitud maxima es 100'),
  providerNumber: Yup.number().notRequired().nullable(),
  address: Yup.string().notRequired().nullable(),
  phone: Yup.string().notRequired().nullable(),
  status: Yup.boolean().required('Campo requerido'),
});

export const locationsValidation = Yup.object({
  locationId: Yup.string()
    .required('Campo requerido')
    .max(25, 'La longitud maxima es 25'),
  status: Yup.boolean().required('Campo requerido'),
});

export const PosValidations = Yup.object({
  pointOfSaleId: Yup.string()
    .required('Campo requerido')
    .max(15, 'La longitud maxima es 15'),
  name: Yup.string()
    .required('Campo requerido')
    .max(100, 'La longitud maxima es 100'),
  status: Yup.boolean().required('Campo requerido'),
});

import React, { useEffect, useState } from 'react';

interface SelectProps {
  options: any[];
  defaultValue?: string;
  onChange?: any;
  name: string;
  optionLabel: string;
  value?: string | number;
  label: string;
  optionValue: string;
  classNames?: string;
}
export const BasicSelectComponent = (props: SelectProps) => {
  const {
    options,
    defaultValue,
    onChange,
    name,
    value,
    label,
    optionLabel,
    optionValue,
  } = props;

  return (
    <div className="flex justify-content-center w-full flex-col mt-3">
      <p className="font-medium text-md">{label}</p>
      <select
        value={value}
        key={value}
        onChange={onChange}
        name={name}
        className="w-full h-12 border border-solid border-border_input bg-white px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline cursor-pointer">
        <option key={0} value={''}>
          Seleccione una opción
        </option>
        {options?.map((option: any, index: number) => (
          <option key={index} value={option[optionValue]}>
            {option[optionLabel]}
          </option>
        ))}
      </select>
    </div>
  );
};

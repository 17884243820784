import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { ButtonComponent } from './ButtonComponen';

type IDropdowsProps = {
  header?: any;
  body?: any;
  labelCancelText?: string;
  labelSubmitText?: string;
  disabledOnSubmitButton?: boolean;
  footerOnlyCancel?: boolean;
  footer?: boolean;
  showPopUp: boolean;
  onSubmit: () => void;
  onClosePopUp: () => void;
};

export const PopUpComponent = (props: IDropdowsProps) => {
  const {
    header,
    body,
    showPopUp,
    footerOnlyCancel,
    footer,
    onSubmit,
    onClosePopUp,
    disabledOnSubmitButton = false,
    labelCancelText = 'Cancelar',
    labelSubmitText = 'Aceptar'
  } = props;

  const footerContent = () => {
    return (
      <div className="w-full h-full flex pb-4 justify-center">
        <ButtonComponent
          label={labelCancelText}
          onClick={() => onClosePopUp()}
          className="p-button-text !bg-deleteButton !text-white"
        />
        {renderSubmitButton()}
      </div>
    );
  };

  const renderSubmitButton = () => {
    if (!footerOnlyCancel) {
      return (
        <ButtonComponent
          label={labelSubmitText}
          onClick={onSubmit}
          disbaled={disabledOnSubmitButton}
        />
      );
    }
    return null;
  };

  return (
    <div className="card flex justify-content-center">
      <Dialog
        header={header}
        visible={showPopUp}
        style={{ width: '50vw' }}
        onHide={onClosePopUp}
        footer={footer ? footerContent() : ''}>
        {body}
      </Dialog>
    </div>
  );
};

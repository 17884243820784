import { ICatalogProvider } from '../../../interfaces/service/CatalogServiceManagerInterface';

interface ICatalogListProps {
  serviceCatalog: ICatalogProvider;
}

export const CatalogsList = (props: ICatalogListProps) => {
  const { serviceCatalog } = props;

  const renderMenu = () => {
    return (
      <div className="h-full w-full flex flex-col">
        {serviceCatalog.catalogList.map((item: any, index: number) => {
          return (
            <div
              className={`flex flex-col mt-5 justify-center text-center cursor-pointer text-grey  hover:!text-official_green ${item.name === serviceCatalog.selectCatalog ? 'text-official_green' : ''
                }`}
              key={index}
              onClick={() => serviceCatalog.catalogSelect(item)}>
              <div className="flex justify-center text-4xl ">{item.icon}</div>
              <div className="flex justify-center">{item.name}</div>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className="flex h-full w-full px-12 overflow-y-auto absolute">
      {renderMenu()}
    </div>
  );
};

import { Column } from 'primereact/column';
import { useEffect, useState } from 'react';
import { LuFileEdit, LuTrash2 } from 'react-icons/lu';

import { ButtonComponent } from '../../../components/ButtonComponen';
import { ListBoxComponent } from '../../../components/ListBoxComponent';
import { TableComponent } from '../../../components/Table';
import { useRoles } from '../../../hooks/usersManager/useRoles';
import { IRoles, IRolesTableProps } from '../../../interfaces/interfaceRole';
import { PermitsList } from '../../../permitsList';
import { validatePermissions } from '../../../utils/utils';
import { DialogPopUpComponent } from './../../../components/DialogPopUpComponent';
import { CheckboxComponent } from '../../../components/CheckboxComponent';

export const RolesTable = (props: IRolesTableProps) => {
  const { setOpenRightMenu, setDataRow, selectMenu, setUpdateTable } = props;

  //States Zone
  const [rolList, setRolLista] = useState<IRoles[]>([]);
  const [rolSelected, setRolSelected] = useState<IRoles>();
  const [sort, setSort] = useState([{ field: '', order: -1 }]);
  const [expandedRows, setExpandedRows] = useState(null);
  //const [menuTitle, setMenuTitle] = useState<string>('');
  let menuTitle = '';

  const [showDeleteModalUser, setShowDeleteModalUser] =
    useState<boolean>(false);

  let usersCopy: IRoles[];

  //Hooks Zone
  const { getRoles } = useRoles();
  const { deleteRoles } = useRoles();

  //Table Zone

  useEffect(() => {
    const getData = async () => {
      let response = await getRoles();
      if (response.status === 200) {
        setRolLista(response.data);
        usersCopy = response.data;
      }
    };

    getData();
  }, []);

  /**
   *Actualiza la tabla sin necesidad de hacer una peticion a BD
   * @param elementUpdated El objeto que se va a agregar a la tabla
   */
  const updateTable = (elementUpdated: any) => {
    let copyUserList: any = rolList;

    if (menuTitle === 'Actualizar') {
      if (elementUpdated !== null && elementUpdated !== undefined) {
        let users = usersCopy.map((item: any) => {
          if (item.userId === elementUpdated.userId) {
            return elementUpdated;
          }
          return item;
        });
        setRolLista(users);
      }
    } else {
      copyUserList.push(elementUpdated);
      setRolLista(copyUserList);
    }

    setOpenRightMenu(false);
    selectMenu('');
  };

  function openRightMenu(
    row: IRoles = { features: [] },
    title: string = 'Agregar'
  ) {
    menuTitle = title;
    setDataRow(row);
    setOpenRightMenu(true);
    selectMenu(menuTitle);
    setUpdateTable(() => updateTable);
  }

  const openDeleteModalUser = (user: IRoles) => {
    setShowDeleteModalUser(true);
    setRolSelected(user);
  };

  /**
   * Regresa la columa de acciones, icono de edicion y eliminacion de cada item
   * @param row El resgistro que se selecciono en la tabla
   */
  const actionsRow = (row: IRoles) => {
    return (
      <div className="flex w-full justify-around text-xl">
        {validatePermissions(PermitsList.SEC_ROLE_EDIT) ? (
          <LuFileEdit
            className="cursor-pointer text-official_green"
            onClick={() => openRightMenu(row, 'Actualizar')}
          />
        ) : (
          <></>
        )}
        {validatePermissions(PermitsList.SEC_ROLE_DELETE) ? (
          <LuTrash2
            className="cursor-pointer text-red"
            onClick={() => openDeleteModalUser(row)}
          />
        ) : (
          <></>
        )}
      </div>
    );
  };

  const permissionsRow = (row: IRoles) => {
    return (
      <div className="flex w-full text-xl">
        {row !== undefined ? row.features.length : 0}
      </div>
    );
  };

  let columnList = [
    {
      field: 'roleName',
      header: 'Nombre',
      sortable: true,
      style: { width: '70%' },
    },
    {
      field: 'features',
      header: 'Cantidad de permisos',
      body: permissionsRow,
      style: { width: '20%' },
    },
    {
      field: 'permisos',
      header: 'Listado de permisos',
      expander: true,
      style: { width: '20px' },
    },
    /*  { field: 'acciones', header: 'Acciones', body: actionsRow }, */
  ];

  const [columns] = useState<any>(columnList);

  const deleteRol = async () => {
    if (rolSelected !== undefined) {
      let response = await deleteRoles(rolSelected['roleId'] || '');
      if (response.status === 204) {
        let copyUserList: any = rolList;
        let users = copyUserList.filter(
          (item: IRoles) => item.roleId !== rolSelected.roleId
        );
        setRolLista(users);
      }
    }
    setShowDeleteModalUser(false);
  };

  const listTemplate = (permission: any) => {
    return (
      <div className="flex justify-between">
        <span>{permission}</span>
        <CheckboxComponent checked={true} disabled={true} />
      </div>
    );
  };

  const rowExpansionTemplate = (data: any) => {
    let permissions = data.features.map((item: any) => item.name);

    return (
      <div className="w-[40%]">
        <ListBoxComponent options={permissions} itemTemplate={listTemplate} />
      </div>
    );
  };

  const bodyModalContent = () => {
    let name =
      rolSelected !== undefined && rolSelected['name']
        ? rolSelected['name']
        : '';
    return (
      <div className="w-full h-full flex pb-4 justify-center">
        ¿Deseas eliminar el {name} seleccionado?
      </div>
    );
  };

  const footerModalContent = () => {
    return (
      <div className="w-full h-full flex pb-4 justify-center">
        <ButtonComponent
          label="Cancelar"
          onClick={() => setShowDeleteModalUser(false)}
          className="p-button-text !bg-deleteButton !text-white"
        />
        <ButtonComponent label="Aceptar" onClick={() => deleteRol()} />
      </div>
    );
  };

  function closeModal() {
    setShowDeleteModalUser(false);
  }

  return (
    <>
      {/*       <div className="h-auto pb-3 w-full flex flex-row justify-end align-middle relative">
        <ButtonComponent
          label="Agregar rol"
          onClick={openRightMenu}
          className={'h-12'}
        />
      </div> */}
      <div className="h-full w-full flex flex-row relative">
        <TableComponent
          value={rolList}
          sortField="name"
          responsiveLayout="scroll"
          globalFilterFields={[]}
          resizableColumns
          setSort={setSort}
          sort={sort}
          onRowToggle={(e: any) => setExpandedRows(e.data)}
          expandedRows={expandedRows}
          rowExpansionTemplate={rowExpansionTemplate}>
          {columns.map((column: any, index: any) => {
            const key = index.toString();
            return (
              <Column
                key={key}
                field={column.field}
                header={column.header}
                bodyClassName={'!text-black'}
                style={
                  column.style
                    ? column.style
                    : {
                        flexGrow: 1,
                        flexBasis: '100px',
                      }
                }
                headerClassName="  !bg-tableHeader !text-withe!justify-between "
                sortable={column.sortable}
                filterMenuStyle={{ width: '14rem' }}
                filterElement={column.filterElement}
                filter={column.filter}
                body={column.body}
                expander={column.expander}
              />
            );
          })}
        </TableComponent>
      </div>

      {showDeleteModalUser ? (
        <DialogPopUpComponent
          show={true}
          header={'Eliminar rol'}
          body={bodyModalContent()}
          footer={footerModalContent()}
          close={closeModal}
        />
      ) : (
        false
      )}
    </>
  );
};

import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { BiLogOut } from 'react-icons/bi';

import { IUserMenu } from '@/interfaces/interfaceUserMenu';
import userIcon from '../../../assets/images/user-header.png';

export const UserMenu = (props: IUserMenu) => {
  const { refCloseProfileCard, showPopover, setShowPopover, serviceHeader } =
    props;

  const IconProfile = () => {
    return (
      <div
        onClick={() => setShowPopover(!showPopover)}
        className="flex w-full h-full flex-row text-secondary-contrast-color-text  rounded-md hover:cursor-pointer ">
        <div className="flex h-full flex-row w-full lg:content-center xl:content-center 2xl:content-center lg:items-center xl:items-center 2xl:items-center lg:justify-center xl:justify-center 2xl:justify-center">
          <div className="flex items-center flex-shrink-0">
            <img src={userIcon} alt="Usuario" className="h-8 mr-2" />
          </div>
          <div className="flex justify-center items-center content-center">
            <ChevronDownIcon className=" h-6 w-6 stroke-slate-600 dark:stroke-[#839AE6]" />
          </div>
        </div>
      </div>
    );
  };

  const RenderMenuOptions = () => {
    return (
      <ul className="flex w-64 flex-col pt-0 absolute top-[61px] divide-y right-1 rounded-b-lg bg-white drop-shadow-2xl p-2 ">
        <div className="flex flex-col w-full break-all py-3">
          <div className="flex justify-between text-official_green cursor-pointer">
            <img src={userIcon} alt="Usuario" className="h-8 mr-2" />

            <BiLogOut
              className="h-7 w-7"
              onClick={() => serviceHeader.logout()}
            />
          </div>
          <div className={`text-xs font-bold  py-1 truncate h-8`}>
            {serviceHeader.user?.userName} {serviceHeader.user?.firstName}{' '}
            {serviceHeader.user?.lastName}
          </div>
          <p className={` text-xs ml-2`}>{serviceHeader.user?.email}</p>
          <p className={` text-xs ml-2`}>{serviceHeader.user?.phone}</p>
          <p className={` text-xs ml-2`}>{serviceHeader.user?.roleName}</p>
        </div>
        <li
          className={` cursor-pointer h-10 justify-start content-center items-center `}
          onClick={() => serviceHeader.openProfileModal()}>
          Mi cuenta
        </li>
        <li
          className={` cursor-pointer h-10 justify-start content-center items-center `}>
          Aviso de privacidad
        </li>
        <li
          className={` cursor-pointer h-10 justify-start content-center items-center `}>
          Términos y condiciones
        </li>
      </ul>
    );
  };

  return (
    <div ref={refCloseProfileCard} className=" flex flex-col w-full relative">
      {IconProfile()}
      {showPopover ? RenderMenuOptions() : null}
    </div>
  );
};

import {
  GET_SAM_COUNTER_CATALOG,
  GET_SAM_COUNTER_CATALOG_ERROR,
  GET_SAM_COUNTER_CATALOG_SUCCESS,
  PATCH_SAM_COUNTER_CATALOG,
  PATCH_SAM_COUNTER_CATALOG_SUCCESS,
  PATCH_SAM_COUNTER_CATALOG_ERROR,
} from '../../../constants/catalog';

export const getSamCounterCatalog = (state: any = false, actions: any) => {
  switch (actions.type) {
    case GET_SAM_COUNTER_CATALOG:
      return {
        ...state,
        loading: true,
      };
    case GET_SAM_COUNTER_CATALOG_ERROR:
      return {
        data: null,
        loading: false,
        error: null,
      };
    case GET_SAM_COUNTER_CATALOG_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: actions.payload,
      };
    default:
      return state;
  }
};

export const patchSamCounterCatalog = (state: any = false, actions: any) => {
  switch (actions.type) {
    case PATCH_SAM_COUNTER_CATALOG:
      return {
        ...state,
        loading: true,
      }
    case PATCH_SAM_COUNTER_CATALOG_ERROR:
      return {
        data: null,
        loading: false,
        error: null
      }
    case PATCH_SAM_COUNTER_CATALOG_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: actions.payload,
      }
    default:
      return state;
  }
}

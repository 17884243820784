import { 
    GET_CONFIGURATION_TRIGGER, 
    GET_CONFIGURATION_TRIGGER_SUCCESS,
    GET_CONFIGURATION_TRIGGER_ERROR,
    POST_CONFIGURATION_TRIGGER,
    POST_CONFIGURATION_TRIGGER_SUCCESS,
    POST_CONFIGURATION_TRIGGER_ERROR 
} from "../../../constants/constantsRed";

export const configurationTrigger_Red = (state: any, actions: any) => {
  switch (actions.type) {
    case GET_CONFIGURATION_TRIGGER:
      return {
        ...state,
        loading: true,
      };
    case GET_CONFIGURATION_TRIGGER_ERROR:
      return {
        data: null,
        loading: false,
        error: null,
      };
    case GET_CONFIGURATION_TRIGGER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: actions.payload,
      };
      case POST_CONFIGURATION_TRIGGER:
      return {
        ...state,
        loading: true,
      };
    case POST_CONFIGURATION_TRIGGER_ERROR:
      return {
        data: null,
        loading: false,
        error: null,
      };
    case POST_CONFIGURATION_TRIGGER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: actions.payload,
      };
    default:
      return state;
  }
};

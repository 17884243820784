import { useContext } from 'react';

import { ToastComponent } from '../../components/ToastComponent';
import {
  GET_ECONOMICNUMBERS_CATALOG,
  GET_ECONOMICNUMBERS_CATALOG_SUCCESS,
  GET_ECONOMICNUMBERS_CATALOG_ERROR,
  POST_ECONOMICNUMBERS_CATALOG,
  POST_ECONOMICNUMBERS_CATALOG_SUCCESS,
  POST_ECONOMICNUMBERS_CATALOG_ERROR,
  PUT_ECONOMICNUMBERS_CATALOG,
  PUT_ECONOMICNUMBERS_CATALOG_SUCCESS,
  PUT_ECONOMICNUMBERS_CATALOG_ERROR,
  DELETE_ECONOMICNUMBERS_CATALOG,
  DELETE_ECONOMICNUMBERS_CATALOG_SUCCESS,
  DELETE_ECONOMICNUMBERS_CATALOG_ERROR,
  LOADING_ON,
  LOADING_OFF
} from '../../constants/constantsRed';
import { StoreContext } from '../../context/Provider';
import useApi from '../../services/request.service';

export const useEconomicNumbersCatalogs = () => {
  const {
    getEconomicNumbersCatalogState,
    postEconomicNumbersCatalogState,
    putEconomicNumbersCatalogState,
    deleteEconomicNumbersCatalogState,
  } = useContext(StoreContext);
  const { genericApiConfiguration } = useApi();

  const getEconomicNumbersCatalog = async () => {
    getEconomicNumbersCatalogState.dispatch({ type: GET_ECONOMICNUMBERS_CATALOG });
    try {
      const result: any = await genericApiConfiguration(
        '/economic-numbers',
        'GET',
        {},
        {},
        {}
      );
      if (result.status === 200) {
        const payload = result.data.message;
        getEconomicNumbersCatalogState.dispatch({
          type: GET_ECONOMICNUMBERS_CATALOG_SUCCESS,
          payload: payload,
        });
      }
      return result;
    } catch (error: any) {
      ToastComponent(
        'warning',
        'Error',
        'La operación falló, inténtelo más tarde por favor.'
      );
      getEconomicNumbersCatalogState.dispatch({
        type: GET_ECONOMICNUMBERS_CATALOG_ERROR,
        payload: error.message,
      });
      return 'error';
    }
  };

  const postEconomicNumbersCatalog = async (data: any) => {
    // dispatchLoading({ type: LOADING_ON });
    postEconomicNumbersCatalogState.dispatch({
      type: POST_ECONOMICNUMBERS_CATALOG,
    });
    try {
      const result: any = await genericApiConfiguration(
        '/economic-numbers',
        'POST',
        data,
        {},
        {}
      );
      if (result.status === 200) {
        ToastComponent('success', '', 'Se ha añadido el registro correctamente');
        const payload = result.data.payload;
        postEconomicNumbersCatalogState.dispatch({
          type: POST_ECONOMICNUMBERS_CATALOG_SUCCESS,
          payload: payload,
        });
      }
      return result;
    } catch (error: any) {
      postEconomicNumbersCatalogState.dispatch({
        type: POST_ECONOMICNUMBERS_CATALOG_ERROR,
        payload: error.message,
      });
      return 'error';
    }
  };

  const putEconomicNumbersCatalog = async (data: any) => {
    putEconomicNumbersCatalogState.dispatch({
      type: PUT_ECONOMICNUMBERS_CATALOG,
    });
    try {
      const result: any = await genericApiConfiguration(
        '/economic-numbers',
        'PUT',
        data,
        {},
        {}
      );
      if (result.status === 200) {
        ToastComponent('success', '', 'Se ha editado el registro correctamente');
        const payload = result.data.payload;
        putEconomicNumbersCatalogState.dispatch({
          type: PUT_ECONOMICNUMBERS_CATALOG_SUCCESS,
          payload: payload,
        });
      }
      return result;
    } catch (error: any) {
      putEconomicNumbersCatalogState.dispatch({
        type: PUT_ECONOMICNUMBERS_CATALOG_ERROR,
        payload: error.message,
      });
      return 'error';
    }
  };

  const deleteEconomicNumbersCatalog = async (id: string) => {
    deleteEconomicNumbersCatalogState.dispatch({
      type: DELETE_ECONOMICNUMBERS_CATALOG,
    });
    try {
      const result: any = await genericApiConfiguration(
        '/economic-numbers/' + id,
        'DELETE',
        {},
        {},
        {}
      );

      if (result.status === 200) {
        ToastComponent(
          'success',
          'Succes',
          'Se eliminó correctamente el registro.'
        );
        const payload = result.data.payload;
        deleteEconomicNumbersCatalogState.dispatch({
          type: DELETE_ECONOMICNUMBERS_CATALOG_SUCCESS,
          payload: payload,
        });
      }
      return result;
    } catch (error: any) {
      deleteEconomicNumbersCatalogState.dispatch({
        type: DELETE_ECONOMICNUMBERS_CATALOG_ERROR,
        payload: error.message,
      });
      return 'error';
    }
  };

  return {
    getEconomicNumbersCatalog,
    postEconomicNumbersCatalog,
    putEconomicNumbersCatalog,
    deleteEconomicNumbersCatalog,
  };
};

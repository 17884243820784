import React, { useState } from 'react';
import { Calendar } from 'primereact/calendar';
import { addLocale } from 'primereact/api';

type TextInputProps = {
  label?: string | null;
  name?: string;
  value?: any;
  className?: string;
  placeholder?: string;
  onChange?: any;
  classSpan?: string;
  required?: boolean;
  disabled?: boolean
};

export const CalendarComponent = (props: TextInputProps) => {
  const {
    label,
    name,
    value,
    className,
    onChange,
    placeholder,
    required = false,
    disabled,
  } = props;

  let css = 'p-inputtext-sm' + className;

  addLocale('es', {
    firstDayOfWeek: 1,
    dayNames: [
      'domingo',
      'lunes',
      'martes',
      'miércoles',
      'jueves',
      'viernes',
      'sábado',
    ],
    dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
    dayNamesMin: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
    monthNames: [
      'Enero',
      'Febrero',
      'Marzo',
      'Abril',
      'Mayo',
      'Junio',
      'Julio',
      'Agosto',
      'Septiembre',
      'Octubre',
      'Noviembre',
      'Diciembre',
    ],
    monthNamesShort: [
      'ene',
      'feb',
      'mar',
      'abr',
      'may',
      'jun',
      'jul',
      'ago',
      'sep',
      'oct',
      'nov',
      'dic',
    ],
    today: 'Hoy',
    clear: 'Limpiar',
  });

  return (
    <div className="flex justify-content-center w-full flex-col">
        <p className="font-medium text-md">{label}</p>
      <Calendar
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        className={css}
        disabled={disabled}
        dateFormat="dd/mm/yy"
        locale="es"
      />
    </div>
  );
};

import {
  DELETE_ROL,
  DELETE_ROL_SUCCESS,
  DELETE_ROL_ERROR,
} from '../../../constants/constantsRed';

export const deleteRoleRed = (state: any = false, actions: any) => {
  switch (actions.type) {
    case DELETE_ROL:
      return {
        ...state,
        loading: true,
      };
    case DELETE_ROL_ERROR:
      return {
        data: null,
        loading: false,
        error: null,
      };
    case DELETE_ROL_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: actions.payload,
      };
    default:
      return state;
  }
};

import { ProgressSpinner } from 'primereact/progressspinner';
import { authService } from '../../authentication/authService';

export const RedirectComponent = () => {
  setTimeout(() => {
    authService.logout();
  }, 4000);

  return (
    <div className="w-full h-full flex flex-col justify-center items-center">
      <span className="text-xl mb-4">
        Usuario sin permisos. Seras dirigido al login.
      </span>
      <ProgressSpinner
        style={{ width: '50px', height: '50px' }}
        className="flex w-[50px] h-[50px]"
        strokeWidth="8"
        fill="var(--surface-ground)"
        animationDuration=".5s"
      />
    </div>
  );
};

import {
    PUT_RECHARGECOUNTER,
    PUT_RECHARGECOUNTER_ERROR,
    PUT_RECHARGECOUNTER_SUCCESS,
  } from '../../../constants/constantsRed';
  
  export const putRechargeCounterRed = (state: any = false, actions: any) => {
    switch (actions.type) {
      case PUT_RECHARGECOUNTER:
        return {
          ...state,
          loading: true,
        };
      case PUT_RECHARGECOUNTER_ERROR:
        return {
          data: null,
          loading: false,
          error: null,
        };
      case PUT_RECHARGECOUNTER_SUCCESS:
        return {
          ...state,
          loading: false,
          error: null,
          data: actions.payload,
        };
      default:
        return state;
    }
  };
  
import { useContext } from 'react';

import { ToastComponent } from '../../components/ToastComponent';
import {
  GET_ROUTES_CATALOG,
  GET_ROUTES_CATALOG_SUCCESS,
  GET_ROUTES_CATALOG_ERROR,
  POST_ROUTES_CATALOG,
  POST_ROUTES_CATALOG_SUCCESS,
  POST_ROUTES_CATALOG_ERROR,
  PUT_ROUTES_CATALOG,
  PUT_ROUTES_CATALOG_SUCCESS,
  PUT_ROUTES_CATALOG_ERROR,
  DELETE_ROUTES_CATALOG,
  DELETE_ROUTES_CATALOG_SUCCESS,
  DELETE_ROUTES_CATALOG_ERROR,
} from '../../constants/constantsRed';
import { StoreContext } from '../../context/Provider';
import useApi from '../../services/request.service';

export const useRoutesCatalogs = () => {
  const {
    getRoutesCatalogState,
    postRoutesCatalogState,
    putRoutesCatalogState,
    deleteRoutesCatalogState
  } = useContext(StoreContext);
  const { genericApiConfiguration } = useApi();

  const getRoutesCatalog = async () => {
    getRoutesCatalogState.dispatch({ type: GET_ROUTES_CATALOG });
    try {
      const result: any = await genericApiConfiguration(
        '/routes',
        'GET',
        {},
        {},
        {}
      );
      if (result.status === 200) {
        const payload = result.data.message;
        getRoutesCatalogState.dispatch({
          type: GET_ROUTES_CATALOG_SUCCESS,
          payload: payload,
        });
      }
      return result;
    } catch (error: any) {
      ToastComponent(
        'warning',
        'Error',
        'La operación falló, inténtelo más tarde por favor.'
      );
      getRoutesCatalogState.dispatch({
        type: GET_ROUTES_CATALOG_ERROR,
        payload: error.message,
      });
      return 'error';
    }
  };

  const postRoutesCatalog = async (data: any) => {
    postRoutesCatalogState.dispatch({ type: POST_ROUTES_CATALOG });
    try {
      const result: any = await genericApiConfiguration(
        '/routes',
        'POST',
        data,
        {},
        {}
      );
      if (result.status === 200) {
        ToastComponent('success', '', 'Se ha añadido el registro correctamente');
        const payload = result.data.payload;
        postRoutesCatalogState.dispatch({
          type: POST_ROUTES_CATALOG_SUCCESS,
          payload: payload,
        });
      }
      return result;
    } catch (error: any) {
      postRoutesCatalogState.dispatch({
        type: POST_ROUTES_CATALOG_ERROR,
        payload: error.message,
      });
      return 'error';
    }
  };

  const putRoutesCatalog = async (data: any) => {
    putRoutesCatalogState.dispatch({ type: PUT_ROUTES_CATALOG });
    try {
      const result: any = await genericApiConfiguration(
        '/routes',
        'PUT',
        data,
        {},
        {}
      );
      if (result.status === 200) {
        ToastComponent('success', '', 'Se ha editado el registro correctamente');
        const payload = result.data.payload;
        putRoutesCatalogState.dispatch({
          type: PUT_ROUTES_CATALOG_SUCCESS,
          payload: payload,
        });
      }
      return result;
    } catch (error: any) {
      putRoutesCatalogState.dispatch({
        type: PUT_ROUTES_CATALOG_ERROR,
        payload: error.message,
      });
      return 'error';
    }
  };

  const deleteRoutesCatalog = async (id: string) => {
    deleteRoutesCatalogState.dispatch({ type: DELETE_ROUTES_CATALOG });
    try {
      const result: any = await genericApiConfiguration(
        '/routes/{id}',
        'DELETE',
        {},
        { ids: id },
        {}
      );

      if (result.status === 200) {
        ToastComponent(
          'success',
          'Succes',
          'Se eliminó correctamente el registro.'
        );
        const payload = result.data.payload;
        deleteRoutesCatalogState.dispatch({
          type: DELETE_ROUTES_CATALOG_SUCCESS,
          payload: payload,
        });
      }
      return result;
    } catch (error: any) {
      deleteRoutesCatalogState.dispatch({
        type: DELETE_ROUTES_CATALOG_ERROR,
        payload: error.message,
      });
      return 'error';
    }
  };

  return {
    getRoutesCatalog,
    postRoutesCatalog,
    putRoutesCatalog,
    deleteRoutesCatalog,
  };
};

import {
  IEconomicNumber,
  IServiceMenuComponent,
} from '@/interfaces/interfaceCatalogs';

import { InputSwitchChangeEvent } from 'primereact/inputswitch';
import { classNames } from 'primereact/utils';

import { ButtonComponent } from '../../../../components/ButtonComponen';
import { InputComponent } from '../../../../components/InputComponent';
import { SwitchComponent } from '../../../../components/SwitchComponent';
import { economicNumberValidations } from './validations_form';
import { DropdownComponent } from '../../../../components/DropdownComponen';
import useCustomFormik from './utils/formik_catalog_Config';

export const EconomicNumberForm = (props: IServiceMenuComponent) => {
  const { serviceCatalog } = props;

  const renderMenu = (): any => {
    return (
      <form
        className="h-full w-full flex flex-col pt-5 px-6"
        onSubmit={serviceCatalog.formikEconomicNumber.handleSubmit}>
        {/*         <div className="flex flex-col">
          <InputNumberComponent
            label={'Id de número economico'}
            name={'economicNumberId'}
            onValueChange={serviceCatalog.formikEconomicNumber.handleChange}
            value={serviceCatalog.formikEconomicNumber?.values?.['economicNumberId']}
            placeholder={'Id de número economico'}
            className={classNames({
              'p-invalid': isFormFieldValid('economicNumberId'),
            })}
          />
          {getFormErrorMessage('economicNumberId')}
        </div> */}

        <div className="flex flex-col">
          <InputComponent
            label={'Número de unidad'}
            type={'text'}
            name={'economicNumber'}
            onChange={serviceCatalog.formikEconomicNumber.handleChange}
            value={serviceCatalog.formikEconomicNumber?.values?.['economicNumber']}
            placeholder={'Número de unidad'}
            className={classNames({
              'p-invalid': serviceCatalog.isFormFieldValid('economicNumber', serviceCatalog.formikEconomicNumber.errors, serviceCatalog.formikEconomicNumber.touched),
            })}
          />
          {serviceCatalog.getFormErrorMessage('economicNumber', serviceCatalog.formikEconomicNumber.errors, serviceCatalog.formikEconomicNumber.touched)}
        </div>

        <div className="flex flex-col">
          <DropdownComponent
            label={'Ruta'}
            options={serviceCatalog.routes}
            optionLabel={'name'}
            name={'routeId'}
            optionValue={'routeId'}
            onChange={serviceCatalog.formikEconomicNumber.handleChange}
            value={serviceCatalog.formikEconomicNumber?.values?.['routeId']}
            filter={true}
          />
          {serviceCatalog.getFormErrorMessage('routeId', serviceCatalog.formikEconomicNumber.errors, serviceCatalog.formikEconomicNumber.touched)}

        </div>

        <div className="flex flex-col">
          <SwitchComponent
            checked={serviceCatalog.formikEconomicNumber?.values?.['status'] || 'false'}
            onChange={(e: InputSwitchChangeEvent) => {
              serviceCatalog.formikEconomicNumber.setFieldValue('status', e.value);
            }}
            label={'Estatus'}
            className={classNames({
              'p-invalid': serviceCatalog.isFormFieldValid('status', serviceCatalog.formikEconomicNumber.errors, serviceCatalog.formikEconomicNumber.touched),
            })}
          />
          {serviceCatalog.getFormErrorMessage('status', serviceCatalog.formikEconomicNumber.errors, serviceCatalog.formikEconomicNumber.touched)}

        </div>

        <ButtonComponent className="mt-10" label={serviceCatalog.buttonLabel} type="submit" />
      </form>
    );
  };

  return (
    <div className="flex w-full h-full flex-col items-center bg-white  shadow-[-6px_-1px_5px_#00000024] z-10 right-0">
      <div className="flex w-full h-full overflow-y-auto">{renderMenu()}</div>
    </div>
  );
};

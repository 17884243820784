import { MultiSelectChangeEvent } from 'primereact/multiselect';
import {  useEffect, useState } from 'react';

import { ButtonComponent } from '../../../components/ButtonComponen';
import { CalendarComponent } from '../../../components/CalendarComponent';
import { MultiSelectComponent } from '../../../components/MultiSelectComponent';
import { useUserManager } from '../../../hooks/usersManager/useUserManager';
import { useAccount, useMsal } from '@azure/msal-react';
import { useAuthService } from '../../../authentication/authService';
import { RiFileExcel2Line } from 'react-icons/ri';

interface IFiltersComponent {
  setFilterGlobal: any;
  setLoader: any
  getUserHistory : any,
  setHistories: any
  dispatchLoading: any
  exportUserHistory: any
}

export const Filters = (props: IFiltersComponent) => {
  const {
    getUserHistory,
    exportUserHistory
  } = props;

  const { getUserManager } = useUserManager(); 
  const { instance } = useMsal();
  const { getAccessToken } = useAuthService();
  const account = useAccount();

  const [filterQuery, setFilterQuery] = useState<string>("");

  const [users, setUsers] = useState<any[]>([]);
  const [usersSelected, setUserSelected] = useState<any[]>([]);
  
  const [app] = useState<any[]>([
    {id: 1, name:'App Móvil'},
    {id: 2, name:'Aplicación de escritorio'},
    {id: 3, name:'Portal Web'},
  ]);
  const [appSeleted, setAppSelected] = useState<any[]>([]);

  const [fromDate, setFromDate] = useState<any>();
  const [toDate, setToDate] = useState<any>();
  const [fromDateInput, setFromDateInput] = useState<any>();
  const [toDateInput, setToDateInput] = useState<any>();

  //hooks

  const getUsers = async () => {
    let response = await getUserManager();
    if (response.status === 200) {
        setUsers(response.data);
    }
  };


  useEffect(() => {
    getUsers();
  }, []);

  /**
   * Mientras se pasa a BD el filtro que seleccionaro, se guarda todo el array en localStorage
   * @param e MultiSelectChangeEvent
   */


  function selectApp(e: MultiSelectChangeEvent) {
    setAppSelected(e.value);
  }

  
  function selectUsers(e: MultiSelectChangeEvent) {
    setUserSelected(e.value);
  }


  const renderSelectUsers = () => {
    return (
      <div className="w-40">
        <MultiSelectComponent
          options={users}
          optionLabel={'name'}
          placeholder="Usuarios"
          label='Usuarios:'
          value={usersSelected}
          className=" h-12"
          onChange={(e: MultiSelectChangeEvent) => selectUsers(e)}
          max={2}
          selectedItemsLabel="Seleccionado"
        />
      </div>
    );
  };

  const renderSelectApp = () => {
    return (
      <div className="w-40">
        <MultiSelectComponent
          options={app}
          optionLabel={'name'}
          label='App:'
          placeholder="App"
          value={appSeleted}
          className=" h-12"
          onChange={(e: MultiSelectChangeEvent) => selectApp(e)}
          max={0}
          selectedItemsLabel="Seleccionado"
        />
      </div>
    );
  };

  const handlerFilters = (queryRequest: string) => {
    let query = `?${queryRequest}`;
    getUserHistory(query)
  };

  const sendData = () => {
    //Debido a que se repiten las cabeceras de los qwery params, no podemos usar un Json para pasarlos al axios, lo vamos a implementar directo en la url;
    let queryRequest = '';

      usersSelected.forEach(element => {
        queryRequest = `${queryRequest}&userId=${element.userId}`;
      });

      appSeleted.forEach(element => {
        queryRequest = `${queryRequest}&app=${element.id}`;
      });

    if (fromDate !== '' && fromDate !==null && fromDate !== undefined) {
        queryRequest = `${queryRequest}&fromDate=${fromDate}`;
    }
    
    if (toDate !== '' && toDate !==null && toDate !== undefined) {
    queryRequest = `${queryRequest}&toDate=${toDate}`;
    }

    handlerFilters(queryRequest);
    setFilterQuery("?"+queryRequest)
  };

  const renderButtonSendData = () => {
    return (
      <div className="w-auto h-12 mt-auto flex flex-row space-x-3">
     
        <ButtonComponent
          label="Enviar"
          onClick={sendData}
          className={'h-full'}
        />
           { <ButtonComponent
          label="Limpiar filtro"
          onClick={()=>{
            setFromDate(undefined)
            setFromDateInput(undefined)
            setToDate(undefined)
            setToDateInput(undefined)
            setAppSelected([])
            setUserSelected([])
            setFilterQuery("")
          }}
          className={'h-full w-40'}
        />
        }
      </div>
    );
  };

  
  const renderBtnExcel = () =>{
    return(
      <div className="w-auto h-12 mt-auto flex flex-row text-[50px] text-alternative_green content-center items-center cursor-pointer">
      <RiFileExcel2Line
        onClick={async()=>{
          await exportUserHistory(filterQuery, await getAccessToken(instance, account))
        }}
       title="Exportar a Excel"
     />
   </div>
    )
  }


  const renderFromDate= () => {
    return (
      <div className="w-40">
        <CalendarComponent
            label={'Fecha inicio: '}
            name={'fromDate'}
            onChange={(e: any) =>
            {
              setFromDateInput(e.value)
              if(e.value !== undefined && e.value !== null)
              {
                var dateFrom = new Date(e.value)
                const day = String(dateFrom.getDate()).padStart(2, '0');
                const month = String(dateFrom.getMonth() + 1).padStart(2, '0'); // Los meses empiezan desde 0
                const year = dateFrom.getFullYear();
                
                const formattedDate = `${year}-${month}-${day}`;
                setFromDate(formattedDate)
              }else{
                setFromDate(undefined)
              }
            }
            }
            value={fromDateInput}
            placeholder="Fecha inicio"
            className=' h-12'
        />
      </div>
    );
  };

  
  const renderToDate= () => {
    return (
      <div className="w-40">
        <CalendarComponent
            label={'Fecha fin:'}
            name={'toDate'}
            onChange={(e: any) =>
                {
                    setToDateInput(e.value)
                    if(e.value !== undefined && e.value !== null)
                    {
                    var dateFrom = new Date(e.value)
                    const day = String(dateFrom.getDate()).padStart(2, '0');
                    const month = String(dateFrom.getMonth() + 1).padStart(2, '0'); // Los meses empiezan desde 0
                    const year = dateFrom.getFullYear();
                    
                    const formattedDate = `${year}-${month}-${day}`;
                    setToDate(formattedDate)
                    }else{
                        setToDate(undefined)
                    }
                }
            }
            value={toDateInput}
            placeholder="Fecha inicio"
            className=' h-12'
        />
      </div>
    );
  };


  return (
    <>
      <div className="w-full h-auto flex justify-between pb-3">
        <div className="flex gap-2">
          {renderFromDate()}
          {renderToDate()}
          {renderSelectApp()}
          {renderSelectUsers()}
          {renderButtonSendData()}
        </div>
        {renderBtnExcel()}
      </div>
    </>
  );
};

import { MultiSelectChangeEvent } from 'primereact/multiselect';
import { useEffect, useState } from 'react';

import { ButtonComponent } from '../../../components/ButtonComponen';
import { InputComponent } from '../../../components/InputComponent';
import { CalendarComponent } from '../../../components/CalendarComponent';
import { MultiSelectComponent } from '../../../components/MultiSelectComponent';
import useApi from '../../../services/request.service';
import { useUserManager } from '../../../hooks/usersManager/useUserManager';
import { LOADING_OFF, LOADING_ON } from "../../../constants/constantsRed";
import { RiFileExcel2Line } from 'react-icons/ri';

interface IFiltersComponent {
  setFilterSamId: any;
  setLoader: any
  genericApiCallApiBilling: any,
  setHistories: any
  dispatchLoading: any
}

export const Filters = (props: IFiltersComponent) => {
  const {
    setFilterSamId,
    setLoader,
    genericApiCallApiBilling,
    setHistories,
    dispatchLoading
  } = props;

  const { dowloadExcelHistorySam } = useApi();
  const { getUserManager } = useUserManager();

  const [filterQuery, setFilterQuery] = useState<string>("");
  const [deviceList, setDeviceList] = useState<any[]>([]);
  const [devicesSelected, setDevicesSelected] = useState<any[]>([]);

  const [operatorsList, setOperatorsList] = useState<any[]>([]);
  const [operatorsSelected, setOperatorsSelected] = useState<any[]>([]);

  const [users, setUsers] = useState<any[]>([]);
  const [usersSelected, setUserSelected] = useState<any[]>([]);

  const [projectsList, setProjectsList] = useState<any[]>([]);
  const [projectsSelected, setProjectsSelected] = useState<any[]>([]);

  const [transportCompany, setTransportCompany] = useState<any[]>([]);
  const [transportCompanySelected, setTransportCompanySelected] = useState<any[]>([]);


  const [samStatus, setSamStatus] = useState<any[]>([
    { id: 1, name: 'Emitido' },
    { id: 2, name: 'Inicializado' },
    { id: 3, name: 'Inactivado' },
    { id: 4, name: 'Cancelado' },
    { id: 5, name: 'Activado' },
    { id: 6, name: 'Dañado' }
  ]);
  const [samStatusSeleted, setSamStatusSelected] = useState<any[]>([]);

  const [fromDate, setFromDate] = useState<any>();
  const [toDate, setToDate] = useState<any>();
  const [fromDateInput, setFromDateInput] = useState<any>();
  const [toDateInput, setToDateInput] = useState<any>();


  const [samIdField, setSamIdField] = useState<string>('');

  //hooks
  const { genericApiConfiguration } = useApi();

  const getDevices = () => {
    genericApiConfiguration('/device-types', 'GET', {}, {}, {}).then(
      (response: any) => {
        if (response !== null) {
          setDeviceList(response.data.message);
        }
      }
    );
  };

  const getUsers = async () => {
    let response = await getUserManager();
    if (response.status === 200) {
      setUsers(response.data);
    }
  };

  const getOperators = () => {
    genericApiConfiguration('/operators', 'GET', {}, {}, {}).then(
      (response: any) => {
        if (response !== null) {
          setOperatorsList(response.data.message);
        }
      }
    );
  };

  const setTransportCompanies = () => {
    genericApiConfiguration('/transport-companies', 'GET', {}, {}, {}).then(
      (response: any) => {
        if (response !== null) {
          setTransportCompany(response.data.message);
        }
      }
    );
  };

  const getProjects = () => {
    genericApiConfiguration('/projects', 'GET', {}, {}, {}).then(
      (response: any) => {
        if (response !== null) {
          setProjectsList(response.data.message);
        }
      }
    );
  };


  useEffect(() => {
    getDevices();
    getOperators();
    getProjects();
    setTransportCompanies();
    getUsers();
  }, []);

  /**
   * Mientras se pasa a BD el filtro que seleccionaro, se guarda todo el array en localStorage
   * @param e MultiSelectChangeEvent
   */

  function selectDevice(e: MultiSelectChangeEvent) {
    setDevicesSelected(e.value);
  }

  function selectOperators(e: MultiSelectChangeEvent) {
    setOperatorsSelected(e.value);
  }

  function selectProjects(e: MultiSelectChangeEvent) {
    setProjectsSelected(e.value);
  }
  function selectSamStatus(e: MultiSelectChangeEvent) {
    setSamStatusSelected(e.value);
  }

  function selectTransportCompany(e: MultiSelectChangeEvent) {
    setTransportCompanySelected(e.value);
  }

  function selectUsers(e: MultiSelectChangeEvent) {
    setUserSelected(e.value);
  }
  const renderSelectDevices = () => {
    return (
      <div className="w-1/12">
        <MultiSelectComponent
          options={deviceList}
          optionLabel={'name'}
          label='Dispositivos:'
          placeholder="Dispositivos"
          value={devicesSelected}
          className=" h-12"
          onChange={(e: MultiSelectChangeEvent) => selectDevice(e)}
          max={0}
          selectedItemsLabel="Seleccionado"
        />
      </div>
    );
  };

  const renderSelectOperators = () => {
    return (
      <div className="w-2/12">
        <MultiSelectComponent
          options={operatorsList}
          label='Operadores tecnológicos:'
          optionLabel={'name'}
          placeholder="Operadores tecnológicos"
          value={operatorsSelected}
          className=" h-12"
          onChange={(e: MultiSelectChangeEvent) => selectOperators(e)}
          max={2}
          selectedItemsLabel="Seleccionado"
        />
      </div>
    );
  };

  const renderSelectEconomicNumbers = () => {
    return (
      <div className="w-1/12">
        <MultiSelectComponent
          options={transportCompany}
          label='EUR:'
          optionLabel={'eur'}
          placeholder="EUR"
          value={transportCompanySelected}
          className=" h-12"
          onChange={(e: MultiSelectChangeEvent) => selectTransportCompany(e)}
          max={2}
          selectedItemsLabel="Seleccionado"
        />
      </div>
    );
  };


  const renderSelectProjects = () => {
    return (
      <div className="w-1/12">
        <MultiSelectComponent
          options={projectsList}
          optionLabel={'name'}
          placeholder="Proyectos"
          label='Proyectos:'
          value={projectsSelected}
          className=" h-12"
          onChange={(e: MultiSelectChangeEvent) => selectProjects(e)}
          max={2}
          selectedItemsLabel="Seleccionado"
        />
      </div>
    );
  };

  const renderSelectUsers = () => {
    return (
      <div className="w-1/12">
        <MultiSelectComponent
          options={users}
          optionLabel={'name'}
          placeholder="Usuarios"
          label='Usuarios:'
          value={usersSelected}
          className=" h-12"
          onChange={(e: MultiSelectChangeEvent) => selectUsers(e)}
          max={2}
          selectedItemsLabel="Seleccionado"
        />
      </div>
    );
  };

  const renderFindSearch = () => {
    return (
      <div className="w-2/12 mt-auto">
        <InputComponent
          type={'text'}
          name={'find'}
          value={samIdField}
          className=" h-12"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setSamIdField(e.target.value)
            setFilterSamId(e.target.value)
          }
          }
          placeholder="Filtrar por SAM ID"
          classSpan={'p-input-icon-right'}
        />
      </div>
    );
  };

  const renderSelectSamStatus = () => {
    return (
      <div className="w-40">
        <MultiSelectComponent
          options={samStatus}
          optionLabel={'name'}
          label='Estatus de sam:'
          placeholder="Estatus"
          value={samStatusSeleted}
          className=" h-12"
          onChange={(e: MultiSelectChangeEvent) => selectSamStatus(e)}
          max={0}
          selectedItemsLabel="Seleccionado"
        />
      </div>
    );
  };

  const handlerFilters = (queryRequest: string) => {
    let url = `/v1.0/histories?${queryRequest}`;
    dispatchLoading({ type: LOADING_ON });
    setLoader(true)
    genericApiCallApiBilling(url, 'GET', {}, {}, {}).then((response: any) => {

      if (response !== null) {
        setHistories(response.data)
      }

      dispatchLoading({ type: LOADING_OFF });
      setLoader(false)
    }).catch(() => { dispatchLoading({ type: LOADING_OFF }); setLoader(false) });
  };

  const sendData = () => {
    //Debido a que se repiten las cabeceras de los qwery params, no podemos usar un Json para pasarlos al axios, lo vamos a implementar directo en la url;
    let queryRequest = '';
    devicesSelected.forEach(element => {
      queryRequest = `${queryRequest}&deviceType=${element.deviceTypeId}`;
    });
    operatorsSelected.forEach(element => {
      queryRequest = `${queryRequest}&technologicalOperator=${element.id}`;
    });
    projectsSelected.forEach(element => {
      queryRequest = `${queryRequest}&project=${element.id}`;
    });

    transportCompanySelected.forEach(element => {
      queryRequest = `${queryRequest}&transportcompanyeur=${element.id}`;
    });


    usersSelected.forEach(element => {
      queryRequest = `${queryRequest}&userId=${element.userId}`;
    });

    samStatusSeleted.forEach(element => {
      queryRequest = `${queryRequest}&samStatus=${element.id}`;
    });

    if (samIdField !== '') {
      queryRequest = `${queryRequest}&samIds=${samIdField}`;
    }

    if (fromDate !== '' && fromDate !== null && fromDate !== undefined) {
      queryRequest = `${queryRequest}&fromDate=${fromDate}`;
    }

    if (toDate !== '' && toDate !== null && toDate !== undefined) {
      queryRequest = `${queryRequest}&toDate=${toDate}`;
    }

    handlerFilters(queryRequest);
    setFilterQuery("?" + queryRequest)
  };

  const renderButtonSendData = () => {
    return (
      <div className="w-auto h-12 mt-auto flex flex-row space-x-3 ml-auto">
        <ButtonComponent
          label="Enviar"
          onClick={sendData}
          className={'h-full'}
        />
        {<ButtonComponent
          label="Limpiar filtro"
          onClick={() => {
            setFromDate(undefined)
            setFromDateInput(undefined)
            setToDate(undefined)
            setToDateInput(undefined)
            setDevicesSelected([])
            setOperatorsSelected([])
            setOperatorsSelected([])
            setSamStatusSelected([])
            setUserSelected([])
            setProjectsSelected([])
            setTransportCompanySelected([])
            setSamIdField('')
            setFilterQuery("")
          }}
          className={'h-full w-40'}
        />
        }
       
      </div>
    );
  };

  const renderBtnExcel = () =>{
    return(
      <div className="w-auto h-12 mt-auto flex flex-row text-[50px] text-alternative_green content-center items-center cursor-pointer">
      <RiFileExcel2Line
       onClick={async () => {
         await dowloadExcelHistorySam("Historial SAM", filterQuery);
       }}
       title="Exportar a Excel"
     />
   </div>
    )
  }

  const renderFromDate = () => {
    return (
      <div className="w-40">
        <CalendarComponent
          label={'Fecha inicio: '}
          name={'fromDate'}
          onChange={(e: any) => {
            setFromDateInput(e.value)
            if (e.value !== undefined && e.value !== null) {
              var dateFrom = new Date(e.value)
              const day = String(dateFrom.getDate()).padStart(2, '0');
              const month = String(dateFrom.getMonth() + 1).padStart(2, '0'); // Los meses empiezan desde 0
              const year = dateFrom.getFullYear();

              const formattedDate = `${year}-${month}-${day}`;
              setFromDate(formattedDate)
            } else {
              setFromDate(undefined)
            }
          }
          }
          value={fromDateInput}
          placeholder="Fecha inicio"
          className=' h-12'
        />
      </div>
    );
  };


  const renderToDate = () => {
    return (
      <div className="w-40">
        <CalendarComponent
          label={'Fecha fin:'}
          name={'toDate'}
          onChange={(e: any) => {
            setToDateInput(e.value)
            if (e.value !== undefined && e.value !== null) {
              var dateFrom = new Date(e.value)
              const day = String(dateFrom.getDate()).padStart(2, '0');
              const month = String(dateFrom.getMonth() + 1).padStart(2, '0'); // Los meses empiezan desde 0
              const year = dateFrom.getFullYear();

              const formattedDate = `${year}-${month}-${day}`;
              setToDate(formattedDate)
            } else {
              setToDate(undefined)
            }
          }
          }
          value={toDateInput}
          placeholder="Fecha inicio"
          className=' h-12'
        />
      </div>
    );
  };


  return (
    <>
      <div className="w-full h-auto flex justify-between pb-3">
        <div className="flex gap-2">
          {renderFromDate()}
          {renderToDate()}
          {renderSelectDevices()}
          {renderSelectOperators()}
          {renderSelectEconomicNumbers()}
          {renderSelectProjects()}
          {renderSelectSamStatus()}
          {renderSelectUsers()}
          {renderFindSearch()}
          {renderButtonSendData()}
        </div>
        {renderBtnExcel()}
      </div>
    </>
  );
};

import { FunctionComponent,  useState } from 'react';

import { MainContainer } from '../../layout/components/MainContainer';
import { TableHistorySam } from './components/TableHistorySam';

export const HistorySam: FunctionComponent = () => {
  const [openRightMenu, setOpenRightMenu] = useState(false);

  const renderContent = () => {
    return (
      <div className="flex w-full h-full relative flex-row">
        <div className="flex grow h-full relative flex-col content-center items-center justify-center p-10 ">
          <TableHistorySam/>
        </div>
      </div>
    );
  };

  const loading = false;

  return (
    <MainContainer
      openRightMenu={openRightMenu}
      setOpenRightMenu={setOpenRightMenu}
      loading={loading}
      rightMenuContent={<></>}>
      {renderContent()}
    </MainContainer>
  );
};

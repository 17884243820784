import { useContext } from 'react';

import { ToastComponent } from '../../components/ToastComponent';
import {
  GET_DEVICETYPES_CATALOG,
  GET_DEVICETYPES_CATALOG_SUCCESS,
  GET_DEVICETYPES_CATALOG_ERROR,
  GET_EXPORTDEVICETYPES_CATALOG,
  GET_EXPORTDEVICETYPES_CATALOG_SUCCESS,
  GET_EXPORTDEVICETYPES_CATALOG_ERROR,
  POST_DEVICETYPES_CATALOG,
  POST_DEVICETYPES_CATALOG_SUCCESS,
  POST_DEVICETYPES_CATALOG_ERROR,
  PUT_DEVICETYPES_CATALOG,
  PUT_DEVICETYPES_CATALOG_SUCCESS,
  PUT_DEVICETYPES_CATALOG_ERROR,
  DELETE_DEVICETYPES_CATALOG,
  DELETE_DEVICETYPES_CATALOG_SUCCESS,
  DELETE_DEVICETYPES_CATALOG_ERROR,
} from '../../constants/constantsRed';
import { StoreContext } from '../../context/Provider';
import useApi from '../../services/request.service';
import { string } from 'yup';

export const useDeviceTypesCatalogs = () => {
  const {
    getDeviceTypesCatalogState,
    postExportDeviceTypesCatalogState,
    postDeviceTypesCatalogState,
    putDeviceTypesCatalogState,
    deleteDeviceTypesCatalogState
  } = useContext(StoreContext);

  const { genericApiConfiguration } = useApi();

  const getDeviceTypesCatalog = async () => {
    getDeviceTypesCatalogState.dispatch({ type: GET_DEVICETYPES_CATALOG });
    try {
      const result: any = await genericApiConfiguration(
        '/device-types',
        'GET',
        {},
        {},
        {}
      );
      if (result.status === 200) {
        const payload = result.data.message;
        getDeviceTypesCatalogState.dispatch({
          type: GET_DEVICETYPES_CATALOG_SUCCESS,
          payload: payload,
        });
      }
      return result;
    } catch (error: any) {
      ToastComponent(
        'warning',
        'Error',
        'La operación falló, inténtelo más tarde por favor.'
      );
      getDeviceTypesCatalogState.dispatch({
        type: GET_DEVICETYPES_CATALOG_ERROR,
        payload: error.message,
      });
      return 'error';
    }
  };

  const postExportDeviceTypesCatalog = async (
    pathSendExcel: string,
    formData: any
  ) => {
    postExportDeviceTypesCatalogState.dispatch({
      type: GET_EXPORTDEVICETYPES_CATALOG,
    });
    try {
      const result: any = await genericApiConfiguration(
        pathSendExcel + '/import',
        'POST',
        formData,
        {},
        { 'Content-Type': 'multipart/form-data' }
      );

      if (result.status !== 200 && !result?.response?.data?.success) {
        let isArray = false;
        let messages = result?.response?.data?.message;

        if (Array.isArray(messages)) {
          isArray = true;
          messages.forEach(
            (message: string) => {
              ToastComponent('warning', 'Error', message);
            }
          );
        }

        if (!isArray)
          ToastComponent('warning', 'Error', messages);

        postExportDeviceTypesCatalogState.dispatch({
          type: GET_EXPORTDEVICETYPES_CATALOG_ERROR,
          payload: result?.response?.data?.message,
        });

        return result;
      }

      const payload = result.data.payload;
      postExportDeviceTypesCatalogState.dispatch({
        type: GET_EXPORTDEVICETYPES_CATALOG_SUCCESS,
        payload: payload,
      });
      ToastComponent('success', 'Succes', result.data?.message);
      return result;
    } catch (error: any) {
      postExportDeviceTypesCatalogState.dispatch({
        type: GET_EXPORTDEVICETYPES_CATALOG_ERROR,
        payload: error.message,
      });
      return 'error';
    }
  };

  const postDeviceTypesCatalog = async (data: any) => {
    postDeviceTypesCatalogState.dispatch({ type: POST_DEVICETYPES_CATALOG });
    try {
      const result: any = await genericApiConfiguration(
        '/device-types',
        'POST',
        data,
        {},
        {}
      );
      if (result.status === 200) {
        ToastComponent('success', '', 'Se ha añadido el registro correctamente');
        const payload = result.data.payload;
        postDeviceTypesCatalogState.dispatch({
          type: POST_DEVICETYPES_CATALOG_SUCCESS,
          payload: payload,
        });
      }
      return result;
    } catch (error: any) {
      postDeviceTypesCatalogState.dispatch({
        type: POST_DEVICETYPES_CATALOG_ERROR,
        payload: error.message,
      });
      return 'error';
    }
  };

  const putDeviceTypesCatalog = async (data: any) => {
    putDeviceTypesCatalogState.dispatch({ type: PUT_DEVICETYPES_CATALOG });
    try {
      const result: any = await genericApiConfiguration(
        '/device-types',
        'PUT',
        data,
        {},
        {}
      );
      if (result.status === 200) {
        ToastComponent('success', '', 'Se ha editado el registro correctamente');
        const payload = result.data.payload;
        putDeviceTypesCatalogState.dispatch({
          type: PUT_DEVICETYPES_CATALOG_SUCCESS,
          payload: payload,
        });
      }
      return result;
    } catch (error: any) {
      putDeviceTypesCatalogState.dispatch({
        type: PUT_DEVICETYPES_CATALOG_ERROR,
        payload: error.message,
      });
      return 'error';
    }
  };

  const deleteDeviceTypesCatalog = async (id: string) => {
    deleteDeviceTypesCatalogState.dispatch({ type: DELETE_DEVICETYPES_CATALOG });
    try {
      const result: any = await genericApiConfiguration(
        '/device-types/' + id,
        'DELETE',
        {},
        {},
        {}
      );

      if (result.status === 200) {
        ToastComponent(
          'success',
          'Succes',
          'Se eliminó correctamente el registro.'
        );
        const payload = result.data.payload;
        deleteDeviceTypesCatalogState.dispatch({
          type: DELETE_DEVICETYPES_CATALOG_SUCCESS,
          payload: payload,
        });
      }
      return result;
    } catch (error: any) {
      deleteDeviceTypesCatalogState.dispatch({
        type: DELETE_DEVICETYPES_CATALOG_ERROR,
        payload: error.message,
      });
      return 'error';
    }
  };

  return {
    getDeviceTypesCatalog,
    postExportDeviceTypesCatalog,
    postDeviceTypesCatalog,
    putDeviceTypesCatalog,
    deleteDeviceTypesCatalog,
  };
};

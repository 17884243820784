import {
    PATCH_ROLE,
    PATCH_ROLE_SUCCESS,
    PATCH_ROLE_ERROR,
  } from '../../../constants/constantsRed';
  
  export const patchRoleRed = (state: any = false, actions: any) => {
    switch (actions.type) {
      case PATCH_ROLE:
        return {
          ...state,
          loading: true,
        };
      case PATCH_ROLE_ERROR:
        return {
          data: null,
          loading: false,
          error: null,
        };
      case PATCH_ROLE_SUCCESS:
        return {
          ...state,
          loading: false,
          error: null,
          data: actions.payload,
        };
      default:
        return state;
    }
  };
  
import { FunctionComponent, useEffect, useState } from 'react';

import { InputComponent } from '../../../components/InputComponent';
import { IInventoryProvider } from '../../../interfaces/service/InventoryServiceManagerInterface';
import { PopUpComponent } from '../../../components/PopUpComponent';
import { ActivePopUp } from '../../../interfaces/interfaceInventory';

interface IWhiteListSamProps {
  serviceInvetory: IInventoryProvider;
}

export const ModifyWhiteListSam = (props: IWhiteListSamProps) => {
  const { serviceInvetory } = props;

  const bodyContent = () => {
    return (
      <div className="w-full h-full flex p-6 justify-center">
        <InputComponent
          type="text"
          name="Comentarios"
          placeholder="Observaciones"
          label="Observaciones"
          value={serviceInvetory.whiteListValue}
          onChange={serviceInvetory.handleOnChangeBodyWhiteList}
        />
      </div>
    );
  };

  return (
    <PopUpComponent
      header={serviceInvetory.samInfo?.WhiteList === true ? 'Quitar de la lista blanca' : 'Agregar a la lista blanca'}
      showPopUp={serviceInvetory.activePopUp === ActivePopUp.ModifyWhiteList && serviceInvetory.showPopUp}
      onSubmit={() => serviceInvetory.onModifyWhiteList()}
      onClosePopUp={() => serviceInvetory.closePopUp()}
      footer={true}
      body={bodyContent()}
    />
  );
};

import { useContext } from 'react';

import { ToastComponent } from '../../components/ToastComponent';
import {
    GET_SAM_CATALOG_VERSIONS,
    GET_SAM_CATALOG_VERSIONS_SUCCESS,
    GET_SAM_CATALOG_VERSIONS_ERROR,
} from '../../constants/catalog';
import { StoreContext } from '../../context/Provider';
import useApi from '../../services/request.service';

export const useSamCatalogsFilters = () => {
  const context = useContext(StoreContext);
  const { getSamKeyVersions } = useContext(StoreContext);
  const { genericApiConfiguration, genericApiCallApiBilling } = useApi();

  const getSamCatalogKeyVersions = async () => {
    getSamKeyVersions.dispatch({ type: GET_SAM_CATALOG_VERSIONS });
    try {
      const result: any = await genericApiCallApiBilling(
        '/sam-key-versions',
        'GET',
        {},
        {},
        {}
      );
      if (result.status === 200) {
        const payload = result.data;
        getSamKeyVersions.dispatch({
          type: GET_SAM_CATALOG_VERSIONS_SUCCESS,
          payload: payload,
        });
      }
      return result;
    } catch (error: any) {
      ToastComponent(
        'warning',
        'Error',
        'La operación falló, inténtelo más tarde por favor.'
      );
      getSamKeyVersions.dispatch({
        type: GET_SAM_CATALOG_VERSIONS_ERROR,
        payload: error.message,
      });
      return 'error';
    }
  };

  return {
    getSamCatalogKeyVersions,
  };
};

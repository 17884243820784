import { PopUpComponent } from '../../../components/PopUpComponent';
import { InputComponent } from '../../../components/InputComponent';
import { IInventoryProvider } from '../../../interfaces/service/InventoryServiceManagerInterface';
import { ActivePopUp } from '../../../interfaces/interfaceInventory';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

interface IRechargeCounterModal {
  serviceInvetory: IInventoryProvider;
}

export const RechargeCouterModal = (props: IRechargeCounterModal) => {
  const { serviceInvetory } = props;
  //   if (countersFromSamTypeStateContext.state?.data !== undefined && countersFromSamTypeStateContext.state?.data !== null) {
  //     let data = countersFromSamTypeStateContext.state?.data?.CounterTypes?.filter((x: any) => x.Status);

  //     let records: Array<IDataBodyForm> = []
  //     data?.forEach((elementChild: any) => {
  //       let record: IDataBodyForm = { amount: 0, counterTypeId: elementChild.Id }
  //       records.push(record)
  //     });
  //     setCounters(data)

  //     if (selectedSam.length !== 1) {
  //       setDataForm(records)
  //       setInitialDataForm(records)
  //     }
  //   } else {
  //     setCounters([])
  //   }
  // }, [countersFromSamTypeStateContext.state]);

  // useEffect(() => {
  //   if (countersFromSamIdStateContext.state?.data !== undefined && countersFromSamIdStateContext.state?.data !== null) {
  //     let records: Array<IDataBodyForm> = []
  //     let data = countersFromSamIdStateContext.state?.data;

  //     data?.forEach((elementChild: any) => {
  //       let record: IDataBodyForm = { amount: elementChild?.Amount ?? 0, counterTypeId: elementChild.CounterTypeId }
  //       records.push(record)
  //     });

  //     if (countersFromSamIdStateContext.state?.data?.length > 0) {
  //       setDataForm(records)
  //       setInitialDataForm(records)
  //     }
  //     else {
  //       let data = countersFromSamTypeStateContext.state?.data?.CounterTypes?.filter((x: any) => x.Status);
  //       let records: Array<IDataBodyForm> = []
  //       data?.forEach((elementChild: any) => {
  //         let record: IDataBodyForm = { amount: 0, counterTypeId: elementChild.Id }
  //         records.push(record)
  //       });
  //       setDataForm(records)
  //       setInitialDataForm(records)
  //     }
  //   }
  // }, [countersFromSamIdStateContext.state]);

  const bodyContent = () => {
    const templateInput = (data: any) => {
      let inputValue = serviceInvetory.counterData.find((currentData: any) => currentData.counterTypeId === data.Id)?.amount || "";

      return (
        <InputComponent
          id={data.Id}
          type="number"
          name={data.Address}
          placeholder={`Cantidad para ${data.Address}`}
          value={inputValue}
          onChange={serviceInvetory.editCounterModalValues}
        />
      );
    };
    var table = (
      <DataTable value={serviceInvetory.counters} scrollable={true} scrollHeight="500px" emptyMessage="Sin resultados">
        <Column field="Address" header="Dirección" className='w-2'></Column>
        <Column field="ammount" header="Cantidad" body={templateInput}></Column>
      </DataTable>
    )

    return table;
  };

  return (
    <PopUpComponent
      header={`Agregar contadores para ${serviceInvetory.popUpHeader}`}
      showPopUp={serviceInvetory.activePopUp === ActivePopUp.RechargeCounterModal && serviceInvetory.showPopUp}
      onSubmit={() => serviceInvetory.getRechargeConterResponse()}
      onClosePopUp={() => serviceInvetory.closePopUp()}
      footerOnlyCancel={serviceInvetory.counters.length > 0 ? false : true}
      footer={true}
      body={bodyContent()}
    />
  );
};

import { LOADING_OFF, LOADING_ON } from '../../../constants/constantsRed';

export const loadingRed = (state: any = false, actions: any) => {
  switch (actions.type) {
    case LOADING_ON:
      return true;
    case LOADING_OFF:
      return false;
    default:
      return state;
  }
};

import './index.css';
import 'primereact/resources/primereact.min.css';

import { MsalProvider } from '@azure/msal-react';
import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';
import { authService } from './authentication/authService';
import reportWebVitals from './reportWebVitals';

const initConfiguration = async () => {
  try {
    await authService.initConfiguration().then(() => {
      authService.createMsal().then(() => {
        const instance = authService.msal.getMsalInstance();
        const root = ReactDOM.createRoot(document.getElementById('root')!);
        return root.render(
          <MsalProvider instance={instance}>
            <App />
          </MsalProvider>
        );
      });
    });
  } catch (error) {
    console.log(error);
  }
};

initConfiguration();

reportWebVitals();

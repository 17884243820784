export const LOADING_ON = 'LOADING_ON';
export const LOADING_OFF = 'LOADING_OFF';

export const GET_SETTINGS = 'GET_SETTINGS';
export const GET_SETTINGS_ERROR = 'GET_SETTINGS_ERROR';
export const GET_SETTINGS_SUCCESS = 'GET_SETTINGS_SUCCESS';

/**
 * Sam Zone
 */

export const PATCH_EDIT_SAM = 'PATCH_EDIT_SAMS';
export const PATCH_EDIT_SAM_SUCCESS = 'PATCH_EDIT_SAM_SUCCESS';
export const PATCH_EDIT_SAM_ERROR = 'PATCH_EDIT_SAM_ERROR';

export const PUT_EDIT_SAM = 'PUT_EDIT_SAMS';
export const PUT_EDIT_SAM_SUCCESS = 'PUT_EDIT_SAM_SUCCESS';
export const PUT_EDIT_SAM_ERROR = 'PUT_EDIT_SAM_ERROR';

export const GET_SAM_BY_ID = 'GET_SAM_BY_IDS';
export const GET_SAM_BY_ID_SUCCESS = 'GET_SAM_BY_ID_SUCCESS';
export const GET_SAM_BY_ID_ERROR = 'GET_SAM_BY_ID_ERROR';

export const GET_SAM_TYPES = 'GET_SAM_TYPESS';
export const GET_SAM_TYPES_SUCCESS = 'GET_SAM_TYPES_SUCCESS';
export const GET_SAM_TYPES_ERROR = 'GET_SAM_TYPES_ERROR';

export const PUT_RECHARGECOUNTER = 'PUT_RECHARGECOUNTER';
export const PUT_RECHARGECOUNTER_SUCCESS = 'PUT_RECHARGECOUNTER_SUCCESS';
export const PUT_RECHARGECOUNTER_ERROR = 'PUT_RECHARGECOUNTER_ERROR';

/**
 * End Sam Zone
 */

/**
 * Users
 */
export const GET_USERLIST = 'GET_USERLIST';
export const GET_USERLIST_SUCCESS = 'GET_USERLIST_SUCCESS';
export const GET_USERLIST_ERROR = 'GET_USERLIST_ERROR';

export const POST_USERLIST = 'POST_USERLIST';
export const POST_USERLIST_SUCCESS = 'POST_USERLIST_SUCCESS';
export const POST_USERLIST_ERROR = 'POST_USERLIST_ERROR';

export const PATCH_USER = 'PATCH_USER';
export const PATCH_USER_SUCCESS = 'PATCH_USER_SUCCESS';
export const PATCH_USER_ERROR = 'PATCH_USER_ERROR';

export const DELETE_USER = 'DELETE_USER';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_ERROR = 'DELETE_USER_ERROR';

/**
 * Roles
 */
export const GET_ROLES = 'GET_ROLES';
export const GET_ROLES_SUCCESS = 'GET_ROLES_SUCCESS';
export const GET_ROLES_ERROR = 'GET_ROLES_ERROR';

export const POST_ROLE = 'POST_USERLIST';
export const POST_ROLE_SUCCESS = 'POST_USERLIST_SUCCESS';
export const POST_ROLE_ERROR = 'POST_USERLIST_ERROR';

export const PATCH_ROLE = 'PATCH_USER';
export const PATCH_ROLE_SUCCESS = 'PATCH_USER_SUCCESS';
export const PATCH_ROLE_ERROR = 'PATCH_USER_ERROR';

export const DELETE_ROL = 'DELETE_ROL';
export const DELETE_ROL_SUCCESS = 'DELETE_ROL_SUCCESS';
export const DELETE_ROL_ERROR = 'DELETE_ROL_ERROR';

/**
 * Permissions
 */

export const GET_PERMISSIONS = 'GET_PERMISSIONS';
export const GET_PERMISSIONS_SUCCESS = 'GET_PERMISSIONS_SUCCESS';
export const GET_PERMISSIONS_ERROR = 'GET_PERMISSIONS_ERROR';

/**
 * Catalogs
 */

// Operators

export const GET_OPERATORS_CATALOG = 'GET_OPERATORS_CATALOG';
export const GET_OPERATORS_CATALOG_SUCCESS = 'GET_OPERATORS_CATALOG_SUCCESS';
export const GET_OPERATORS_CATALOG_ERROR = 'GET_OPERATORS_CATALOG_ERROR';

export const POST_OPERATORS_CATALOG = 'POST_OPERATORS_CATALOG';
export const POST_OPERATORS_CATALOG_SUCCESS = 'POST_OPERATORS_CATALOG_SUCCESS';
export const POST_OPERATORS_CATALOG_ERROR = 'POST_OPERATORS_CATALOG_ERROR';

export const PUT_OPERATORS_CATALOG = 'PUT_OPERATORS_CATALOG';
export const PUT_OPERATORS_CATALOG_SUCCESS = 'PUT_OPERATORS_CATALOG_SUCCESS';
export const PUT_OPERATORS_CATALOG_ERROR = 'PUT_OPERATORS_CATALOG_ERROR';

export const DELETE_OPERATORS_CATALOG = 'DELETE_OPERATORS_CATALOG';
export const DELETE_OPERATORS_CATALOG_SUCCESS =
  'DELETE_OPERATORS_CATALOG_SUCCESS';
export const DELETE_OPERATORS_CATALOG_ERROR = 'DELETE_OPERATORS_CATALOG_ERROR';

// Locations

export const GET_LOCATIONS_CATALOG = 'GET_LOCATIONS_CATALOG';
export const GET_LOCATIONS_CATALOG_SUCCESS = 'GET_LOCATIONS_CATALOG_SUCCESS';
export const GET_LOCATIONS_CATALOG_ERROR = 'GET_LOCATIONS_CATALOG_ERROR';

export const POST_LOCATIONS_CATALOG = 'POST_LOCATIONS_CATALOG';
export const POST_LOCATIONS_CATALOG_SUCCESS = 'POST_LOCATIONS_CATALOG_SUCCESS';
export const POST_LOCATIONS_CATALOG_ERROR = 'POST_LOCATIONS_CATALOG_ERROR';

export const PUT_LOCATIONS_CATALOG = 'PUT_LOCATIONS_CATALOG';
export const PUT_LOCATIONS_CATALOG_SUCCESS = 'PUT_LOCATIONS_CATALOG_SUCCESS';
export const PUT_LOCATIONS_CATALOG_ERROR = 'PUT_LOCATIONS_CATALOG_ERROR';

export const DELETE_LOCATIONS_CATALOG = 'DELETE_LOCATIONS_CATALOG';
export const DELETE_LOCATIONS_CATALOG_SUCCESS =
  'DELETE_LOCATIONS_CATALOG_SUCCESS';
export const DELETE_LOCATIONS_CATALOG_ERROR = 'DELETE_LOCATIONS_CATALOG_ERROR';

// Device types

export const GET_DEVICETYPES_CATALOG = 'GET_DEVICETYPES_CATALOG';
export const GET_DEVICETYPES_CATALOG_SUCCESS =
  'GET_DEVICETYPES_CATALOG_SUCCESS';
export const GET_DEVICETYPES_CATALOG_ERROR = 'GET_DEVICETYPES_CATALOG_ERROR';

export const POST_DEVICETYPES_CATALOG = 'POST_DEVICETYPES_CATALOG';
export const POST_DEVICETYPES_CATALOG_SUCCESS =
  'POST_DEVICETYPES_CATALOG_SUCCESS';
export const POST_DEVICETYPES_CATALOG_ERROR = 'POST_DEVICETYPES_CATALOG_ERROR';

export const PUT_DEVICETYPES_CATALOG = 'PUT_DEVICETYPES_CATALOG';
export const PUT_DEVICETYPES_CATALOG_SUCCESS =
  'PUT_DEVICETYPES_CATALOG_SUCCESS';
export const PUT_DEVICETYPES_CATALOG_ERROR = 'PUT_DEVICETYPES_CATALOG_ERROR';

export const DELETE_DEVICETYPES_CATALOG = 'DELETE_DEVICETYPES_CATALOG';
export const DELETE_DEVICETYPES_CATALOG_SUCCESS =
  'DELETE_DEVICETYPES_CATALOG_SUCCESS';
export const DELETE_DEVICETYPES_CATALOG_ERROR =
  'DELETE_DEVICETYPES_CATALOG_ERROR';

export const GET_EXPORTDEVICETYPES_CATALOG = 'GET_EXPORTDEVICETYPES_CATALOG';
export const GET_EXPORTDEVICETYPES_CATALOG_SUCCESS =
  'GET_EXPORTDEVICETYPES_CATALOG_SUCCESS';
export const GET_EXPORTDEVICETYPES_CATALOG_ERROR =
  'GET_EXPORTDEVICETYPES_CATALOG_ERROR';

//Economic numbers

export const GET_ECONOMICNUMBERS_CATALOG = 'GET_ECONOMICNUMBERS_CATALOG';
export const GET_ECONOMICNUMBERS_CATALOG_SUCCESS =
  'GET_ECONOMICNUMBERS_CATALOG_SUCCESS';
export const GET_ECONOMICNUMBERS_CATALOG_ERROR =
  'GET_ECONOMICNUMBERS_CATALOG_ERROR';

export const POST_ECONOMICNUMBERS_CATALOG = 'POST_ECONOMICNUMBERS_CATALOG';
export const POST_ECONOMICNUMBERS_CATALOG_SUCCESS =
  'POST_ECONOMICNUMBERS_CATALOG_SUCCESS';
export const POST_ECONOMICNUMBERS_CATALOG_ERROR =
  'POST_ECONOMICNUMBERS_CATALOG_ERROR';

export const PUT_ECONOMICNUMBERS_CATALOG = 'PUT_ECONOMICNUMBERS_CATALOG';
export const PUT_ECONOMICNUMBERS_CATALOG_SUCCESS =
  'PUT_ECONOMICNUMBERS_CATALOG_SUCCESS';
export const PUT_ECONOMICNUMBERS_CATALOG_ERROR =
  'PUT_ECONOMICNUMBERS_CATALOG_ERROR';

export const DELETE_ECONOMICNUMBERS_CATALOG = 'DELETE_ECONOMICNUMBERS_CATALOG';
export const DELETE_ECONOMICNUMBERS_CATALOG_SUCCESS =
  'DELETE_ECONOMICNUMBERS_CATALOG_SUCCESS';
export const DELETE_ECONOMICNUMBERS_CATALOG_ERROR =
  'DELETE_ECONOMICNUMBERS_CATALOG_ERROR';

export const GET_PCMODULES_CATALOG = 'GET_PCMODULES_CATALOG';
export const GET_PCMODULES_CATALOG_SUCCESS = 'GET_PCMODULES_CATALOG_SUCCESS';
export const GET_PCMODULES_CATALOG_ERROR = 'GET_PCMODULES_CATALOG_ERROR';

// Pos
export const GET_POS_CATALOG = 'GET_POS_CATALOG';
export const GET_POS_CATALOG_SUCCESS = 'GET_POS_CATALOG_SUCCESS';
export const GET_POS_CATALOG_ERROR = 'GET_POS_CATALOG_ERROR';

export const POST_POS_CATALOG = 'POST_POS_CATALOG';
export const POST_POS_CATALOG_SUCCESS = 'POST_POS_CATALOG_SUCCESS';
export const POST_POS_CATALOG_ERROR = 'POST_POS_CATALOG_ERROR';

export const PUT_POS_CATALOG = 'PUT_POS_CATALOG';
export const PUT_POS_CATALOG_SUCCESS = 'PUT_POS_CATALOG_SUCCESS';
export const PUT_POS_CATALOG_ERROR = 'PUT_POS_CATALOG_ERROR';

export const DELETE_POS_CATALOG = 'DELETE_POS_CATALOG';
export const DELETE_POS_CATALOG_SUCCESS = 'DELETE_POS_CATALOG_SUCCESS';
export const DELETE_POS_CATALOG_ERROR = 'DELETE_POS_CATALOG_ERROR';

//Projects

export const GET_PROJECTS_CATALOG = 'GET_PROJECTS_CATALOG';
export const GET_PROJECTS_CATALOG_SUCCESS = 'GET_PROJECTS_CATALOG_SUCCESS';
export const GET_PROJECTS_CATALOG_ERROR = 'GET_PROJECTS_CATALOG_ERROR';

export const POST_PROJECTS_CATALOG = 'POST_PROJECTS_CATALOG';
export const POST_PROJECTS_CATALOG_SUCCESS = 'POST_PROJECTS_CATALOG_SUCCESS';
export const POST_PROJECTS_CATALOG_ERROR = 'POST_PROJECTS_CATALOG_ERROR';

export const PUT_PROJECTS_CATALOG = 'PUT_PROJECTS_CATALOG';
export const PUT_PROJECTS_CATALOG_SUCCESS = 'PUT_PROJECTS_CATALOG_SUCCESS';
export const PUT_PROJECTS_CATALOG_ERROR = 'PUT_PROJECTS_CATALOG_ERROR';

export const DELETE_PROJECTS_CATALOG = 'DELETE_PROJECTS_CATALOG';
export const DELETE_PROJECTS_CATALOG_SUCCESS =
  'DELETE_PROJECTS_CATALOG_SUCCESS';
export const DELETE_PROJECTS_CATALOG_ERROR = 'DELETE_PROJECTS_CATALOG_ERROR';

// Routes

export const GET_ROUTES_CATALOG = 'GET_ROUTES_CATALOG';
export const GET_ROUTES_CATALOG_SUCCESS = 'GET_ROUTES_CATALOG_SUCCESS';
export const GET_ROUTES_CATALOG_ERROR = 'GET_ROUTES_CATALOG_ERROR';

export const POST_ROUTES_CATALOG = 'POST_ROUTES_CATALOG';
export const POST_ROUTES_CATALOG_SUCCESS = 'POST_ROUTES_CATALOG_SUCCESS';
export const POST_ROUTES_CATALOG_ERROR = 'POST_ROUTES_CATALOG_ERROR';

export const PUT_ROUTES_CATALOG = 'PUT_ROUTES_CATALOG';
export const PUT_ROUTES_CATALOG_SUCCESS = 'PUT_ROUTES_CATALOG_SUCCESS';
export const PUT_ROUTES_CATALOG_ERROR = 'PUT_ROUTES_CATALOG_ERROR';

export const DELETE_ROUTES_CATALOG = 'DELETE_ROUTES_CATALOG';
export const DELETE_ROUTES_CATALOG_SUCCESS = 'DELETE_ROUTES_CATALOG_SUCCESS';
export const DELETE_ROUTES_CATALOG_ERROR = 'DELETE_ROUTES_CATALOG_ERROR';

//Stations

export const GET_STATIONS_CATALOG = 'GET_STATIONS_CATALOG';
export const GET_STATIONS_CATALOG_SUCCESS = 'GET_STATIONS_CATALOG_SUCCESS';
export const GET_STATIONS_CATALOG_ERROR = 'GET_STATIONS_CATALOG_ERROR';

export const POST_STATIONS_CATALOG = 'POST_STATIONS_CATALOG';
export const POST_STATIONS_CATALOG_SUCCESS = 'POST_STATIONS_CATALOG_SUCCESS';
export const POST_STATIONS_CATALOG_ERROR = 'POST_STATIONS_CATALOG_ERROR';

export const PUT_STATIONS_CATALOG = 'PUT_STATIONS_CATALOG';
export const PUT_STATIONS_CATALOG_SUCCESS = 'PUT_STATIONS_CATALOG_SUCCESS';
export const PUT_STATIONS_CATALOG_ERROR = 'PUT_STATIONS_CATALOG_ERROR';

export const DELETE_STATIONS_CATALOG = 'DELETE_STATIONS_CATALOG';
export const DELETE_STATIONS_CATALOG_SUCCESS =
  'DELETE_STATIONS_CATALOG_SUCCESS';
export const DELETE_STATIONS_CATALOG_ERROR = 'DELETE_STATIONS_CATALOG_ERROR';

export const GET_EXPORT_CATALOG = 'GET_EXPORT_CATALOG';
export const GET_EXPORT_CATALOG_SUCCESS = 'GET_EXPORT_CATALOG_SUCCESS';
export const GET_EXPORT_CATALOG_ERROR = 'GET_EXPORT_CATALOG_ERROR';

//Transport companmnies

export const GET_TRANSPORTCOMPANIES_CATALOG = 'GET_TRANSPORTCOMPANIES_CATALOG';
export const GET_TRANSPORTCOMPANIES_CATALOG_SUCCESS =
  'GET_TRANSPORTCOMPANIES_CATALOG_SUCCESS';
export const GET_TRANSPORTCOMPANIES_CATALOG_ERROR =
  'GET_TRANSPORTCOMPANIES_CATALOG_ERROR';

export const POST_TRANSPORTCOMPANIES_CATALOG =
  'POST_TRANSPORTCOMPANIES_CATALOG';
export const POST_TRANSPORTCOMPANIES_CATALOG_SUCCESS =
  'POST_TRANSPORTCOMPANIES_CATALOG_SUCCESS';
export const POST_TRANSPORTCOMPANIES_CATALOG_ERROR =
  'POST_TRANSPORTCOMPANIES_CATALOG_ERROR';

export const PUT_TRANSPORTCOMPANIES_CATALOG = 'PUT_TRANSPORTCOMPANIES_CATALOG';
export const PUT_TRANSPORTCOMPANIES_CATALOG_SUCCESS =
  'PUT_TRANSPORTCOMPANIES_CATALOG_SUCCESS';
export const PUT_TRANSPORTCOMPANIES_CATALOG_ERROR =
  'PUT_TRANSPORTCOMPANIES_CATALOG_ERROR';

export const DELETE_TRANSPORTCOMPANIES_CATALOG =
  'DELETE_TRANSPORTCOMPANIES_CATALOG';
export const DELETE_TRANSPORTCOMPANIES_CATALOG_SUCCESS =
  'DELETE_TRANSPORTCOMPANIES_CATALOG_SUCCESS';
export const DELETE_TRANSPORTCOMPANIES_CATALOG_ERROR =
  'DELETE_TRANSPORTCOMPANIES_CATALOG_ERROR';


export const GET_COUNTER_BY_SAMTYPE = 'GET_COUNTER_BY_SAMTYPE';
export const GET_COUNTER_BY_SAMTYPE_SUCCESS = 'GET_COUNTER_BY_SAMTYPE_SUCCESS';
export const GET_COUNTER_BY_SAMTYPE_ERROR = 'GET_COUNTER_BY_SAMTYPE_ERROR';

export const GET_COUNTER_BY_SAMID = 'GET_COUNTER_BY_SAMID';
export const GET_COUNTER_BY_SAMID_SUCCESS = 'GET_COUNTER_BY_SAMID_SUCCESS';
export const GET_COUNTER_BY_SAMID_ERROR = 'GET_COUNTER_BY_SAMID_ERROR';

export const GET_USER_HISTORY = 'GET_USER_HISTORY';
export const GET_USER_HISTORY_SUCCESS = 'GET_USER_HISTORY_SUCCESS';
export const GET_USER_HISTORY_ERROR = 'GET_USER_HISTORY_ERROR';
import { IServiceMenuComponent } from '@/interfaces/interfaceCatalogs';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { ButtonComponent } from '../../../../components/ButtonComponen';
import { TableComponent } from '../../../../components/Table';
import { Column } from 'primereact/column';
import { CheckboxComponent } from '../../../../components/CheckboxComponent';
import { TitleMenu } from '../../../Inventary/components/rightMenu/TitleMenu';
import { useSamCounterCatalogs } from '../../../../hooks/catalogs/useSamCounterCatalog';

export const SamCounterForm = (props: IServiceMenuComponent) => {
  const { dataRow, updateTable, serviceCatalog } = props;

  const [buttonLabel, setButtonLabel] = useState<string>('Agregar');

  const { patchSamCounterCatalog } = useSamCounterCatalogs();


  useEffect(() => {
    if (dataRow !== undefined) {
      setButtonLabel('Actualizar');
    }
  }, [dataRow]);

  const handleSubmit = async (values: any) => {
    let response: any;

    const result = {
      Code: values.Code,
      SamTypeId: values.SamTypeId,
      Name: values.Name,
      CounterTypes: Object.keys(values)
        .filter(key => /^[0-9A-F]{2}h\d{2}$/.test(key))
        .map(key => {
          const address = key.substring(0, 3);
          const id = values[key];
          const statusKey = `Status${address}`;
          return {
            Id: id,
            Name: "",
            Address: address,
            Status: values[statusKey] || false
          };
        })
    };

    response = await patchSamCounterCatalog([result]);
    if (response.status === 200) {
      serviceCatalog.updateTable();
    }
  };


  const formik = useFormik({
    initialValues: dataRow,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  const generateStatusRows = () => {
    return Object.keys(dataRow)
      .filter(key => key.startsWith('Status'))
      .map(key => ({
        statusCode: key.replace('Status', ''),
        statusValue: dataRow[key],
        statusKey: key,
      }));
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>, statusKey: string) => {
    formik.setFieldValue(statusKey, e.target.checked);
  };

  const renderMenu = (): JSX.Element => {
    const statusRows = generateStatusRows();

    return (
      <div className="flex w-full h-full flex-col items-center bg-white  shadow-[-6px_-1px_5px_#00000024] z-10 right-0">
        <div className="flex w-full h-[10%] ">
          <TitleMenu name={dataRow.Name} />
        </div>
        <div className="flex w-full h-[85%] overflow-y-auto">
          <form
            className="h-full w-full flex flex-col pt-5 px-6"
            onSubmit={formik.handleSubmit}>
            <TableComponent value={statusRows} className={'flex w-full h-full'} paginator={false} rowsPerPageOptions={false}>
              <Column
                header="Cambiar Estatus"
                body={rowData => (
                  <CheckboxComponent
                    checked={formik.values[rowData.statusKey]}
                    onChange={(e: any) => handleCheckboxChange(e, rowData.statusKey)}
                  />
                )}
              />
              <Column field="statusCode" header="Dirección" />
            </TableComponent>
            <ButtonComponent className="mt-10" label={buttonLabel} type="submit" />
          </form>
        </div>
      </div>
    );
  };

  return (
    <div className="flex w-full h-full flex-col items-center bg-white shadow-[-6px_-1px_5px_#00000024] z-10 right-0">
      <div className="flex w-full h-full overflow-y-auto">{renderMenu()}</div>
    </div>
  );
};

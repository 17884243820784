
export const OptionRadar = () =>{
    return (
        {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
                legend: {
                    labels: {
                       color: '#495057'
                    }
                }
            },
            scales: {
                r: {
                    grid: {
                        color: '#CECECEFF'
                    }
                }
            }
        }
    )
}
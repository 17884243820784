import {
  PUT_POS_CATALOG,
  PUT_POS_CATALOG_SUCCESS,
  PUT_POS_CATALOG_ERROR,
} from '../../../../constants/constantsRed';

export const putPosCatalogRed = (
  state: any = false,
  actions: any
) => {
  switch (actions.type) {
    case PUT_POS_CATALOG:
      return {
        ...state,
        loading: true,
      };
    case PUT_POS_CATALOG_SUCCESS:
      return {
        data: null,
        loading: false,
        error: null,
      };
    case PUT_POS_CATALOG_ERROR:
      return {
        ...state,
        loading: false,
        error: null,
        data: actions.payload,
      };
    default:
      return state;
  }
};

import * as Yup from 'yup';

export const validationsCreateRole = Yup.object({
  roleName: Yup.string().min(4, 'Debe tener una longitud mayor'),
  permissionValue: Yup.array().required('Campo requerido'),
});

export const validationsUpdateRole = Yup.object({
  roleName: Yup.string()
    .min(4, 'Debe tener una longitud mayor')
    .required('Campo requerido'),
  /*    permissionValue: Yup.string()
    .min(4, 'Debe tener una longitud mayor')
    .required('Campo requerido'), */
});

import {
  IOperators,
  IServiceMenuComponent,
} from '@/interfaces/interfaceCatalogs';
import { InputSwitchChangeEvent } from 'primereact/inputswitch';
import { classNames } from 'primereact/utils';
import { useEffect, useState } from 'react';

import { ButtonComponent } from '../../../../components/ButtonComponen';
import { InputComponent } from '../../../../components/InputComponent';
import { InputNumberComponent } from '../../../../components/InputNumberComponent';
import { SwitchComponent } from '../../../../components/SwitchComponent';
import { useOperatorsCatalogs } from '../../../../hooks/catalogs/useOperatorsCatalogs';
import { createObjectAsInterface } from '../../../../utils/utils';
import { operatorsValidations } from './validations_form';
import useCustomFormik from './utils/formik_catalog_Config';

export const OperatorsForm = (props: IServiceMenuComponent) => {
  const { serviceCatalog } = props;

  useEffect(() => {
    let value = serviceCatalog.formikOperators.values['phone'];

    if (value) {
      //Evita letras o caracteres
      const regex = /^\d*$/;
      if (!regex.test(value)) {
        serviceCatalog.formikOperators.values['phone'] = value.slice(0, -1);
      }
    }
  }, [serviceCatalog.formikOperators.values['phone']]);

  const renderMenu = (): any => {
    return (
      <form
        className="h-full w-full flex flex-col pt-5 px-6"
        onSubmit={serviceCatalog.formikOperators.handleSubmit}>
        <div className="flex flex-col">
          <InputComponent
            label={'Id de operador'}
            type={'text'}
            name={'technologicalOperatorId'}
            onChange={serviceCatalog.formikOperators.handleChange}
            value={serviceCatalog.formikOperators?.values?.['technologicalOperatorId'] || ''}
            placeholder={'Id de operador'}
            className={classNames({
              'p-invalid': serviceCatalog.isFormFieldValid('technologicalOperatorId', serviceCatalog.formikOperators.errors, serviceCatalog.formikOperators.touched),
            })}
          />
          {serviceCatalog.getFormErrorMessage('technologicalOperatorId', serviceCatalog.formikOperators.errors, serviceCatalog.formikOperators.touched)}
        </div>

        <div className="flex flex-col">
          <InputComponent
            label={'Nombre'}
            type={'text'}
            name={'name'}
            onChange={serviceCatalog.formikOperators.handleChange}
            value={serviceCatalog.formikOperators?.values?.['name'] || ''}
            placeholder={'Nombre'}
            className={classNames({
              'p-invalid': serviceCatalog.isFormFieldValid('name', serviceCatalog.formikOperators.errors, serviceCatalog.formikOperators.touched),
            })}
          />
          {serviceCatalog.getFormErrorMessage('name', serviceCatalog.formikOperators.errors, serviceCatalog.formikOperators.touched)}
        </div>

        {/* <div className="flex flex-col">
          <InputNumberComponent
            label={'Número de proveedor'}
            name={'providerNumber'}
            onValueChange={serviceCatalog.formikOperators.handleChange}
            value={serviceCatalog.formikOperators?.values?.['providerNumber']}
            placeholder={'Número de proveedor'}
            className={classNames({
              'p-invalid': isFormFieldValid('providerNumber'),
            })}
          />
          {getFormErrorMessage('providerNumber')}
        </div> */}

        <div className="flex flex-col">
          <InputComponent
            label={'Dirección'}
            type={'text'}
            name={'address'}
            onChange={serviceCatalog.formikOperators.handleChange}
            value={serviceCatalog.formikOperators?.values?.['address'] || ''}
            placeholder={'Dirección'}
            className={classNames({
              'p-invalid': serviceCatalog.isFormFieldValid('address', serviceCatalog.formikOperators.errors, serviceCatalog.formikOperators.touched),
            })}
          />
          {serviceCatalog.getFormErrorMessage('address', serviceCatalog.formikOperators.errors, serviceCatalog.formikOperators.touched)}
        </div>

        <div className="flex flex-col">
          <InputComponent
            label={'Teléfono'}
            type={'text'}
            name={'phone'}
            maxLength={10}
            onChange={serviceCatalog.formikOperators.handleChange}
            value={serviceCatalog.formikOperators?.values?.['phone'] || ''}
            placeholder={'Teléfono'}
            className={classNames({
              'p-invalid': serviceCatalog.isFormFieldValid('phone', serviceCatalog.formikOperators.errors, serviceCatalog.formikOperators.touched),
            })}
          />
          {serviceCatalog.getFormErrorMessage('phone', serviceCatalog.formikOperators.errors, serviceCatalog.formikOperators.touched)}
        </div>

        <div className="flex flex-col">
          <SwitchComponent
            checked={serviceCatalog.formikOperators?.values?.['status'] || 'false'}
            onChange={(e: InputSwitchChangeEvent) => {
              serviceCatalog.formikOperators.setFieldValue('status', e.value);
            }}
            label={'Estatus'}
            className={classNames({
              'p-invalid': serviceCatalog.isFormFieldValid('status', serviceCatalog.formikOperators.errors, serviceCatalog.formikOperators.touched),
            })}
          />
          {serviceCatalog.getFormErrorMessage('status', serviceCatalog.formikOperators.errors, serviceCatalog.formikOperators.touched)}
        </div>

        <ButtonComponent className="mt-10" label={serviceCatalog.buttonLabel} type="submit" />
      </form>
    );
  };

  return (
    <div className="flex w-full h-full flex-col items-center bg-white  shadow-[-6px_-1px_5px_#00000024] z-10 right-0">
      <div className="flex w-full h-full overflow-y-auto">{renderMenu()}</div>
    </div>
  );
};

import {
  IServiceMenuComponent,
  ILocations,
} from '@/interfaces/interfaceCatalogs';
import { InputSwitchChangeEvent } from 'primereact/inputswitch';
import { classNames } from 'primereact/utils';

import { ButtonComponent } from '../../../../components/ButtonComponen';
import { InputComponent } from '../../../../components/InputComponent';
import { locationsValidation } from './validations_form';
import { SwitchComponent } from '../../../../components/SwitchComponent';
import useCustomFormik from './utils/formik_catalog_Config';

export const LocationsForm = (props: IServiceMenuComponent) => {
  const { serviceCatalog } = props;

  const renderMenu = (): any => {
    return (
      <form
        className="h-full w-full flex flex-col pt-5 px-6"
        onSubmit={serviceCatalog.formikLocations.handleSubmit}>
        <div className="flex flex-col">
          <InputComponent
            label={'Locación'}
            type={'locationId'}
            name={'locationId'}
            onChange={serviceCatalog.formikLocations.handleChange}
            value={serviceCatalog.formikLocations?.values?.['locationId'] || ''}
            placeholder={'Locación'}
            className={classNames({
              'p-invalid': serviceCatalog.isFormFieldValid('locationId', serviceCatalog.formikLocations.errors, serviceCatalog.formikLocations.touched),

            })}
          />
          {serviceCatalog.getFormErrorMessage('locationId', serviceCatalog.formikLocations.errors, serviceCatalog.formikLocations.touched)}

        </div>

        <div className="flex flex-col">
          <SwitchComponent
            checked={serviceCatalog.formikLocations?.values?.['status'] || 'false'}
            onChange={(e: InputSwitchChangeEvent) => {
              serviceCatalog.formikLocations.setFieldValue('status', e.value);
            }}
            label={'Estatus'}
            className={classNames({
              'p-invalid': serviceCatalog.isFormFieldValid('status', serviceCatalog.formikLocations.errors, serviceCatalog.formikLocations.touched),
            })}
          />
          {serviceCatalog.getFormErrorMessage('status', serviceCatalog.formikLocations.errors, serviceCatalog.formikLocations.touched)}
        </div>
        <ButtonComponent className="mt-10" label={serviceCatalog.buttonLabel} type="submit" />
      </form>
    );
  };

  return (
    <div className="flex w-full h-full flex-col items-center bg-white  shadow-[-6px_-1px_5px_#00000024] z-10 right-0">
      <div className="flex w-full h-full overflow-y-auto">{renderMenu()}</div>
    </div>
  );
};

import { StoreContext } from "../../context/Provider";
import { useInventoryService } from "../../hooks/inventory/useInventory";
import { useSamTypes } from "../../hooks/inventory/useSamTypes";
import { useCounterFromSamType } from "../../hooks/inventory/useCounterFromSamType";
import { useRechargeCourter } from "../../hooks/inventory/useRechargeCourter";
import { useFiltersService } from "../../hooks/inventory/useFilters";
import { useEditSam } from "../../hooks/inventory/useEditSam";
import { useConfigurationTriggerService } from "../../hooks/inventory/useConfigurationTrigger";
import { columnsDisplayRechargeCounterHistory, columnsInventoryTable, coulmsInventoryActions } from "../../pages/Inventary/components/table/helper";
import { createQueryParams } from "../../utils/utils";
import { getColumnsInFilter } from '../../pages/Inventary/utils/ColumnsInFilter';
import { createContext, useContext, useEffect, useState, ChangeEvent } from "react";
import { MultiSelectChangeEvent } from "primereact/multiselect";
import { ToastComponent } from "../../components/ToastComponent";
import { IInventoryProvider } from "../../interfaces/service/InventoryServiceManagerInterface";
import { ActivePopUp, ActivePopUpType, IDataBodyForm, ISamInfo, ISendCaptureTriggerData, ISendDataForm } from "../../interfaces/interfaceInventory";

export const InventoryContextSM = createContext<IInventoryProvider>({} as IInventoryProvider);

export const InventoryProvider = (props: any) => {
  const [dataRow, setDataRow] = useState<any>(null);
  const [updateTable, setUpdateTable] = useState<any>(null);

  const [openRightMenu, setOpenRightMenu] = useState<boolean>(false);
  const [showWhiteList, setShowWhiteList] = useState<boolean>(false);
  const [showDisableSam, setShowDisableSam] = useState<boolean>(false);
  const [whiteListVisible, setWhiteListVisible] = useState<boolean>(false);

  const [dinamicColumns, setDinamicColumns] = useState<any[]>([]);

  const [deviceList, setDeviceList] = useState<any[]>([]);
  const [devicesSelected, setDevicesSelected] = useState<any[]>([]);

  const [operatorsList, setOperatorsList] = useState<any[]>([]);
  const [operatorsSelected, setOperatorsSelected] = useState<any[]>([]);

  const [samTypeList, setSamTypeList] = useState<any[]>([]);
  const [samTypeSelected, setSamTypeSelected] = useState<any[]>([]);

  const [projectsList, setProjectsList] = useState<any[]>([]);
  const [projectsSelected, setProjectsSelected] = useState<any[]>([]);

  const [selectMenu, setSelectMenu] = useState<string>('');
  const [selectedSam, setSelectedSam] = useState<any[]>([]);
  const [selectedSamRow, setSelectedSamRow] = useState<any>(undefined);
  const [selectedSamToHistoryRechargeModal, setSelectedSamToHistoryRechargeModal] = useState<any>();
  const [showRechargeCounterModal, setShowRechargeCounterModal] = useState<boolean>(false);
  const [showTriggerComunicationModal, setShowTriggerComunicationModal] = useState<boolean>(false);
  const [columnsList, setColumnsList] = useState<any[]>([]);

  const [columnsDisplayInTable, setColumnsDisplayInTable] = useState<any[]>([]);

  const [filterGlobal, setFilterGlobal] = useState<string>('');
  const [columnsSelected, setColumnSelected] = useState<any[]>([] /* columnSelected */);
  const [allColumnsFilter, setAllColumnsFilter] = useState<any[]>([]);

  const [samInfo, setSamInfo] = useState<ISamInfo | null>(null);

  const [disableSamValue, setDisableSamValue] = useState<string>('');
  const [popUpHeader, setPopUpHeader] = useState<string>('')

  const [samIdField, setSamIdField] = useState<string>('');
  const [filterIdDevice, setFilterIdDevice] = useState<string>('');
  const [samListData, setSamListData] = useState<any>([]);
  const [samListDataTemp, setSamListDataTemp] = useState<any[]>([]);
  const [samListDataCopy, setSamListDataCopy] = useState<any[]>([]);
  const [showHistoryRechageModal, setShowHistoryRechageModal] = useState<boolean>(false);
  const [deviceIdField, setDeviceIdField] = useState<string>('');

  const [whiteListValue, setWhiteListValue] = useState<string>('');

  const [showPopUp, setShowPopUp] = useState<boolean>(false);
  const [rechargeCounterHistoryColumns, setRechargeCounterHistoryColumns] = useState<any[]>([]);

  const [sort, setSort] = useState([{ field: '', order: -1 }]);

  const [counters, setCounters] = useState<any>([]);
  const [counterData, setCounterData] = useState<Array<IDataBodyForm>>([]);
  const [initialDataForm, setInitialDataForm] = useState<IDataBodyForm[]>([]);


  const [activePopUp, setActivePopUp] = useState<ActivePopUpType>(ActivePopUp.DisableSam);

  const [isTimeChecked, setIsTimeChecked] = useState<boolean>(false);
  const [isQuantityChecked, setIsQuantityChecked] = useState<boolean>(false);
  const [timeValue, setTimeValue] = useState<number>(0);
  const [quantityValue, setQuantityValue] = useState<number>(0);
  const [triggerHeaderBody, setTriggerHeaderBody] = useState<string>('');

  const { countersFromSamTypeStateContext, countersFromSamIdStateContext } = useContext(StoreContext)
  const { getCounterBySamType, getCounterBySamId } = useCounterFromSamType();

  const { putRechargeCounter } = useRechargeCourter();

  const { getCatalogProjectsState, getCatalogDeviceState, getCatalogOperatorsState, getSamTypesStates } = useContext(StoreContext);

  const { patchEditSamState, putEditSamState, getSamByIdState } = useContext(StoreContext);
  const { patchEditSam, getSamById } = useEditSam();

  const { inventoryStateContext } = useContext(StoreContext);
  const { getInventoryData, inventoryExcel } = useInventoryService();

  const { getConfigurationTriggerState } = useContext(StoreContext);
  const { getConfigurationTrigger, postConfigurationTrigger } = useConfigurationTriggerService()

  useFiltersService()
  useSamTypes()
  useEditSam();

  useEffect(() => {
    const queryRequest = queryParamsDataToSend();
    let rechargeColumns: any[] = columnsDisplayRechargeCounterHistory()
    setRechargeCounterHistoryColumns(rechargeColumns);
    if (!queryRequest && samListDataTemp.length == 0) {
      ToastComponent(
        '',
        '',
        'Seleccione algún filtro y el botón enviar para mostrar resultados.',
        6000
      );
    }
  }, [])

  useEffect(() => {
    if (
      getCatalogOperatorsState.state.data !== null
    ) {
      setOperatorsList(getCatalogOperatorsState.state.data);
    }
  }, [
    getCatalogOperatorsState.state,
  ]);

  useEffect(() => {
    if (
      getCatalogProjectsState.state.data !== null
    ) {
      setProjectsList(getCatalogProjectsState.state.data)
    }
  }, [
    getCatalogProjectsState.state,
  ]);

  useEffect(() => {
    if (
      getCatalogDeviceState.state.data !== null
    ) {
      setDeviceList(getCatalogDeviceState.state.data);
    }
  }, [
    getCatalogDeviceState.state,
  ]);

  useEffect(() => {
    if (
      getSamTypesStates.state.data !== null
    ) {
      setSamTypeList(getSamTypesStates.state.data);
    }
  }, [
    getSamTypesStates.state,
  ]);

  useEffect(() => {
    if (patchEditSamState.state.loading !== null) {
    }
  }, [patchEditSamState.state]);

  useEffect(() => {
    if (getSamByIdState.state.loading !== null) {
      setSelectedSamToHistoryRechargeModal(getSamByIdState.state.data);
    }
  }, [getSamByIdState.state])

  useEffect(()=>{
    if(getConfigurationTriggerState.state.data !== null){
      const {data} = getConfigurationTriggerState.state;
      setTimeValue(data.minutesForTrigger);
      setIsTimeChecked(data.minutesTriggerEnabled);
      setQuantityValue(data.quantityForTrigger);
      setIsQuantityChecked(data.quantityTriggerEnabled);
    }
  },[getConfigurationTriggerState.state])

  useEffect(() => {
    let defaultColumns: any[] = columnsInventoryTable()
    let actionColum: any[] = coulmsInventoryActions(
      editSamHandler,
      openDisableSam,
      openWhiteListSam,
      openRechargesCounterModalSingle,
      openTriggerComunicationModalSingle
    );
    let inventoryColumns: any[] = [...defaultColumns, ...actionColum];
    let allFilterColumns = allColumns.map(({ field, header }) => ({ field, header }));
    let filterSelectedColumns = defaultColumns.map(({ field, header }) => ({ field, header }));

    setColumnSelected(filterSelectedColumns);
    setAllColumnsFilter(allFilterColumns)
    setDinamicColumns(inventoryColumns)
  }, []);

  useEffect(() => {
    if (filterGlobal === '' && filterIdDevice === '') {
      setSamListDataTemp(samListData);
    } else {
      let filteredData = samListData;

      if (filterGlobal !== '') {
        filteredData = filteredData.filter((x: any) =>
          x?.SamId?.includes(filterGlobal)
        );
      }

      if (filterIdDevice !== '') {
        filteredData = filteredData.filter((x: any) =>
          x?.NumberDevice?.includes(filterIdDevice)
        );
      }

      setSamListDataTemp(filteredData);
    }
  }, [filterGlobal, filterIdDevice]);

  useEffect(() => {
    if (inventoryStateContext.state.data !== null) {
      const { data } = inventoryStateContext.state;

      setSamListData(data);
      setSamListDataCopy(data);
      setSamListDataTemp(data);
    }

  }, [inventoryStateContext.state.data]);

  useEffect(() => {
    if (countersFromSamTypeStateContext.state.data !== null) {
      let data: any = countersFromSamTypeStateContext.state.data.CounterTypes.filter((data: any) => data.Status);

      let records: Array<IDataBodyForm> = []
      data?.forEach((elementChild: any) => {
        let record: IDataBodyForm = { amount: 0, counterTypeId: elementChild.Id }
        records.push(record)
      });
      setCounters(data);
      if (selectedSam.length !== 1) {
        setCounterData(records)
        setInitialDataForm(records)
      }
    }
  }, [countersFromSamTypeStateContext.state]);

  useEffect(() => {
    if (countersFromSamIdStateContext.state?.data !== undefined && countersFromSamIdStateContext.state?.data !== null) {
      let records: Array<IDataBodyForm> = []
      let data = countersFromSamIdStateContext.state?.data;

      data?.forEach((elementChild: any) => {
        let record: IDataBodyForm = { amount: elementChild?.Amount ?? 0, counterTypeId: elementChild.CounterTypeId }
        records.push(record)
      });

      if (countersFromSamIdStateContext.state?.data?.length > 0) {
        setCounterData(records)
        setInitialDataForm(records)
      }
      else {
        let data = countersFromSamTypeStateContext.state?.data?.CounterTypes?.filter((x: any) => x.Status);
        let records: Array<IDataBodyForm> = []
        data?.forEach((elementChild: any) => {
          let record: IDataBodyForm = { amount: 0, counterTypeId: elementChild.Id }
          records.push(record)
        });
        setCounterData(records)
        setInitialDataForm(records)
      }
    }
  }, [countersFromSamIdStateContext.state]);

  function editSamHandler(row: any) {
    setDataRow(row);
    setOpenRightMenu(true);
    setSelectMenu('Actualizar');
    setUpdateTable(() => updateTable);
  };

  function updateInventoryTable() {
    setShowWhiteList(false);
    setShowDisableSam(false);
    setOpenRightMenu(false);
    setSelectMenu('');
    const queryRequest = queryParamsDataToSend();
    getInventoryData(queryRequest);
  };

  function openDisableSam(row: ISamInfo) {
    setSamInfo(row);
    setShowPopUp(true);
    openPopUpFunction(ActivePopUp.DisableSam);
  };

  function openAddContadortes(row: ISamInfo) {
    setSamInfo(row);
    getRechargeHistoryData(row);
    openPopUpFunction(ActivePopUp.RechargeCounterHistory);
  };

  function openWhiteListSam(row: ISamInfo) {
    setSamInfo(row);
    openPopUpFunction(ActivePopUp.ModifyWhiteList);
  };

  function openPopUpFunction(typePopUp: ActivePopUp) {
    setShowPopUp(true);
    setActivePopUp(typePopUp);
  }

  async function handlerFilters(queryRequest: string) {
    return await getInventoryData(queryRequest);
  }

  async function getRechargeHistoryData(row: ISamInfo) {
    return await getSamById(row?.Id, 'counters/');
  }

  function generateExcel() {
    //Debido a que se repiten las cabeceras de los qwery params, no podemos usar un Json para pasarlos al axios, lo vamos a implementar directo en la url;
    let queryRequest = '';
    devicesSelected.forEach(element => {
      queryRequest = `${queryRequest}&deviceType=${element.name}`;
    });
    operatorsSelected.forEach(element => {
      queryRequest = `${queryRequest}&technologicalOperator=${element.name}`;
    });
    projectsSelected.forEach(element => {
      queryRequest = `${queryRequest}&project=${element.name}`;
    });
    samTypeSelected.forEach(element => {
      queryRequest = `${queryRequest}&samType=${element.name}`;
    });
    if (samIdField !== '') {
      queryRequest = `${queryRequest}&samIds=${samIdField}`;
    }

    handlerExcelExport(queryRequest);
  };

  function handlerExcelExport(queryRequest: string) {
    const columnsQueryParams = createQueryParams(
      'columnsToDisplay',
      columnsDisplayInTable.map((item: any) => item.field)
    );
    inventoryExcel(queryRequest, columnsQueryParams);
  };

  function handleFindSearch(e: ChangeEvent<HTMLInputElement>) {
    setSamIdField(e.target.value)
    setFilterGlobal(e.target.value)
  }

  function handeFindSearchDeviceId(e: ChangeEvent<HTMLInputElement>) {
    setDeviceIdField(e.target.value)
    setFilterIdDevice(e.target.value)
  }

  function handleOnChangeBodyWhiteList(e: ChangeEvent<HTMLInputElement>) {
    setWhiteListValue(e.target.value);
  }

  function handleSelectColumn(e: MultiSelectChangeEvent) {
    setColumnSelected(e.value)
    handlerColumn(e.value);
  }

  function selectDevice(e: MultiSelectChangeEvent) {
    setDevicesSelected(e.value);
  }

  function selectOperators(e: MultiSelectChangeEvent) {
    setOperatorsSelected(e.value);
  }

  function selectProjects(e: MultiSelectChangeEvent) {
    setProjectsSelected(e.value);
  }

  function selectSamType(e: MultiSelectChangeEvent) {
    setSamTypeSelected(e.value);
  }

  function handlerColumn(data: any) {
    let newOrderColumns: any = [...data];
    let columns: string = newOrderColumns.map((item: any) => item.field);
    let actionColum: any[] = coulmsInventoryActions(
      editSamHandler,
      openDisableSam,
      openWhiteListSam,
      openRechargesCounterModalSingle,
      openTriggerComunicationModalSingle);

    const sortedColumns = [];

    for (const field of columns) {
      const column = allColumns.find((col: any) => col.field === field);
      if (column) {
        sortedColumns.push(column);
      }
    }
    let inventoryColumns: any[] = [...sortedColumns, ...actionColum];
    setDinamicColumns(inventoryColumns);
  };

  function closeHistoryRechageModal() {
    setShowHistoryRechageModal(false);
  };

  function closePopUp() {
    setDisableSamValue('');
    setShowPopUp(false);
    setTimeValue(0);
    setIsTimeChecked(false);
    setQuantityValue(0);
    setIsQuantityChecked(false);
  }

  async function onModifyDisableSam() {
    const itemValue = samInfo?.SamStatus === 'Activar' ? 3 : 5;

    let data = {
      operation: 'replace',
      path: 'SamStatusId',
      value: itemValue.toString(),
      description: disableSamValue,
    };

    let dataWhiteList = {
      operation: 'replace',
      path: 'WhiteList',
      value: 'false',
      description: 'Se desactivó la SAM',
    };

    const dataToSend = itemValue === 3 && samInfo?.WhiteList ? dataWhiteList : data;

    return await patchEditSam(dataToSend, samInfo?.SamId, false).finally(async () => {
      closePopUp();
      const queryRequest = queryParamsDataToSend();
      return await getInventoryData(queryRequest);
    })
  }

  function onDisableSamValueChange(e: ChangeEvent<HTMLInputElement>) {
    setDisableSamValue(e.target.value);
  }

  function editCounterModalValues(e: ChangeEvent<HTMLInputElement>) {
    const updatedDataForm = counterData.map((item: IDataBodyForm) =>
      item.counterTypeId === parseInt(e.target.id) ? { ...item, amount: e.target.value } : item
    );
    setCounterData(updatedDataForm)
  }

  async function getRechargeConterResponse() {
    let dataSend: Array<ISendDataForm> = []

    if(selectedSamRow !== undefined)
    {
      counters.forEach((elementChild: any) => {
        let record: ISendDataForm = { samId: selectedSamRow.Id, counterTypeId: elementChild.Id, amount: counterData.filter((currentData) => currentData.counterTypeId === elementChild.Id)[0]?.amount ?? 0 }
        dataSend.push(record)
      });
    }else{
      selectedSam.forEach((element: any) => {
        counters.forEach((elementChild: any) => {
          let record: ISendDataForm = { samId: element.Id, counterTypeId: elementChild.Id, amount: counterData.filter((currentData) => currentData.counterTypeId === elementChild.Id)[0]?.amount ?? 0 }
          dataSend.push(record)
        });
      });
    }

    return await putRechargeCounter(dataSend).finally(async () => {
      closeRechargesCounterModal();
      updateInventoryTable();
      setSelectedSam([])
      setSelectedSamRow(undefined)
      closePopUp();
      const queryRequest = queryParamsDataToSend();
      return await getInventoryData(queryRequest);
    });
  };

  function closeDisableSam(value: boolean) {
    setShowDisableSam(value);
  };

  async function onModifyWhiteList() {
    let itemValue = !samInfo?.WhiteList;

    let data = {
      operation: 'replace',
      path: 'WhiteList',
      value: itemValue.toString(),
      description: whiteListValue,
    };

    return await patchEditSam(data, samInfo?.SamId).finally(async () => {
      updateInventoryTable();
      setWhiteListValue('');
      closePopUp();
      const queryRequest = queryParamsDataToSend();
      return await getInventoryData(queryRequest);
    })
  };

  function setDefaultColumns() {
    let save_order = localStorage.getItem('columns_order')!;
    if (save_order === '') {
      let saveOrderArray = defaultColumns.map(column => column.field);
      let strSaveOrder = saveOrderArray.join(',');
      save_order = strSaveOrder;
    }

    const order = save_order.split(',');

    const sortedColumns = [];
    for (const field of order) {
      const column = allColumns.find((col: any) => col.field === field);
      if (column) sortedColumns.push(column);
    }

    defaultColumns = sortedColumns;
  };

  function queryParamsDataToSend() {
    let queryParams: string[] = [];
    const addParams = (selectedArray: any[], paramName: string) => {
      if (selectedArray.length > 0) {
        selectedArray.forEach(element => {
          queryParams.push(`${paramName}=${encodeURIComponent(element.name)}`)
        })
      }
    }
    addParams(devicesSelected, 'deviceType');
    addParams(operatorsSelected, 'technologicalOperator');
    addParams(projectsSelected, 'project');
    addParams(samTypeSelected, 'samType');  // Add samIds field if not empty  
    if (samIdField !== '') {
      queryParams.push(`samIds=${encodeURIComponent(samIdField)}`);
    }

    const queryRequest = queryParams.join('&');
    return queryRequest
  }

  function sendData() {
    const queryRequest = queryParamsDataToSend();
    handlerFilters(queryRequest);
  };

  function closeRechargesCounterModal() {
    setShowRechargeCounterModal(false);
    sendData();
    setSelectedSam([])
  };

  async function openRechargesCounterModal() {
    let firstSamTypeId: any = undefined;

    if (selectedSam !== undefined && selectedSam.length > 0) {
      firstSamTypeId = selectedSam[0].SamTypeId
    }

    const allSameSamType = selectedSam?.every((item: any) => item.SamTypeId === firstSamTypeId);
    if (selectedSam !== undefined && selectedSam.length > 0 && allSameSamType) {
      setPopUpHeader(selectedSam[0]?.SamType)
      await getCounterBySamId(selectedSam[0].SamTypeId);
      return await getCounterBySamType(selectedSam[0].SamTypeId, selectedSam.length === 1, selectedSam[0].Id).finally(async () => {
        openPopUpFunction(ActivePopUp.RechargeCounterModal)

      })
    }
  };

  async function openRechargesCounterModalSingle(row: ISamInfo) {
    setSelectedSamRow(row)
    setPopUpHeader(row.SamType)
    await getCounterBySamId(row.SamTypeId);
    return await getCounterBySamType(row.SamTypeId, true, row.Id).finally(async () => {
      openPopUpFunction(ActivePopUp.RechargeCounterModal)
    })
  }

  function closeTriggerComunicationModal() {
    setShowTriggerComunicationModal(false);
    sendData();
    setSelectedSam([])
  };

  async function openTriggerComunicationModal(){
    if (selectedSam !== undefined && selectedSam.length > 0) {
      setPopUpHeader(`Comunicación con ${selectedSam.length} dispositivos`)
      setTriggerHeaderBody('');
      await getCounterBySamId(selectedSam[0].SamTypeId);
      return await getCounterBySamType(selectedSam[0].SamTypeId, selectedSam.length === 1, selectedSam[0].Id).finally(async () => {
        openPopUpFunction(ActivePopUp.CaptureLimitComunication)
      })
    }
  }

  async function openTriggerComunicationModalSingle(row :ISamInfo){  
    setSelectedSamRow(row)
    setPopUpHeader(`[${row.SamId}] - Comunicación con dispositivo`);
    setTriggerHeaderBody(`para el dispositivo ${row.DeviceType} - ${row.DeviceTypeId}`)
    await getConfigurationTrigger(row.Id).finally(async () =>{
      openPopUpFunction(ActivePopUp.CaptureLimitComunication)
    });
  }

  function onTimeCheckboxChange(){
    setIsTimeChecked(!isTimeChecked);
    if (!isTimeChecked) {
      setTimeValue(2);
    }else{
      setTimeValue(0);
    }
  }

  function onQuantityCheckboxChange(){
    setIsQuantityChecked(!isQuantityChecked);
    if (!isQuantityChecked) {
      setQuantityValue(1);
    }else{
      setQuantityValue(0)
    }
  }

  function handleInputTimeChange(value: string){
    if (value.trim() === '') {
      setTimeValue(2);
      return;
    }
    const intValue = parseInt(value, 10);
    const verifyValue = Math.max(2, Math.min(intValue, 10000));
    setTimeValue(verifyValue);
  }

  function handleInputQuantityChange(value: string){
    if (value.trim() === '') {
      setQuantityValue(1);
      return;
    }
    const intValue = parseInt(value, 10);
    const verifyValue = Math.max(1, Math.min(intValue, 99999));
    setQuantityValue(verifyValue);
  }
  
  async function handleSubmitComunicationTriggers(){
    let dataSend: ISendCaptureTriggerData;
    
    if(selectedSamRow !== undefined)
      {
        dataSend = {
          samIds:[selectedSamRow.Id],
          minutesForTrigger: timeValue,
          minutesTriggerEnabled: isTimeChecked,
          quantityForTrigger: quantityValue,
          quantityTriggerEnabled: isQuantityChecked
        }
      }else{
      dataSend = {
        samIds: selectedSam.map(item => item.Id),
        minutesForTrigger: timeValue,
        minutesTriggerEnabled: isTimeChecked,
        quantityForTrigger: quantityValue,
        quantityTriggerEnabled: isQuantityChecked
      }
    }

    return await postConfigurationTrigger(dataSend).finally(async()=>{
      closeRechargesCounterModal();
      updateInventoryTable();
      setSelectedSam([])
      setSelectedSamRow(undefined)
      closePopUp();
      const queryRequest = queryParamsDataToSend();
      return await getInventoryData(queryRequest);
    })
  }

  let defaultColumns: any[] = columnsInventoryTable();

  const allColumns = [
    ...defaultColumns,
    ...getColumnsInFilter(openAddContadortes),
  ];

  return (
    <InventoryContextSM.Provider
      value={{
        columnsList,
        dataRow,
        openRightMenu,
        selectMenu,
        updateTable,
        showDisableSam,
        showWhiteList,
        samInfo,
        selectedSam,
        selectedSamToHistoryRechargeModal,
        showRechargeCounterModal,
        showTriggerComunicationModal,
        deviceList,
        devicesSelected,
        operatorsList,
        operatorsSelected,
        projectsList,
        samTypeList,
        samTypeSelected,
        loader: inventoryStateContext.state.loading,
        samListDataCopy,
        samListDataTemp,
        columnsDisplayInTable,
        defaultColumns,
        allColumns,
        projectsSelected,
        samIdField,
        filterGlobal,
        showHistoryRechageModal,
        columnsSelected,
        deviceIdField,
        dinamicColumns,
        whiteListValue,
        whiteListVisible,
        rechargeCounterHistoryColumns,
        showPopUp,
        activePopUp,
        popUpHeader,
        disableSamValue,
        sort,
        counters,
        counterData,
        allColumnsFilter,
        isQuantityChecked,
        isTimeChecked,
        quantityValue,
        timeValue,
        triggerHeaderBody,

        setOpenRightMenu,
        setShowDisableSam,
        setShowWhiteList,
        setSelectedSam,
        setSelectedSamToHistoryRechargeModal,
        setShowRechargeCounterModal,
        setDeviceList,
        setDevicesSelected,
        setOperatorsList,
        setOperatorsSelected,
        setProjectsList,
        setSamTypeList,
        setSamTypeSelected,
        setProjectsSelected,
        setSamIdField,
        setFilterGlobal,
        setDefaultColumns,
        setSort,

        editSamHandler,
        updateInventoryTable,
        openDisableSam,
        openWhiteListSam,
        openAddContadortes,
        handlerFilters,
        handleFindSearch,
        handleOnChangeBodyWhiteList,
        closeHistoryRechageModal,
        closeDisableSam,
        sendData,
        openRechargesCounterModal,
        openTriggerComunicationModal,
        closeRechargesCounterModal,
        generateExcel,
        handeFindSearchDeviceId,
        handleSelectColumn,
        selectDevice,
        selectOperators,
        selectProjects,
        selectSamType,
        onModifyDisableSam,
        closePopUp,
        onDisableSamValueChange,
        editCounterModalValues,
        getRechargeConterResponse,
        onModifyWhiteList,
        onQuantityCheckboxChange,
        onTimeCheckboxChange,
        handleInputTimeChange,
        handleInputQuantityChange,
        handleSubmitComunicationTriggers
      }}
    >
      {props.children}
    </InventoryContextSM.Provider>
  );
};
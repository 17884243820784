import React, { useContext, useEffect, useRef } from 'react';

import { RightMenuComponent } from '../../../components/RightMenuComponent';
import { LayoutContext } from '../../../context/LayoutContext';

interface IMainContainer {
  children?: any;
  rightMenuContent?: any;
  openRightMenu?: boolean;
  setOpenRightMenu?: any;
  loading: boolean;
}
export const MainContainer = (props: IMainContainer) => {
  const {
    children,
    rightMenuContent,
    openRightMenu,
    setOpenRightMenu,
    loading = false,
  } = props;

  const rightMenuRef = useRef<HTMLDivElement>(null);

  const contextLayout = useContext(LayoutContext);
  const { prevetCloseRightMenu } = contextLayout;

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {

      if (
        rightMenuRef.current &&
        !rightMenuRef.current.contains(event.target as Node) &&
        !prevetCloseRightMenu
      ) {
        setOpenRightMenu(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  return (
    <div className="flex w-full h-full  relative">
      {children}

      {openRightMenu && (
        <RightMenuComponent
          rightMenuRef={rightMenuRef}
          setOpenRightMenu={setOpenRightMenu}
          loading={loading}>
          {rightMenuContent()}
        </RightMenuComponent>
      )}
    </div>
  );
};

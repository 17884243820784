import {
  PATCH_EDIT_SAM,
  PATCH_EDIT_SAM_ERROR,
  PATCH_EDIT_SAM_SUCCESS,
} from '../../../constants/constantsRed';

export const patchSamRed = (state: any, actions: any) => {
  switch (actions.type) {
    case PATCH_EDIT_SAM:
      return {
        ...state,
        loading: true,
      };
    case PATCH_EDIT_SAM_ERROR:
      return {
        data: null,
        loading: false,
        error: null,
      };
    case PATCH_EDIT_SAM_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: actions.payload,
      };
    default:
      return state;
  }
};

import { DropdownComponent } from '../../../../../components/DropdownComponen';
import { IRenderInput } from '../EditSamMenu';

interface ICetDeviceFormProps {
  formik: any;
  renderInput: (props: IRenderInput) => void;
  routes: any[];
  economicNumbers: any[];
  inputs: any;
}

export const CetDeviceForm = (props: ICetDeviceFormProps) => {
  const { formik, renderInput, routes, economicNumbers } = props;

  return (
    <>
      {renderInput({
        label: 'Id de equipo',
        type: 'text',
        name: 'NumberDevice',
        typedName: 'NumberDevice',
        placeholder: 'Operador',
        max:10
      })}

      <DropdownComponent
        label={'Ruta / Corredor'}
        options={routes}
        optionLabel={'name'}
        name="RouteId"
        optionValue={'routeId'}
        onChange={formik.handleChange}
        value={formik?.values?.['RouteId'] || ''}
      />

      <DropdownComponent
        label={'Vehículo'}
        options={economicNumbers}
        optionLabel={'economicNumber'}
        name="EconomicNumberId"
        optionValue={'economicNumberId'}
        onChange={formik.handleChange}
        value={formik?.values?.['EconomicNumberId'] || ''}
      />

      {renderInput({
        label: 'Número de placas',
        type: 'text',
        name: 'CarPlates',
        typedName: 'CarPlates',
        placeholder: 'Placa',
        max:10
      })}

      {renderInput({
        label: 'Observaciones',
        type: 'text',
        name: 'Observations',
        typedName: 'Observations',
        placeholder: 'Observaciones',
      })}
    </>
  );
};

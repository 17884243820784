import { useFormik, FormikConfig } from 'formik';
import { useEffect } from 'react';

function useCustomFormik(
  inputs: any,
  validationSchema?: FormikConfig<any>['validationSchema'],
  handleSubmit?: (data: any) => void,
  onFormChange?: (changedFields: { [key: string]: any }, values: any) => void
) {
  const formik = useFormik({
    initialValues: inputs || {},
    enableReinitialize: true,
    validationSchema: validationSchema || undefined,
    onSubmit: (data) => {
      if (handleSubmit) {
        handleSubmit(data);
      }
    },
  });

  useEffect(() => {
    if (onFormChange && formik.initialValues && formik.values) { // Verifica que ambos objetos estén definidos
      const changedFields: { [key: string]: any } = {};

      // Comparar `values` con `initialValues` para detectar cambios
      Object.keys(formik.initialValues).forEach((key) => {
        if (formik.initialValues[key] !== formik.values[key]) {
          changedFields[key] = formik.values[key];
        }
      });

      // Solo llama a `onFormChange` si hay cambios
      if (Object.keys(changedFields).length > 0) {
        onFormChange(changedFields, formik.values);
      }
    }
  }, [formik.values, formik.initialValues, onFormChange]);

  return formik;
}

export default useCustomFormik;

import { DropdownComponent } from '../../../../../components/DropdownComponen';

import { IRenderInput } from '../EditSamMenu';

interface IPosDeviceFormProps {
  formik: any;
  renderInput: (props: IRenderInput) => void;
  pos: any[];
}

export const PosDeviceForm = (props: IPosDeviceFormProps) => {
  const { formik, renderInput, pos } = props;

  return (
    <>
      {renderInput({
        label: 'Id de equipo',
        type: 'text',
        name: 'NumberDevice',
        typedName: 'NumberDevice',
        placeholder: 'Operador',
        max:10
      })}

      <DropdownComponent
        label={'Punto de venta'}
        options={pos}
        optionLabel={'name'}
        name="PointOfSaleId"
        optionValue={'id'}
        onChange={formik.handleChange}
        value={formik?.values?.['PointOfSaleId'] || ''}
      />

      {renderInput({
        label: 'Dirección',
        type: 'text',
        name: 'Address',
        typedName: 'Address',
        placeholder: 'Dirección',
      })}

      {renderInput({
        label: 'Observaciones',
        type: 'text',
        name: 'Observations',
        typedName: 'Observations',
        placeholder: 'Observaciones',
      })}
    </>
  );
};

import { IRenderInput } from '../EditSamMenu';

interface ICauDeviceFormProps {
  formik: any;
  renderInput: (props: IRenderInput) => void;
  operatorsList: any[];
}

export const CauDeviceForm = (props: ICauDeviceFormProps) => {
  const { renderInput } = props;

  return (
    <>
      {renderInput({
        label: 'Id de equipo',
        type: 'text',
        name: 'NumberDevice',
        typedName: 'NumberDevice',
        placeholder: 'Operador',
        max:10
      })}

      {renderInput({
        label: 'Dirección',
        type: 'text',
        name: 'Address',
        typedName: 'Address',
        placeholder: 'Dirección',
      })}

      {renderInput({
        label: 'Observaciones',
        type: 'text',
        name: 'Observations',
        typedName: 'Observations',
        placeholder: 'Observaciones',
      })}
    </>
  );
};

import { Dropdown } from 'primereact/dropdown';
import React from 'react';

type DropdowsProps = {
  label: string;
  name?: string;
  className?: string;
  placeholder?: string;
  onChange?: any;
  options: any[];
  optionLabel: string;
  value?: any;
  disabled?: boolean;
  filter?: boolean;
  optionValue?: any;
};

export const DropdownComponent = (props: DropdowsProps) => {
  const {
    label,
    name,
    className,
    placeholder,
    onChange,
    options,
    optionLabel,
    value,
    disabled,
    optionValue,
    filter = false,
  } = props;
  let css = 'p-inputtext-sm border-1 ' + className;
  return (
    <div className="flex justify-content-center w-full flex-col mt-3">
      <p className="font-medium text-md">{label}</p>
      <Dropdown
        value={value}
        onChange={onChange}
        options={options}
        optionLabel={optionLabel}
        placeholder={placeholder}
        className={css}
        name={name}
        disabled={disabled}
        filter={filter}
        optionValue={optionValue}
      />
    </div>
  );
};

import * as React from 'react';
import { Route } from 'react-router-dom';

import { Catalogs } from '../pages/Catalogs';
import { Inventory } from '../pages/Inventary/Index';
import { Roles } from './../pages/Roles';
import { UserManager } from './../pages/UsersManager';
import { RedirectComponent } from './../pages/Redirect';
import { HistorySam } from 'src/pages/HistorySam/Index';
import { UserHistory } from 'src/pages/UserHistory/Index';
import { InventoryProvider } from '../serviceContextManager/inventory/InventoryServiceManager';
import { CatalogProvider } from '../serviceContextManager/catalog/CatalogServiceManager';
import { HistoryCard } from 'src/pages/HistoryCards/Index';
import { HistoryCardProvider } from '../serviceContextManager/historyCards/HistoryCardServiceManager';

interface IRouterProps {
  path: string;
  name: string;
  element: React.ReactNode;
  component?: JSX.Element;
}

const routerBrowser: IRouterProps[] = [
  { path: '/', name: 'inventario', element: (<InventoryProvider><Inventory /></InventoryProvider>) },
  { path: '/inventario', name: 'inventario', element: (<InventoryProvider><Inventory /></InventoryProvider>) },
  { path: '/history-sam', name: 'Historial de sam', element: <HistorySam /> },
  { path: '/history-cards', name: 'Historial de tarjetas', element: <HistoryCardProvider><HistoryCard /></HistoryCardProvider> },
  { path: '/catalogos', name: 'catalog', element: (<CatalogProvider><Catalogs /></CatalogProvider>) },
  { path: '/administrar-usuarios', name: 'usuarios', element: <UserManager /> },
  { path: '/history-user', name: 'historial de usuarios', element: <UserHistory /> },
  { path: '/roles', name: 'roles', element: <Roles /> },
  { path: '/denegado', name: 'denegado', element: <RedirectComponent /> },
];

const RouterConfig = () => {
  return (
    <>
      {routerBrowser.map(route => (
        <Route key={route.name} path={route.path} element={route.element} />
      ))}
    </>
  );
};

export default RouterConfig;

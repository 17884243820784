import {
  DELETE_ECONOMICNUMBERS_CATALOG,
  DELETE_ECONOMICNUMBERS_CATALOG_SUCCESS,
  DELETE_ECONOMICNUMBERS_CATALOG_ERROR,
} from '../../../../constants/constantsRed';

export const deleteEconomicNumbersCatalogRed = (
  state: any = false,
  actions: any
) => {
  switch (actions.type) {
    case DELETE_ECONOMICNUMBERS_CATALOG:
      return {
        ...state,
        loading: true,
      };
    case DELETE_ECONOMICNUMBERS_CATALOG_ERROR:
      return {
        data: null,
        loading: false,
        error: null,
      };
    case DELETE_ECONOMICNUMBERS_CATALOG_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: actions.payload,
      };
    default:
      return state;
  }
};

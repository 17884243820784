import { FunctionComponent, useEffect, useState } from 'react';

import Edit from '../../../../assets/icons/Edit';

interface ITitleMenu {
  name: string;
}

export const TitleMenu = (props: ITitleMenu) => {
  const [title, setTitle] = useState('');

  useEffect(() => {
    setTitle(props.name);
  });
  
  return (
    <div className="flex w-full h-full border flex-col content-center   justify-center items-center rounded-l-lg ">
      <div className="flex flex-row w-full h-[60%] content-center justify-start items-center">
        <div className="flex ml-10">
          <Edit />
          <p className="ml-2 place-self-center  font-medium text-gray-900">
            {title}
          </p>
        </div>
      </div>
      <div className="flex flex-row w-full h-[10%] bg-official_green"></div>
    </div>
  );
};

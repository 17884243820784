import { useFormik } from 'formik';
import { classNames } from 'primereact/utils';
import { useContext, useEffect, useState } from 'react';

import { StoreContext } from '../../../context/Provider';
import { PermitsList } from '../../../permitsList';
import {
  createObjectAsInterface,
  validatePermissions,
} from '../../../utils/utils';
import { BasicSelectComponent } from './../../../components/BasicSelectComponentComponent';
import { ButtonComponent } from './../../../components/ButtonComponen';
import { DropdownComponent } from './../../../components/DropdownComponen';
import { InputComponent } from './../../../components/InputComponent';
import { useJobPositionCatalog } from './../../../hooks/catalogs/useJobPositionCatalog';
import { useRoles } from './../../../hooks/usersManager/useRoles';
import { useUserManager } from './../../../hooks/usersManager/useUserManager';
import {
  IServiceMenuComponent,
  UserEditInterface,
  UserInterface,
} from './../../../interfaces/interfaceUserManager';
import { validationsCreateUser, validationsUpdateUser } from './utils';
import { IJobPosition } from '@/interfaces/interfaceCatalogs';

export const FormUser = (props: IServiceMenuComponent) => {
  const { dataRow, selectMenu, updateTable } = props;
  const context = useContext(StoreContext);
  const { getSettingsState } = context;
  const objAsAddUserInterface: UserInterface = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    phone: '',
    roleId: '',
    jobPositionId: null,
  };

  const objAsEditUserInterface: UserEditInterface = {
    userId: '',
    firstName: '',
    lastName: '',
    phone: '',
    jobPositionId: null,

  };

  const [inputs, setInputs] = useState<UserInterface>(objAsAddUserInterface);

  const [roles, setRoles] = useState<any>();
  const [jobPositions, setJobPositions] = useState<IJobPosition[]>([]);

  const [buttonLabel, setButtonLabel] = useState<string>('Agregar usuario');

  //Hooks

  const { getRoles } = useRoles();
  const { postUserManager, patchUserManager } = useUserManager();
  const { getjobPositionCatalog } = useJobPositionCatalog();

  useEffect(() => {
    const getData = async () => {
      let response = await getRoles();
      if (response.status === 200) {
        setRoles(response.data);
        if (dataRow !== undefined) {
          formik.setFieldValue('roleId', `${dataRow.roleId}`);
        }
      }

      response = await getjobPositionCatalog();
      if (response.status === 200) {
        setJobPositions(response.data);
      }
      //}
    };

    getData();
  }, []);

  const formik = useFormik({
    initialValues: inputs,
    enableReinitialize: true,
    validationSchema:
      selectMenu !== undefined ? validationsUpdateUser : validationsCreateUser,
    onSubmit: data => {
      data.roleId = data.roleId === '' ? undefined : data.roleId;
      handleSubmit(data);
    },
  });

  useEffect(() => {
    
    if (dataRow !== undefined) {
      setButtonLabel('Actualizar usuario');
      let objetToCreateInterface =
        selectMenu !== 'Actualizar'
          ? objAsEditUserInterface
          : objAsEditUserInterface;
      let obj = createObjectAsInterface(dataRow, objetToCreateInterface);
      setInputs(obj);
    }
  }, [dataRow]);

  const handleSubmit = async (values: any) => {
    let response: any = {};

    if (selectMenu === 'Actualizar') {
      let id = values.userId;
      delete values.userId;
      response = await patchUserManager(values, id);
      if (response.status === 200) {
        response.data['roleName'] = dataRow?.roleName;
      }
    } else {
      response = await postUserManager(values);
      if (response.status === 200) {
        response.data['roleName'] = response['data']['role']['name'];
      }
    }
    if (response.status === 200) {
      const position = jobPositions.find(item => item.id === response.data.jobPositionId);
      response.data.jobPosition = position?.name;
      updateTable(response.data);
    }
  };

  const isFormFieldValid = (
    name: 'firstName' | 'lastName' | 'email' | 'password' | 'roleId'
  ) => !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (
    name: 'firstName' | 'lastName' | 'email' | 'password' | 'roleId'
  ) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };

  useEffect(() => {
    let value = formik.values['phone'];

    if (value) {
      //Evita letras o caracteres
      const regex = /^\d*$/;
      if (!regex.test(value)) {
        formik.values['phone'] = value.slice(0, -1);
      }
    }
  }, [formik.values['phone']]);

  useEffect(() => {
    let value = formik.values['firstName'];
    if (value) {
      //Evitar caracteres especiales y numeros en el campo de representante legal
      const regex = /^[a-zA-ZáéíóúÁÉÍÓÚ\s]*$/;
      // Verificar si el nuevo valor coincide con la expresión regular
      if (!regex.test(value)) {
        formik.values['firstName'] = value.slice(0, -1);
      }
    }
  }, [formik.values['firstName']]);

  useEffect(() => {
    let value = formik.values['lastName'];
    if (value) {
      //Evitar caracteres especiales y numeros en el campo de representante legal
      const regex = /^[a-zA-ZáéíóúÁÉÍÓÚ\s]*$/;
      // Verificar si el nuevo valor coincide con la expresión regular
      if (!regex.test(value)) {
        formik.values['lastName'] = value.slice(0, -1);
      }
    }
  }, [formik.values['lastName']]);

  const renderMenu = (): any => {
    return (
      <form
        className="h-full w-full flex flex-col pt-5 px-6"
        onSubmit={formik.handleSubmit}>
        <div className="flex flex-col">
          <InputComponent
            label={'Nombre'}
            type={'firstName'}
            name={'firstName'}
            onChange={formik.handleChange}
            value={formik?.values?.['firstName'] || ''}
            placeholder={'Nombre'}
            className={classNames({
              'p-invalid': isFormFieldValid('firstName'),
            })}
          />
          {getFormErrorMessage('firstName')}
        </div>
        <div className="flex flex-col">
          <InputComponent
            label={'Apellido'}
            type={'lastName'}
            name={'lastName'}
            onChange={formik.handleChange}
            value={formik?.values?.['lastName'] || ''}
            placeholder={'Apellido'}
            className={classNames({
              'p-invalid': isFormFieldValid('lastName'),
            })}
          />
          {getFormErrorMessage('lastName')}
        </div>
        <div className="flex flex-col">
          <InputComponent
            label={'Teléfono'}
            type={'phone'}
            maxLength={10}
            name={'phone'}
            onChange={formik.handleChange}
            value={formik?.values?.['phone'] || ''}
            placeholder={'Teléfono'}
          />
        </div>
        {selectMenu !== 'Actualizar' ? (
          <>
            <div className="flex flex-col">
              <InputComponent
                label={'Correo'}
                type={'email'}
                name={'email'}
                onChange={formik.handleChange}
                value={formik?.values?.['email'] || ''}
                placeholder={'Correo'}
                className={classNames({
                  'p-invalid': isFormFieldValid('email'),
                })}
              />
              {getFormErrorMessage('email')}
            </div>
            <div className="flex flex-col">
              <InputComponent
                label={'Contraseña'}
                type={'password'}
                name={'password'}
                onChange={formik.handleChange}
                value={formik?.values?.['password'] || ''}
                placeholder={'Contraseña'}
                className={classNames({
                  'p-invalid': isFormFieldValid('password'),
                })}
              />
              {getFormErrorMessage('password')}
            </div>
            <div className="flex flex-col">
              <BasicSelectComponent
                label={'Rol'}
                options={roles}
                optionLabel={'roleName'}
                name="roleId"
                optionValue={'roleId'}
                onChange={formik.handleChange}
                value={formik?.values?.['roleId'] || ''}
              />
              {getFormErrorMessage('roleId')}
            </div>
          </>
        ) : validatePermissions(PermitsList.SEC_ROLE_EDIT) &&
          getSettingsState.data.userId !== dataRow?.userId ? (
          <div className="flex flex-col">
            <BasicSelectComponent
              label={'Rol'}
              options={roles}
              optionLabel={'roleName'}
              name="roleId"
              optionValue={'roleId'}
              onChange={formik.handleChange}
              value={formik?.values?.['roleId'] || ''}
            />
            {getFormErrorMessage('roleId')}
          </div>
        ) : (
          <></>
        )}
        <div className="flex flex-col">
          <DropdownComponent
            label="Puesto: "
            name="jobPositionId"
            options={jobPositions}
            optionLabel={'name'}
            optionValue={'id'}
            placeholder="Puesto"
            onChange={formik.handleChange}
            value={formik?.values?.['jobPositionId']}
            className=" h-12 !w-full"
          />
        </div>

        <div style={{ marginTop: '1rem' }}>
          <ButtonComponent className="mt-4" label={buttonLabel} />
        </div>
      </form>
    );
  };

  return (
    <div className="flex w-full h-full flex-col items-center bg-white  shadow-[-6px_-1px_5px_#00000024] z-10 right-0">
      <div className="flex w-full h-full overflow-y-auto">{renderMenu()}</div>
    </div>
  );
};

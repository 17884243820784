import { Filters } from "./Filters"
import { TableComponent } from '../../../components/Table';
import { Column } from "primereact/column";
import { IHistoryCardProvider } from "../../../interfaces/service/HistoryCardServiceManagerInterface";



export const TableHistoryCards = (props: IHistoryCardProvider) => {
    const headerTemplate = (data:any) => {
        return (
            <span className=" rounded-md p-3">
                <strong className="text-black">{data.groupKey}</strong>
            </span>
        );
    }

  const getColumnsToDisplayIntable = () => {
    let columns = props.defaultColumnsTable.map((column: any, index: any) => {
      const key = index.toString();
      return (
        <Column
          key={key}
          field={column.field}
          filterField={column.field}
          dataType={column.isFilter ? column.dataType : undefined}
          header={column.header}
          bodyClassName={'!text-black'}
          style={{
            flexGrow: 1,
            flexBasis: '100px',
          }}
          headerClassName="  !bg-tableHeader !text-withe!justify-between "
          sortable={column.sortable}
          filterMenuStyle={{ width: '14rem' }}
          filter={false}
          body={column.body}
        />
      );
    });

    return columns;
  }
  return (
    <>
      <div className="h-full flex flex-col w-full overflow-y-auto relative ">
        <TableComponent
          rowGroupHeaderTemplate={headerTemplate}
          value={props?.dataTable}
          groupRowsBy={"groupKey"}
          expandedRows={props.expandedRows}
          onRowToggle={(e:any)=> {props.setExpandedRows(e.data)}}
          expandableRowGroups={true}
          rowGroupMode={"subheader"}
          sortField="name"
          responsiveLayout="scroll"
          globalFilterFields={[]}
          resizableColumns
          sort={props.sort}
          setSort={props.setSort}
          reorderableColumns={true}
          OnEmptyMessage="No se encontraron registros disponibles."
        >
          {getColumnsToDisplayIntable()}
        </TableComponent>
      </div>
    </>)
}
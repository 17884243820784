import {
    GET_USER_HISTORY_CARDS,
    GET_USER_HISTORY_CARDS_SUCCESS,
    GET_USER_HISTORY_CARDS_ERROR,
  } from '../../../constants/constantsRed';
  
  export const getHistoryCard = (state: any = false, actions: any) => {
    switch (actions.type) {
      case GET_USER_HISTORY_CARDS:
        return {
          ...state,
          loading: true,
        };
      case GET_USER_HISTORY_CARDS_SUCCESS:
        return {
          data: actions.payload,
          loading: false,
          error: null,
        };
      case GET_USER_HISTORY_CARDS_ERROR:
        return {
          ...state,
          loading: false,
          error: null,
          data: actions.payload,
        };
      default:
        return state;
    }
  };
  
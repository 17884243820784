import { useContext } from 'react';

import { ToastComponent } from '../../components/ToastComponent';
import {
  GET_LOCATIONS_CATALOG,
  GET_LOCATIONS_CATALOG_SUCCESS,
  GET_LOCATIONS_CATALOG_ERROR,
  POST_LOCATIONS_CATALOG,
  POST_LOCATIONS_CATALOG_SUCCESS,
  POST_LOCATIONS_CATALOG_ERROR,
  PUT_LOCATIONS_CATALOG,
  PUT_LOCATIONS_CATALOG_SUCCESS,
  PUT_LOCATIONS_CATALOG_ERROR,
  DELETE_LOCATIONS_CATALOG,
  DELETE_LOCATIONS_CATALOG_SUCCESS,
  DELETE_LOCATIONS_CATALOG_ERROR,
} from '../../constants/constantsRed';
import { StoreContext } from '../../context/Provider';
import useApi from '../../services/request.service';

export const useLocationCatalogs = () => {
  const {
    getLocationCatalogState,
    postLocationsCatalogState,
    putLocationsCatalogState,
    deleteLocationsCatalogState
  } = useContext(StoreContext);

  const { genericApiConfiguration } = useApi();

  const getLocationCatalog = async () => {
    getLocationCatalogState.dispatch({ type: GET_LOCATIONS_CATALOG });
    try {
      const result: any = await genericApiConfiguration(
        '/locations',
        'GET',
        {},
        {},
        {}
      );
      if (result.status === 200) {
        const payload = result.data.message;
        getLocationCatalogState.dispatch({
          type: GET_LOCATIONS_CATALOG_SUCCESS,
          payload: payload,
        });
      }
      return result;
    } catch (error: any) {
      ToastComponent(
        'warning',
        'Error',
        'La operación falló, inténtelo más tarde por favor.'
      );
      getLocationCatalogState.dispatch({
        type: GET_LOCATIONS_CATALOG_ERROR,
        payload: error.message,
      });
      return 'error';
    }
  };

  const postLocationsCatalog = async (data: any) => {
    postLocationsCatalogState.dispatch({ type: POST_LOCATIONS_CATALOG });
    try {
      const result: any = await genericApiConfiguration(
        '/locations',
        'POST',
        data,
        {},
        {}
      );
      if (result.status === 200) {
        ToastComponent('success', '', 'Se ha añadido el registro correctamente');
        const payload = result.data.payload;
        postLocationsCatalogState.dispatch({
          type: POST_LOCATIONS_CATALOG_SUCCESS,
          payload: payload,
        });
      }
      return result;
    } catch (error: any) {
      postLocationsCatalogState.dispatch({
        type: POST_LOCATIONS_CATALOG_ERROR,
        payload: error.message,
      });
      return 'error';
    }
  };

  const putLocationsCatalog = async (data: any) => {
    putLocationsCatalogState.dispatch({ type: PUT_LOCATIONS_CATALOG });
    try {
      const result: any = await genericApiConfiguration(
        '/locations',
        'PUT',
        data,
        {},
        {}
      );
      if (result.status === 200) {
        ToastComponent('success', '', 'Se ha editado el registro correctamente');
        const payload = result.data.payload;
        putLocationsCatalogState.dispatch({
          type: PUT_LOCATIONS_CATALOG_SUCCESS,
          payload: payload,
        });
      }
      return result;
    } catch (error: any) {
      putLocationsCatalogState.dispatch({
        type: PUT_LOCATIONS_CATALOG_ERROR,
        payload: error.message,
      });
      return 'error';
    }
  };

  const deleteLocationsCatalog = async (id: string) => {
    deleteLocationsCatalogState.dispatch({ type: DELETE_LOCATIONS_CATALOG });
    try {
      const result: any = await genericApiConfiguration(
        '/locations/{id}',
        'DELETE',
        {},
        { ids: id },
        {}
      );

      if (result.status === 200) {
        ToastComponent(
          'success',
          'Succes',
          'Se eliminó correctamente el registro.'
        );
        const payload = result.data.payload;
        deleteLocationsCatalogState.dispatch({
          type: DELETE_LOCATIONS_CATALOG_SUCCESS,
          payload: payload,
        });
      }
      return result;
    } catch (error: any) {
      deleteLocationsCatalogState.dispatch({
        type: DELETE_LOCATIONS_CATALOG_ERROR,
        payload: error.message,
      });
      return 'error';
    }
  };

  return {
    getLocationCatalog,
    postLocationsCatalog,
    putLocationsCatalog,
    deleteLocationsCatalog,
  };
};

import { FunctionComponent, useContext, useEffect, useState } from 'react';

import { MainContainer } from '../../layout/components/MainContainer';
import { RolesTable } from './components/RolesTable';
import { RightMenu } from './components/RightMenu';
import { StoreContext } from '../../context/Provider';

export const Roles: FunctionComponent = () => {
  //States right menu
  const [openRightMenu, setOpenRightMenu] = useState(false);
  const [dataRow, setDataRow] = useState<any>(null);
  const [selectMenu, setSelectMenu] = useState<string>('');
  const [updateTable, setUpdateTable] = useState<any>(null);
  const context = useContext(StoreContext);
  const { postRoleState, getRolesState, patchRoleState, deleteRoleState } =
    context;

  const renderContent = () => {
    return (
      <div className="flex w-full h-full relative flex-row">
        <div className="flex grow h-full relative flex-col content-center items-center justify-center p-10 ">
          <RolesTable
            setOpenRightMenu={setOpenRightMenu}
            setDataRow={setDataRow}
            selectMenu={setSelectMenu}
            setUpdateTable={setUpdateTable}
          />
        </div>
      </div>
    );
  };

  const renderRightComponent = () => {
    return (
      <RightMenu
        dataRow={dataRow}
        selectMenu={selectMenu}
        setOpenRightMenu={setOpenRightMenu}
        updateTable={updateTable}
      />
    );
  };

  const loading =
    postRoleState.loading ||
    getRolesState.loading ||
    patchRoleState.loading ||
    deleteRoleState.loading;

  return (
    <MainContainer
      openRightMenu={openRightMenu}
      setOpenRightMenu={setOpenRightMenu}
      loading={loading}
      rightMenuContent={renderRightComponent}>
      {renderContent()}
    </MainContainer>
  );
};

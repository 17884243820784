import './App.css';

import { BrowserRouter, Route, Routes } from 'react-router-dom';

import MsalLogin from './authentication/MsalLogin';
import { LayoutContextProvider } from './context/LayoutContext';
import { StoreProvider } from './context/Provider';
import { MainLayout } from './layout/MainLayout';
import RouterConfig from './routes/RouterBrowser';

/* import '../src/styles/fonts.css'; */
/* import 'react-toastify/dist/ReactToastify.css'; */

//import { PrimeReactProvider } from 'primereact/api';
const App = () => {
  return (
    <>
      {/*  <PrimeReactProvider> */}
      <MsalLogin>
        <StoreProvider>
          <BrowserRouter>
            <LayoutContextProvider>
              <Routes>
                <Route path="/" element={<MainLayout />}>
                  {RouterConfig()}
                </Route>
              </Routes>
            </LayoutContextProvider>
          </BrowserRouter>
        </StoreProvider>
      </MsalLogin>
      {/* </PrimeReactProvider> */}
    </>
  );
};

export default App;

import { useContext } from 'react';

import { ToastComponent } from '../../components/ToastComponent';
import {
  GET_SAM_COUNTER_CATALOG,
  GET_SAM_COUNTER_CATALOG_SUCCESS,
  GET_SAM_COUNTER_CATALOG_ERROR,

  PATCH_SAM_COUNTER_CATALOG,
  PATCH_SAM_COUNTER_CATALOG_SUCCESS,
  PATCH_SAM_COUNTER_CATALOG_ERROR,

  POST_SAM_COUNTER_CATALOG,
  POST_SAM_COUNTER_CATALOG_SUCCESS,
  POST_SAM_COUNTER_CATALOG_ERROR,

  PUT_SAM_COUNTER_CATALOG,
  PUT_SAM_COUNTER_CATALOG_SUCCESS,
  PUT_SAM_COUNTER_CATALOG_ERROR,

  DELETE_SAM_COUNTER_CATALOG,
  DELETE_SAM_COUNTER_CATALOG_SUCCESS,
  DELETE_SAM_COUNTER_CATALOG_ERROR,

  GET_SAM_COUNTER_TYPES_CATALOG,
  GET_SAM_COUNTER_TYPES_CATALOG_SUCCESS,
  GET_SAM_COUNTER_TYPES_CATALOG_ERROR
} from '../../constants/catalog';
import { StoreContext } from '../../context/Provider';
import useApi from '../../services/request.service';
import { ISamCounterTypes } from '../../interfaces/interfaceCounterTypes';

export const useSamCounterCatalogs = () => {
  const context = useContext(StoreContext);
  const { catalogSamCounter, patchSamCounter } = useContext(StoreContext);
  const { genericApiCallApiBilling } = useApi();

  const getSamCounterCatalog = async () => {
    catalogSamCounter.dispatch({ type: GET_SAM_COUNTER_CATALOG });
    try {
      const result: any = await genericApiCallApiBilling(
        '/v1.0/sam-types',
        'GET',
        {},
        {},
        {}
      );
      if (result.status === 200) {
        const payload = result.data;
        catalogSamCounter.dispatch({
          type: GET_SAM_COUNTER_CATALOG_SUCCESS,
          payload: payload,
        });
      }
      return result;
    } catch (error: any) {
      ToastComponent(
        'warning',
        'Error',
        'La operación falló, inténtelo más tarde por favor.'
      );
      catalogSamCounter.dispatch({
        type: GET_SAM_COUNTER_CATALOG_ERROR,
        payload: error.message,
      });
      return 'error';
    }
  };

  const getSamCounterTypesCatalog = async () => {
    catalogSamCounter.dispatch({ type: GET_SAM_COUNTER_TYPES_CATALOG });
    try {
      const result: any = await genericApiCallApiBilling(
        '/v1.0/counters/counterTypes',
        'GET',
        {},
        {},
        {}
      );
      if (result.status === 200) {
        const payload = result.data.payload;
        catalogSamCounter.dispatch({
          type: GET_SAM_COUNTER_TYPES_CATALOG_SUCCESS,
          payload: payload,
        });
      }
      return result;
    } catch (error: any) {
      ToastComponent(
        'warning',
        'Error',
        'La operación falló, inténtelo más tarde por favor.'
      );
      catalogSamCounter.dispatch({
        type: GET_SAM_COUNTER_TYPES_CATALOG_ERROR,
        payload: error.message,
      });
      return 'error';
    }
  };

  const patchSamCounterCatalog = async (data: ISamCounterTypes[]) => {
    patchSamCounter.dispatch({ type: PATCH_SAM_COUNTER_CATALOG });
    try {
      const result: any = await genericApiCallApiBilling(
        '/v1.0/counters',
        'PATCH',
        data,
        {},
        {}
      );
      if (result.status === 200) {
        ToastComponent('success', '', 'Se ha editado el registro correctamente');
        const payload = result.data.payload;
        patchSamCounter.dispatch({
          type: PATCH_SAM_COUNTER_CATALOG_SUCCESS,
          payload: payload,
        });
      }
      return result;
    } catch (error: any) {
      ToastComponent(
        'warning',
        'Error',
        'La operación falló, inténtelo más tarde por favor.'
      );
      patchSamCounter.dispatch({
        type: PATCH_SAM_COUNTER_CATALOG_ERROR,
        payload: error.message,
      });
      return 'error';
    }
  };


  return {
    getSamCounterCatalog,
    getSamCounterTypesCatalog,
    patchSamCounterCatalog,
    // postStationsCatalog,
    // putStationsCatalog,
    // deleteStationsCatalog,
  };
};

import { InputText } from 'primereact/inputtext';
import React, { useState } from 'react';

type TextInputProps = {
  id?: any,
  label?: string | null;
  type?: string;
  name: string;
  value?: string;
  className?: string;
  placeholder?: string;
  onChange?: any;
  classSpan?: string;
  maxLength?: number;
  disabled?: boolean;
};

export const InputComponent = (props: TextInputProps) => {
  const {
    id,
    label,
    type,
    name,
    value,
    className,
    placeholder,
    onChange,
    classSpan,
    maxLength,
    disabled
  } = props;

  const classCss = className || 'p-inputtext-sm';
  return (
    <div className="flex w-full flex-col">
      <>
        {' '}
        {label !== undefined ? (
          <p className="font-medium text-md ">{label}</p>
        ) : (
          <></>
        )}{' '}
      </>

      <InputText
        id={id}
        type={type}
        maxLength={maxLength}
        name={name}
        value={value}
        placeholder={placeholder}
        className={`${classCss} !h-12`}
        onChange={onChange}
        disabled={disabled}
      />
    </div>
  );
};

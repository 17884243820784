import React from 'react';
import { Checkbox } from 'primereact/checkbox';

interface ICheckboxProps {
  checked: boolean;
  onChange?: any;
  disabled?: boolean;
}

export const CheckboxComponent = (props: ICheckboxProps) => {
  const { checked, onChange, disabled } = props;

  return (
    <div className="card flex justify-content-center">
      <Checkbox onChange={onChange} checked={checked} disabled={disabled} />
    </div>
  );
};

import { DropdownChangeEvent } from "primereact/dropdown";
import { DropdownComponent } from "../../../components/DropdownComponen";
import { IHistoryCardProvider } from "../../../interfaces/service/HistoryCardServiceManagerInterface";
import { Chart } from "primereact/chart";

export const ChartHistoryCards = (props: IHistoryCardProvider) => {
    return (
        <div className="h-full flex flex-col w-full">
            <div className="w-full h-auto flex flex-row space-x-3 ">
                <div className="!w-1/12 mt-auto">
                    <DropdownComponent
                    options={props.typeChart}
                    label='Tipo de gráfica: '
                    optionLabel={'label'}
                    placeholder="Tipo de grafica"
                    className=" h-12 !w-full"
                    value={props.typeChartSelected}
                    onChange={(e: DropdownChangeEvent) => props.setTypeChartSelected(e.value)}
                    />
                </div>

                <div className="!w-2/12 mt-auto">
                    <DropdownComponent
                    options={props.typeReport}
                    label='Tipo de reporte: '
                    optionLabel={'name'}
                    placeholder="Tipo de reporte"
                    className=" h-12 !w-full"
                    value={props.typeReportSelected}
                    onChange={(e: DropdownChangeEvent) => props.setTypeReportSelected(e.value)}
                    />
                </div>
                <div className="!w-full mt-auto flex justify-end">
                    <span className="text-2xl font-semibold  mr-5 bg-[--primary-color] pt-2 pb-2 pr-5 pl-5 rounded-full text-white">
                        Total {new Intl.NumberFormat('es-MX').format(props.totalCount ?? 0)}
                    </span>
                </div>
            </div>
            <div className="relative h-full w-full pb-5">
                <Chart 
                    type={props?.typeChartSelected?.type ?? "line"}
                    data={props?.dataChart ?? []} 
                    options={props?.typeChartSelected?.options ?? {}} 
                    className="!h-full !w-full"
                />
            </div>
        </div>
    );
};

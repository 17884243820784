import useApi from "../../services/request.service";
import { StoreContext } from "../../context/Provider";
import { useContext, useEffect } from "react";
import { GET_INVENTORY, GET_INVENTORY_ERROR, GET_INVENTORY_EXCEL, GET_INVENTORY_EXCEL_ERROR, GET_INVENTORY_EXCEL_SUCCESS, GET_INVENTORY_SUCCESS } from "../../constants/inventory";
import { ToastComponent } from "../../components/ToastComponent";

export const useInventoryService = () => {
  const { inventoryStateContext, inventoryToExcelStateContext } = useContext(StoreContext);
  const { genericApiCallApiBilling } = useApi();

  const getInventoryData = async (queryRequest: string = '') => {
    inventoryStateContext.dispatch({ type: GET_INVENTORY });

    try {
      const urlQuery: string = `/v1.0/sam-inventory?${queryRequest}`;
      const result: any = await genericApiCallApiBilling(urlQuery, 'GET', {}, {}, {})

      const payload = result.data;
      if (result.status === 200) {
        inventoryStateContext.dispatch({ type: GET_INVENTORY_SUCCESS, payload: payload });
      }
      return result;
    } catch (error: any) {
      ToastComponent(
        '',
        '',
        error.message,
        6000
      );
    }

  }

  function downloadCSV(data:any, filename = 'Inventario.csv') {
    const blob = new Blob(["\uFEFF" + data], { type: 'text/csv;charset=utf-8;' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }

  const inventoryExcel = async (queryRequest: string = '', columnsQueryParams: any) => {
    inventoryToExcelStateContext.dispatch({ type: GET_INVENTORY_EXCEL });
    try {
      const urlQuery = `/v1.0/sam-inventory/download?${queryRequest}&${columnsQueryParams}`
      const result: any = await genericApiCallApiBilling(urlQuery, 'GET', {}, {}, {});
      const payload = result.data;
      if (result.status === 200) {
        inventoryToExcelStateContext.dispatch({ type: GET_INVENTORY_EXCEL_SUCCESS, payload: payload })
        if (payload !== null && payload !== undefined) {
          downloadCSV(payload)
        } else if (payload === '') {
          ToastComponent(
            'warning',
            'Error',
            'No hay datos para excel.',
            6000
          );
        } else {
          ToastComponent(
            'warning',
            'Error',
            'Hay un problema en la solicitud.',
            6000
          );
        }
      }
    } catch (error: any) {
      ToastComponent(
        'warning',
        'Error',
        error.message,
        6000
      );
      inventoryToExcelStateContext.dispatch({ type: GET_INVENTORY_EXCEL_ERROR })
    }
  }

  // useEffect(() => {
  //   if (inventoryStateContext.state.data === null && inventoryStateContext.state.loading === false) {
  //     getInventoryData();
  //   }
  // }, [inventoryStateContext.state])


  return { getInventoryData, inventoryExcel };
}
import { FunctionComponent,  useContext,  useState } from 'react';

import { MainContainer } from '../../layout/components/MainContainer';
import { IHistoryCardProvider } from '../../interfaces/service/HistoryCardServiceManagerInterface';
import { HistoryCardContextSM } from '../../serviceContextManager/historyCards/HistoryCardServiceManager';
import { TableHistoryCards } from './components/TableHistoryCard';
import { Filters } from './components/Filters';
import { ChartHistoryCards } from './components/ChartHistoryCard';

export const HistoryCard: FunctionComponent = () => {

  const serviceInvetory = useContext<IHistoryCardProvider>(HistoryCardContextSM);
  const [openRightMenu, setOpenRightMenu] = useState(false);

  const renderContent = () => {
    return (
      <div className="flex w-full h-full relative flex-row ">
        <div className="flex row h-full  flex-col content-center p-2 w-full">
            <div className='flex h-auto w-full'>
            <Filters  {...serviceInvetory} />

            </div>
            <div className='flex !h-full'>
            {
              !serviceInvetory.showChart?
              <TableHistoryCards {...serviceInvetory}/> : 
              <ChartHistoryCards {...serviceInvetory}/>
            }
            </div>
        </div>
      </div>
    );
  };    

  const loading = false;

  return (
    <MainContainer
      openRightMenu={openRightMenu}
      setOpenRightMenu={setOpenRightMenu}
      loading={loading}
      rightMenuContent={<></>}>
      {renderContent()}
    </MainContainer>
  );
};

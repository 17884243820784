import { useContext } from 'react';

import { ToastComponent } from '../../components/ToastComponent';
import {
  GET_POS_CATALOG,
  GET_POS_CATALOG_SUCCESS,
  GET_POS_CATALOG_ERROR,
  POST_POS_CATALOG,
  POST_POS_CATALOG_SUCCESS,
  POST_POS_CATALOG_ERROR,
  PUT_POS_CATALOG,
  PUT_POS_CATALOG_SUCCESS,
  PUT_POS_CATALOG_ERROR,
  DELETE_POS_CATALOG,
  DELETE_POS_CATALOG_SUCCESS,
  DELETE_POS_CATALOG_ERROR,
} from '../../constants/constantsRed';
import { StoreContext } from '../../context/Provider';
import useApi from '../../services/request.service';

export const usePointsOfSalesCatalog = () => {
  const {
    getPointsOfSalesCatalogState,
    postPosCatalogState,
    putPosCatalogState,
    deletePosCatalogState
  } = useContext(StoreContext);
  const { genericApiConfiguration } = useApi();

  const getPointsOfSalesCatalog = async () => {
    getPointsOfSalesCatalogState.dispatch({ type: GET_POS_CATALOG });
    try {
      const result: any = await genericApiConfiguration(
        '/pos',
        'GET',
        {},
        {},
        {}
      );
      if (result.status === 200) {
        const payload = result.data.message;
        getPointsOfSalesCatalogState.dispatch({
          type: GET_POS_CATALOG_SUCCESS,
          payload: payload,
        });
      }
      return result;
    } catch (error: any) {
      ToastComponent(
        'warning',
        'Error',
        'La operación falló, inténtelo más tarde por favor.'
      );
      getPointsOfSalesCatalogState.dispatch({
        type: GET_POS_CATALOG_ERROR,
        payload: error.message,
      });
      return 'error';
    }
  };

  const postPointsOfSalesCatalog = async (data: any) => {
    postPosCatalogState.dispatch({ type: POST_POS_CATALOG });
    try {
      const result: any = await genericApiConfiguration(
        '/pos',
        'POST',
        data,
        {},
        {}
      );
      if (result.status === 200) {
        const payload = result.data.payload;
        postPosCatalogState.dispatch({
          type: POST_POS_CATALOG_SUCCESS,
          payload: payload,
        });
        ToastComponent(
          'success',
          '',
          'Se ha añadido el registro correctamente'
        );
      }
      return result;
    } catch (error: any) {
      postPosCatalogState.dispatch({
        type: POST_POS_CATALOG_ERROR,
        payload: error.message,
      });
      return 'error';
    }
  };

  const putPointsOfSalesCatalog = async (data: any) => {
    ToastComponent(
      'success',
      '',
      'Se ha editado el registro correctamente'
    );
    putPosCatalogState.dispatch({ type: PUT_POS_CATALOG });
    try {
      const result: any = await genericApiConfiguration(
        '/pos',
        'PUT',
        data,
        {},
        {}
      );
      if (result.status === 200) {
        const payload = result.data.payload;
        putPosCatalogState.dispatch({
          type: PUT_POS_CATALOG_SUCCESS,
          payload: payload,
        });
      }
      return result;
    } catch (error: any) {
      putPosCatalogState.dispatch({
        type: PUT_POS_CATALOG_ERROR,
        payload: error.message,
      });
      return 'error';
    }
  };

  const deletePointsOfSalesCatalog = async (id: string) => {
    deletePosCatalogState.dispatch({ type: DELETE_POS_CATALOG });
    try {
      const result: any = await genericApiConfiguration(
        '/pos/{id}',
        'DELETE',
        {},
        { ids: id },
        {}
      );

      if (result.status === 200) {
        ToastComponent(
          'success',
          'Succes',
          'Se eliminó correctamente el registro.'
        );
        const payload = result.data.payload;
        deletePosCatalogState.dispatch({
          type: DELETE_POS_CATALOG_SUCCESS,
          payload: payload,
        });
      }
      return result;
    } catch (error: any) {
      deletePosCatalogState.dispatch({
        type: DELETE_POS_CATALOG_ERROR,
        payload: error.message,
      });
      return 'error';
    }
  };

  return {
    getPointsOfSalesCatalog,
    postPointsOfSalesCatalog,
    putPointsOfSalesCatalog,
    deletePointsOfSalesCatalog,
  };
};

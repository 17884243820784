import useApi from "../../services/request.service";
import { StoreContext } from "../../context/Provider";
import {
  GET_CONFIGURATION_TRIGGER,
  GET_CONFIGURATION_TRIGGER_SUCCESS,
  GET_CONFIGURATION_TRIGGER_ERROR,
  POST_CONFIGURATION_TRIGGER,
  POST_CONFIGURATION_TRIGGER_SUCCESS,
  POST_CONFIGURATION_TRIGGER_ERROR
} from "../../constants/constantsRed";
import { useContext, useEffect } from "react";
import { ToastComponent } from "../../components/ToastComponent";
import { getCurrentPosition } from "../../utils/utils";

export const useConfigurationTriggerService = () => {
  const { getConfigurationTriggerState, postConfigurationTriggerState, } = useContext(StoreContext);
  const { genericApiConfiguration, genericApiCallApiBilling } = useApi();

  const getConfigurationTrigger = async (samId: number) => {
    getConfigurationTriggerState.dispatch({ type: GET_CONFIGURATION_TRIGGER });
    try {
      const urlQuery = `/v1.0/sam-inventory/${samId}/triggers`; 
      const result: any = await genericApiCallApiBilling(urlQuery, 'GET', {}, {}, {});
      const payload = result.data;
      if (result.status === 200) {
        getConfigurationTriggerState.dispatch({ type: GET_CONFIGURATION_TRIGGER_SUCCESS, payload: payload });
      }
      return result;
    } catch (error) {
        getConfigurationTriggerState.dispatch({ type: GET_CONFIGURATION_TRIGGER_ERROR });
    }
  };

  const postConfigurationTrigger = async (data: any) => {
    postConfigurationTriggerState.dispatch({ type: POST_CONFIGURATION_TRIGGER });
    try {
      let coords = await getCurrentPosition();
      const headers = {
        locationGPS: `${coords.latitude}, ${coords.latitude}`,
        appId: 'web',
      };
      const result: any = await genericApiCallApiBilling(
        '/v1.0/device-triggers',
        'POST',
        data,
        {},
        headers
      );
      if (result.status === 200) {
        const payload = result.data;
        postConfigurationTriggerState.dispatch({
          type: POST_CONFIGURATION_TRIGGER_SUCCESS,
          payload: payload,
        });
        ToastComponent('success', '', 'Parámetros de comunicación agregados correctamente');
      }else{
        const message = result?.response?.data?.message;
        ToastComponent('warning', '', message);
        postConfigurationTriggerState.dispatch({
          type: POST_CONFIGURATION_TRIGGER_ERROR,
          payload: result?.response?.data
        });
      }
      return result;
    } catch (error: any) {
      ToastComponent(
        'warning',
        'Error',
        'La operación falló, inetnet más tarde por favor.'
      );
      postConfigurationTriggerState.dispatch({
        type: POST_CONFIGURATION_TRIGGER_ERROR,
        payload: error.message,
      });
      return 'error';
    }
  };


  return { getConfigurationTrigger, postConfigurationTrigger };
};
import { InputSwitch, InputSwitchChangeEvent } from 'primereact/inputswitch';

interface RadioButtonProps {
  checked?: any;
  label?: string;
  onChange: any;
  className?: string;
}

export const SwitchComponent = (props: RadioButtonProps) => {
  const { checked, label, onChange, className } = props;
  return (
    <div className="flex flex-col justify-content-center mb-3">
      <label htmlFor={label} className="font-medium text-md mb-2 mt-3">
        {label}
      </label>
      <InputSwitch
        id="value"
        name="value"
        checked={checked}
        onChange={onChange}
        className={className}
      />
    </div>
  );
};

import useApi from "../../services/request.service";
import { StoreContext } from "../../context/Provider";
import {
  GET_DEVICETYPES_CATALOG,
  GET_DEVICETYPES_CATALOG_SUCCESS,
  GET_DEVICETYPES_CATALOG_ERROR,
  GET_OPERATORS_CATALOG,
  GET_OPERATORS_CATALOG_SUCCESS,
  GET_OPERATORS_CATALOG_ERROR,
  GET_PROJECTS_CATALOG,
  GET_PROJECTS_CATALOG_SUCCESS,
  GET_PROJECTS_CATALOG_ERROR,
} from "../../constants/constantsRed";
import { useContext, useEffect } from "react";

export const useFiltersService = () => {
  const { getCatalogProjectsState, getCatalogDeviceState, getCatalogOperatorsState } = useContext(StoreContext);
  const { genericApiConfiguration } = useApi();

  const getDevices = async () => {
    getCatalogDeviceState.dispatch({ type: GET_DEVICETYPES_CATALOG });
    try {
      const urlQuery = '/device-types';
      const result: any = await genericApiConfiguration(urlQuery, 'GET', {}, {}, {});
      const payload = result.data;
      if (result.status === 200) {
        getCatalogDeviceState.dispatch({ type: GET_DEVICETYPES_CATALOG_SUCCESS, payload: payload.message });
      }
      return result;
    } catch (error) {
      getCatalogDeviceState.dispatch({ type: GET_DEVICETYPES_CATALOG_ERROR });
    }
  };

  const getOperators = async () => {
    getCatalogOperatorsState.dispatch({ type: GET_OPERATORS_CATALOG });
    try {
      const urlQuery: string = '/operators';
      const result: any = await genericApiConfiguration(urlQuery, 'GET', {}, {}, {});
      const payload = result.data;
      if (result.status === 200) {
        getCatalogOperatorsState.dispatch({ type: GET_OPERATORS_CATALOG_SUCCESS, payload: payload.message });
      }
      return result;
    } catch (error) {
      getCatalogOperatorsState.dispatch({ type: GET_OPERATORS_CATALOG_ERROR });
    }
  };

  const getProjects = async () => {
    getCatalogProjectsState.dispatch({ type: GET_PROJECTS_CATALOG });
    try {
      const urlQuery: string = '/projects';
      const result: any = await genericApiConfiguration(urlQuery, 'GET', {}, {}, {});
      const payload = result.data;
      if (result.status === 200) {
        getCatalogProjectsState.dispatch({ type: GET_PROJECTS_CATALOG_SUCCESS, payload: payload.message });
      }
      return result;
    } catch (error) {
      getCatalogProjectsState.dispatch({ type: GET_PROJECTS_CATALOG_ERROR });
    }
  };

  const fetchAllCatalogs = async () => {
    try {
      await Promise.all([getDevices(), getOperators(), getProjects()]);
    } catch (error) {
      console.error("Error fetching catalogs", error);
    }
  };

  useEffect(() => {
    if (
      !getCatalogDeviceState.state.loading &&
      !getCatalogProjectsState.state.loading &&
      !getCatalogOperatorsState.state.loading &&
      getCatalogDeviceState.state.data === null &&
      getCatalogProjectsState.state.data === null &&
      getCatalogOperatorsState.state.data === null
    ) {
      fetchAllCatalogs();
    }
  }, [
    getCatalogDeviceState.state,
    getCatalogProjectsState.state,
    getCatalogOperatorsState.state,
  ]);

  return { getDevices, getOperators, getProjects };
};
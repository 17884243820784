const ua = window.navigator.userAgent;
const msie = ua.indexOf('MSIE ');
const msie11 = ua.indexOf('Trident/');
const msedge = ua.indexOf('Edge/');
const isIE = msie > 0 || msie11 > 0 || msedge > 0;

export const loginRequest = {
  scopes: ['openid', 'offline_access'],
};

export const msalConfig = {
  auth: {
    clientId: '',
    tenantId: '',
    authority: '',
    redirectUri: '',
    postLogoutRedirectUri: '',
    knownAuthorities: [''],
    navigateToLoginRequestUrl: true,
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: isIE,
  },
  system: {
    windowHashTimeout: 60000,
    iframeHashTimeout: 6000,
    loadFrameTimeout: 0,
  },
};

export const TOKEN_STORAGE = 'beasaastk';
export const TOKEN_EXP_STORAGE = 'beasaastk_exp';

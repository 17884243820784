import React, { useEffect, useState, FC, ReactNode } from 'react';
import { useMsal, useAccount, AuthenticatedTemplate } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';
import { loginRequest } from './msalConstants';
import { ProgressSpinner } from 'primereact/progressspinner';

const MsalLogin: FC<{ children: ReactNode }> = ({ children }) => {
  const { instance, inProgress } = useMsal();
  const [isAuthenticated, setisAuthenticated] = useState(false);

  useEffect(() => {
    if (inProgress === InteractionStatus.None && !isAuthenticated) {
      const account = instance.getActiveAccount();
      if (account) {
        setisAuthenticated(true);
      } else {
        try {
          console.error('llama a login:', inProgress, isAuthenticated, account);
          instance.loginRedirect({
            ...loginRequest,
            prompt: 'select_account',
          });
        } catch (error) {
          console.error('Error loginredirect:', error);
        }
      }
    }
  }, [inProgress, isAuthenticated]); // Solo ejecuta el efecto cuando inProgress cambia y isAuthenticated cambia

  return isAuthenticated ? (
    <AuthenticatedTemplate>{children}</AuthenticatedTemplate>
  ) : (
    <div className="flex h-screen w-full justify-center content-center items-center flex-col">
      <ProgressSpinner
        style={{ width: '50px', height: '50px' }}
        className="flex w-[50px] h-[50px]"
        strokeWidth="8"
        fill="var(--surface-ground)"
        animationDuration=".5s"
      />
      <h1>Autenticando...</h1>
    </div>
  );
};

export default MsalLogin;

import { FunctionComponent, useContext, useEffect, useState } from 'react';

import { MainContainer } from '../../layout/components/MainContainer';
import { InventaryTable } from './components/InventoryTable';
import { RightMenu } from './components/rightMenu/RightMenu';
import { InventoryContextSM } from '../../serviceContextManager/inventory/InventoryServiceManager';
import { IInventoryProvider } from '../../interfaces/service/InventoryServiceManagerInterface';
import { RechargeCouterModal } from './components/rechargeCouterModal';
import { ModifyDisableSam } from './components/DisableSam';
import { ModifyWhiteListSam } from './components/ModifyWhiteListSam';
import { RechargesCounterHistoryModal } from './components/RechargesCounterHistoryModal';


export const Inventory: FunctionComponent = () => {
  const serviceInvetory = useContext<IInventoryProvider>(InventoryContextSM);

  const renderContent = () => {
    return (
      <div className="flex w-full h-full relative flex-row overflow-hidden">
        <InventaryTable
          serviceInvetory={serviceInvetory}
        />
        <RechargeCouterModal
          serviceInvetory={serviceInvetory}
        />
        <ModifyWhiteListSam
          serviceInvetory={serviceInvetory}
        />
        <ModifyDisableSam
          serviceInvetory={serviceInvetory}
        />
        <RechargesCounterHistoryModal
          serviceInvetory={serviceInvetory}
        />
      </div>
    );
  };

  const renderRightComponent = () => {
    return (
      <RightMenu
        dataRow={serviceInvetory.dataRow}
        selectMenu={serviceInvetory.selectMenu}
        setOpenRightMenu={serviceInvetory.setOpenRightMenu}
        updateTable={serviceInvetory.updateInventoryTable}
      />
    );
  };


  return (
    <MainContainer
      openRightMenu={serviceInvetory.openRightMenu}
      setOpenRightMenu={serviceInvetory.setOpenRightMenu}
      loading={false}
      rightMenuContent={renderRightComponent}>
      {renderContent()}
    </MainContainer>
  );
};

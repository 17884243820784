import { FormikErrors, FormikTouched, FormikValues } from 'formik';
import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { authService } from '../../authentication/authService';
import { StoreContext } from '../../context/Provider';
import { useUserManager } from '../../hooks/usersManager/useUserManager';
import { IHeaderProvidesProps } from '../../interfaces/interfaceHeader';
import { IUserData } from '../../interfaces/interfaceUserMenu';
import { validationsPersonalData } from '../../layout/components/Header/validationsPersonalData';
import useCustomFormik from '../../pages/Catalogs/components/forms/utils/formik_catalog_Config';
import { validateUserWebPermission } from '../../utils/utils';
import { useSettings } from './../../hooks/settings/useSettings';

export interface IHeaderServiceManager {
  userMenuRef: any;
  showPopover: boolean;
  userButtonRef: any;
  menuSelected: string;
  showProfileModal: boolean;
  user: IUserData;
  patchUserManagerState: any;

  setMenuSelected: Dispatch<SetStateAction<string>>;
  setShowPopover: (any: boolean) => void;
  closePersonalModal: () => void;

  formikPersonalData: any;
  openProfileModal: (any?: any) => void;
  logout: (any?: any) => void;
  isFormFieldValid: (
    name: string,
    errors: FormikErrors<FormikValues>,
    touched: FormikTouched<FormikValues>
  ) => boolean;
  getFormErrorMessage: (
    name: string,
    errors: FormikErrors<FormikValues>,
    touched: FormikTouched<FormikValues>
  ) => React.ReactNode;
}

export const HeaderServiceManager = createContext<IHeaderServiceManager>(
  {} as IHeaderServiceManager
);

export const HeaderProvider = (props: IHeaderProvidesProps) => {
  const { userMenuRef, userButtonRef, showPopover, setShowPopover } = props;

  const [menuSelected, setMenuSelected] = useState<string>('');
  const [showProfileModal, setShowProfileModal] = useState<boolean>(false);
  const [inputs, setInputs] = useState<any>();

  const { getPersonalData } = useSettings();
  const { patchUserAndUpdateUserLogged } = useUserManager();
  const navigate = useNavigate();
  const context = useContext(StoreContext);
  const { getSettingsState, patchUserManagerState } = context;

  const [user, setUser] = useState<IUserData>({
    userName: '',
    firstName: '',
    surName: '',
    lastName: '',
    email: '',
    rol: '',
    roleName: '',
    phone: '',
    modules: [],
  });

  useEffect(() => {
    let path: string;
    window.location.pathname === '/'
      ? (path = '/inventario')
      : (path = window.location.pathname);

    setMenuSelected(path);
    getPersonalData();
  }, []);

  useEffect(() => {
    if (
      getSettingsState?.data !== undefined &&
      getSettingsState?.data !== null
    ) {
      const data = getSettingsState?.data;
      setUser(data);
      setInputs(data);
      localStorage.setItem('permitions_user', data['permissionValue']);
      localStorage.setItem('userId', data['userId']);
      const validateWebUser = validateUserWebPermission();

      if (validateWebUser === false && !data.allowViewHistorySam) {
        navigate('/denegado');
        return;
      }
      if (validateWebUser === false && data.allowViewHistorySam) {
        navigate('/history-sam');
      }
      if (validateWebUser === true) {
        navigate('/');
      }
    }
  }, [getSettingsState?.data]);

  const formikPersonalData = useCustomFormik(
    inputs,
    validationsPersonalData,
    handleSubmitForm
  );

  function isFormFieldValid(
    name: string,
    errors: FormikErrors<FormikValues>,
    touched: FormikTouched<FormikValues>
  ) {
    return !!(touched[name] && errors[name]);
  }

  function getFormErrorMessage(
    name: string,
    errors: FormikErrors<FormikValues>,
    touched: FormikTouched<FormikValues>
  ) {
    const error = errors[name];
    const errorMessage = typeof error === 'string' ? error : '';
    return (
      isFormFieldValid(name, errors, touched) && (
        <small className="p-error">{errorMessage}</small>
      )
    );
  }

  useEffect(() => {
    const validateAndSanitizeField = (fieldName: string) => {
      const fieldValue = formikPersonalData?.values?.[fieldName] || '';
      const onlyText = /^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s]*$/;

      if (!onlyText.test(fieldValue)) {
        const sanitizedValue = fieldValue.replace(
          /[^a-zA-ZáéíóúÁÉÍÓÚñÑ\s]/g,
          ''
        );
        formikPersonalData.setFieldValue(fieldName, sanitizedValue);
      }
    };

    validateAndSanitizeField('firstName');
    validateAndSanitizeField('lastName');
  }, [
    formikPersonalData?.values?.['firstName'],
    formikPersonalData?.values?.['lastName'],
  ]);

  async function handleSubmitForm(values: any) {
    const userObj = {
      firstName: values['firstName'],
      jobPositionId: values['jobPositionId'],
      lastName: values['lastName'],
      phone: values['phone'].toString(),
      roleId: values['roleId'].toString(),
    };

    const response = await patchUserAndUpdateUserLogged(
      userObj,
      values['userId']
    );
    if (response.status === 200) {
      setShowProfileModal(false);
    }
  }

  function closePersonalModal() {
    formikPersonalData.resetForm();
    setShowProfileModal(false);
  }

  function logout() {
    authService.logout();
  }

  function openProfileModal() {
    setShowProfileModal(true);
  }

  return (
    <HeaderServiceManager.Provider
      value={{
        userMenuRef,
        showPopover,
        userButtonRef,
        menuSelected,
        showProfileModal,
        user,
        patchUserManagerState,

        setMenuSelected,
        setShowPopover,
        closePersonalModal,

        formikPersonalData,
        openProfileModal,
        logout,
        isFormFieldValid,
        getFormErrorMessage,
      }}>
      {props.children}
    </HeaderServiceManager.Provider>
  );
};

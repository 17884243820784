import { FunctionComponent, useContext, useEffect, useState } from 'react';
import { TitleMenu } from './../../Inventary/components/rightMenu/TitleMenu';
import { FormRole } from './FormRole';

interface IServiceMenuComponent {
  dataRow?: any;
  selectMenu?: string;
  setOpenRightMenu: any;
  updateTable: any;
}

export const RightMenu = (props: IServiceMenuComponent) => {
  const { dataRow, selectMenu, setOpenRightMenu, updateTable } = props;
  const [menu, setMenu] = useState('');

  useEffect(() => {
    setMenu(selectMenu || '');
  });

  /**
   *Choose menu to show
   */
  const renderMenu = (): any => {
    switch (menu) {
      case 'Agregar':
        return <FormRole updateTable={updateTable} />;

      case 'Actualizar':
        return <FormRole updateTable={updateTable} dataRow={dataRow} selectMenu={menu} />;

      default:
        return false;
    }
  };

  return (
    <div className="flex w-full h-full flex-col items-center bg-white  shadow-[-6px_-1px_5px_#00000024] z-10 right-0">
      <div className="flex w-full h-[10%] ">
        <TitleMenu name={menu} />
      </div>
      <div className="flex w-full h-[85%] overflow-y-auto">{renderMenu()}</div>
    </div>
  );
};

import {
  POST_LOCATIONS_CATALOG,
  POST_LOCATIONS_CATALOG_SUCCESS,
  POST_LOCATIONS_CATALOG_ERROR,
} from '../../../../constants/constantsRed';

export const postLocationsCatalogRed = (state: any = false, actions: any) => {
  switch (actions.type) {
    case POST_LOCATIONS_CATALOG:
      return {
        ...state,
        loading: true,
      };
    case POST_LOCATIONS_CATALOG_ERROR:
      return {
        data: null,
        loading: false,
        error: null,
      };
    case POST_LOCATIONS_CATALOG_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: actions.payload,
      };
    default:
      return state;
  }
};

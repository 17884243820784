import {
  GET_SAM_BY_ID,
  GET_SAM_BY_ID_ERROR,
  GET_SAM_BY_ID_SUCCESS,
} from '../../../constants/constantsRed';

export const getSamByIdRed = (state: any = false, actions: any) => {
  switch (actions.type) {
    case GET_SAM_BY_ID:
      return {
        ...state,
        loading: true,
      };
    case GET_SAM_BY_ID_ERROR:
      return {
        data: null,
        loading: false,
        error: null,
      };
    case GET_SAM_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: actions.payload,
      };
    default:
      return state;
  }
};

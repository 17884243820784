import { useContext } from 'react';

import { ToastComponent } from '../../components/ToastComponent';
import {
  GET_OPERATORS_CATALOG,
  GET_OPERATORS_CATALOG_SUCCESS,
  GET_OPERATORS_CATALOG_ERROR,
  POST_OPERATORS_CATALOG,
  POST_OPERATORS_CATALOG_SUCCESS,
  POST_OPERATORS_CATALOG_ERROR,
  PUT_OPERATORS_CATALOG,
  PUT_OPERATORS_CATALOG_SUCCESS,
  PUT_OPERATORS_CATALOG_ERROR,
  DELETE_OPERATORS_CATALOG,
  DELETE_OPERATORS_CATALOG_SUCCESS,
  DELETE_OPERATORS_CATALOG_ERROR,
} from '../../constants/constantsRed';
import { StoreContext } from '../../context/Provider';
import useApi from '../../services/request.service';

export const useOperatorsCatalogs = () => {
  const {
    getOperatorsCatalogState,
    postOperatorsCatalogState,
    putOperatorsCatalogState,
    deleteOperatorsCatalogState
  } = useContext(StoreContext);
  const { genericApiConfiguration } = useApi();

  const getOperatorsCatalog = async () => {
    getOperatorsCatalogState.dispatch({ type: GET_OPERATORS_CATALOG });
    try {
      const result: any = await genericApiConfiguration(
        '/operators',
        'GET',
        {},
        {},
        {}
      );
      if (result.status === 200) {
        const payload = result.data.message;
        getOperatorsCatalogState.dispatch({
          type: GET_OPERATORS_CATALOG_SUCCESS,
          payload: payload,
        });
      }
      return result;
    } catch (error: any) {
      ToastComponent(
        'warning',
        'Error',
        'La operación falló, inténtelo más tarde por favor.'
      );
      getOperatorsCatalogState.dispatch({
        type: GET_OPERATORS_CATALOG_ERROR,
        payload: error.message,
      });
      return 'error';
    }
  };

  const postOperatorsCatalog = async (data: any) => {
    postOperatorsCatalogState.dispatch({ type: POST_OPERATORS_CATALOG });
    try {
      const result: any = await genericApiConfiguration(
        '/operators',
        'POST',
        data,
        {},
        {}
      );
      if (result.status === 200) {
        ToastComponent('success', '', 'Se ha añadido el registro correctamente');
        const payload = result.data.message;
        postOperatorsCatalogState.dispatch({
          type: POST_OPERATORS_CATALOG_SUCCESS,
          payload: payload,
        });
      }
      return result;
    } catch (error: any) {
      postOperatorsCatalogState.dispatch({
        type: POST_OPERATORS_CATALOG_ERROR,
        payload: error.message,
      });
      return 'error';
    }
  };

  const putOperatorsCatalog = async (data: any) => {
    putOperatorsCatalogState.dispatch({ type: PUT_OPERATORS_CATALOG });
    try {
      const result: any = await genericApiConfiguration(
        '/operators',
        'PUT',
        data,
        {},
        {}
      );
      if (result.status === 200) {
        ToastComponent('success', '', 'Se ha editado el registro correctamente');
        const payload = result.data.payload;
        putOperatorsCatalogState.dispatch({
          type: PUT_OPERATORS_CATALOG_SUCCESS,
          payload: payload,
        });
      }
      return result;
    } catch (error: any) {
      putOperatorsCatalogState.dispatch({
        type: PUT_OPERATORS_CATALOG_ERROR,
        payload: error.message,
      });
      return 'error';
    }
  };

  const deleteOperatorsCatalog = async (id: string) => {
    deleteOperatorsCatalogState.dispatch({ type: DELETE_OPERATORS_CATALOG });
    try {
      const result: any = await genericApiConfiguration(
        '/operators/{id}',
        'DELETE',
        {},
        { ids: id },
        {}
      );

      if (result.status === 200) {
        ToastComponent(
          'success',
          'Succes',
          'Se eliminó correctamente el registro.'
        );
        const payload = result.data.payload;
        deleteOperatorsCatalogState.dispatch({
          type: DELETE_OPERATORS_CATALOG_SUCCESS,
          payload: payload,
        });
      }
      return result;
    } catch (error: any) {
      deleteOperatorsCatalogState.dispatch({
        type: DELETE_OPERATORS_CATALOG_ERROR,
        payload: error.message,
      });
      return 'error';
    }
  };

  return {
    getOperatorsCatalog,
    postOperatorsCatalog,
    putOperatorsCatalog,
    deleteOperatorsCatalog,
  };
};

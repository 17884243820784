import { Tooltip } from 'primereact/tooltip';
import { LuAlertCircle } from 'react-icons/lu';

export const getColumnsInFilter = (openAddContadortes: any) => {

  const rechargesCounterInventory = (row: any) => {
    return row.AmountTransactionAdditional !== null ? (
      <div
        className="flex w-full text-base"
        onClick={() => openAddContadortes(row)}>
        <p>{row.AmountTransactionAdditional}</p>
        <>
          <Tooltip target=".custom-target-icon" />
          <div
            className="custom-target-icon"
            data-pr-tooltip={'Historial de contadores'}
            data-pr-position="top">
            <LuAlertCircle className="text-sm text-ligth_blue cursor-pointer ml-2" />
          </div>
        </>
      </div>
    ) : (
      <></>
    );
  };

  const columnsInFilter: Array<any> = [
    {
      field: 'Project',
      header: 'Proyecto',
      sortable: true,
      isFilter: true,
      dataType: undefined,
    },
    {
      field: 'Station',
      header: 'Estación',
      sortable: true,
      isFilter: true,
      dataType: undefined,
    },
    {
      field: 'RouteName',
      header: 'Ruta',
      sortable: true,
      isFilter: true,
      dataType: undefined,
    },
    {
      field: 'RouteNumber',
      header: 'Número de ruta',
      sortable: true,
      isFilter: true,
      dataType: undefined,
    },
    {
      field: 'PointOfSale',
      header: 'Punto de venta',
      sortable: true,
      isFilter: true,
      dataType: undefined,
    },
    {
      field: 'AmountTransactionAdditional',
      header: 'Contadores',
      isFilter: false,
      sortable: false,
      dataType: undefined,
      body: rechargesCounterInventory,
    },
    {
      field: 'PcModules',
      header: 'Módulo Pc',
      sortable: true,
      isFilter: true,
      dataType: undefined,
    },
    {
      field: 'Observations',
      header: 'Observaciones',
      sortable: true,
      isFilter: true,
      dataType: undefined,
    },
    {
      field: 'Name',
      header: 'Usuario que registró',
      sortable: true,
      isFilter: true,
      dataType: undefined,
    },
    {
      field: 'CreatedOn',
      header: 'Fecha de alta',
      sortable: true,
      isFilter: true,
      dataType: 'date',
    },
    {
      field: 'SamActivatedBy',
      header: 'Usuario que activó',
      sortable: true,
      isFilter: true,
      dataType: undefined,
    },
    {
      field: 'ActivatedOn',
      header: 'Fecha de activación',
      sortable: true,
      isFilter: true,
      dataType: 'date',
    },
  ];

  return columnsInFilter;
};

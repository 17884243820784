import { useContext, useEffect } from 'react';

import { ToastComponent } from '../../components/ToastComponent';
import {
  GET_TRANSPORTCOMPANIES_CATALOG,
  GET_TRANSPORTCOMPANIES_CATALOG_SUCCESS,
  GET_TRANSPORTCOMPANIES_CATALOG_ERROR,
  POST_TRANSPORTCOMPANIES_CATALOG,
  POST_TRANSPORTCOMPANIES_CATALOG_SUCCESS,
  POST_TRANSPORTCOMPANIES_CATALOG_ERROR,
  PUT_TRANSPORTCOMPANIES_CATALOG,
  PUT_TRANSPORTCOMPANIES_CATALOG_SUCCESS,
  PUT_TRANSPORTCOMPANIES_CATALOG_ERROR,
  DELETE_TRANSPORTCOMPANIES_CATALOG,
  DELETE_TRANSPORTCOMPANIES_CATALOG_SUCCESS,
  DELETE_TRANSPORTCOMPANIES_CATALOG_ERROR,
} from '../../constants/constantsRed';
import { StoreContext } from '../../context/Provider';
import useApi from '../../services/request.service';

export const useTransportCompaniesCatalogs = () => {
  const {
    getTransportCompaniesCatalogState,
    postTransportCompaniesCatalogState,
    putTransportCompaniesCatalogState,
    deleteTransportCompaniesCatalogState
  } = useContext(StoreContext);

  const { genericApiConfiguration } = useApi();

  const getTransportCompaniesCatalog = async () => {
    getTransportCompaniesCatalogState.dispatch({
      type: GET_TRANSPORTCOMPANIES_CATALOG,
    });
    try {

      const result: any = await genericApiConfiguration(
        '/transport-companies',
        'GET',
        {},
        {},
        {}
      );
      if (result.status === 200) {
        const payload = result.data.message;
        getTransportCompaniesCatalogState.dispatch({
          type: GET_TRANSPORTCOMPANIES_CATALOG_SUCCESS,
          payload: payload,
        });
      }
      return result;
    } catch (error: any) {
      ToastComponent(
        'warning',
        'Error',
        'La operación falló, inténtelo más tarde por favor.'
      );
      getTransportCompaniesCatalogState.dispatch({
        type: GET_TRANSPORTCOMPANIES_CATALOG_ERROR,
        payload: error.message,
      });
      return 'error';
    }
  };

  const postTransportCompaniesCatalog = async (data: any) => {
    postTransportCompaniesCatalogState.dispatch({
      type: POST_TRANSPORTCOMPANIES_CATALOG,
    });
    try {
      const result: any = await genericApiConfiguration(
        '/transport-companies',
        'POST',
        data,
        {},
        {}
      );
      if (result.status === 200) {
        ToastComponent('success', '', 'Se ha añadido el registro correctamente');
        const payload = result.data.payload;
        postTransportCompaniesCatalogState.dispatch({
          type: POST_TRANSPORTCOMPANIES_CATALOG_SUCCESS,
          payload: payload,
        });
      }
      return result;
    } catch (error: any) {
      postTransportCompaniesCatalogState.dispatch({
        type: POST_TRANSPORTCOMPANIES_CATALOG_ERROR,
        payload: error.message,
      });
      return 'error';
    }
  };

  const putTransportCompaniesCatalog = async (data: any) => {
    putTransportCompaniesCatalogState.dispatch({
      type: PUT_TRANSPORTCOMPANIES_CATALOG,
    });
    try {
      const result: any = await genericApiConfiguration(
        '/transport-companies',
        'PUT',
        data,
        {},
        {}
      );
      if (result.status === 200) {
        ToastComponent('success', '', 'Se ha editado el registro correctamente');
        const payload = result.data.payload;
        putTransportCompaniesCatalogState.dispatch({
          type: PUT_TRANSPORTCOMPANIES_CATALOG_SUCCESS,
          payload: payload,
        });
      }
      return result;
    } catch (error: any) {
      putTransportCompaniesCatalogState.dispatch({
        type: PUT_TRANSPORTCOMPANIES_CATALOG_ERROR,
        payload: error.message,
      });
      return 'error';
    }
  };

  const deleteTransportCompaniesCatalog = async (id: string) => {
    deleteTransportCompaniesCatalogState.dispatch({
      type: DELETE_TRANSPORTCOMPANIES_CATALOG,
    });
    try {
      const result: any = await genericApiConfiguration(
        '/transport-companies/{id}',
        'DELETE',
        {},
        { ids: id },
        {}
      );

      if (result.status === 200) {
        ToastComponent(
          'success',
          'Succes',
          'Se eliminó correctamente el registro.'
        );
        const payload = result.data.payload;
        deleteTransportCompaniesCatalogState.dispatch({
          type: DELETE_TRANSPORTCOMPANIES_CATALOG_SUCCESS,
          payload: payload,
        });
      }
      return result;
    } catch (error: any) {
      deleteTransportCompaniesCatalogState.dispatch({
        type: DELETE_TRANSPORTCOMPANIES_CATALOG_ERROR,
        payload: error.message,
      });
      return 'error';
    }
  };

  return {
    getTransportCompaniesCatalog,
    postTransportCompaniesCatalog,
    putTransportCompaniesCatalog,
    deleteTransportCompaniesCatalog,
  };
};

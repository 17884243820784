
import { useContext } from 'react';

import { ToastComponent } from '../../components/ToastComponent';
import {
    GET_COUNTER_BY_SAMTYPE,
    GET_COUNTER_BY_SAMTYPE_SUCCESS,
    GET_COUNTER_BY_SAMTYPE_ERROR,
    GET_COUNTER_BY_SAMID,
    GET_COUNTER_BY_SAMID_SUCCESS,
    GET_COUNTER_BY_SAMID_ERROR,
} from '../../constants/constantsRed';
import { StoreContext } from '../../context/Provider';
import useApi from '../../services/request.service';



export const useCounterFromSamType = () => {
    const {countersFromSamTypeStateContext,countersFromSamIdStateContext} = useContext(StoreContext);
    const {genericApiCallApiBilling} = useApi();

    const getCounterBySamType = async(samtypeId :any, oneSelected : boolean, id:any) =>{
        countersFromSamTypeStateContext.dispatch({type: GET_COUNTER_BY_SAMTYPE});
        try {
          const urlQuery = `/v1.0/counters/`+samtypeId
          const result: any = await genericApiCallApiBilling(urlQuery, 'GET', {}, {}, {});
          const payload = result.data;
          if(result.status === 200){
            countersFromSamTypeStateContext.dispatch({type: GET_COUNTER_BY_SAMTYPE_SUCCESS, payload: payload})
            if(oneSelected){
              getCounterBySamId(id)
            }
          }
          else
          countersFromSamTypeStateContext.dispatch({type: GET_COUNTER_BY_SAMTYPE_ERROR,payload : []})
        } catch (error: any) {
          ToastComponent(
            'warning',
            'Error',
            error.message,
            6000
          );
          countersFromSamTypeStateContext.dispatch({type: GET_COUNTER_BY_SAMTYPE_ERROR})
        }
      }

      
    const getCounterBySamId = async(samId :any) =>{
        countersFromSamIdStateContext.dispatch({type: GET_COUNTER_BY_SAMID});
        try {
          const urlQuery = `/v1.0/counters-sam/`+samId
          const result: any = await genericApiCallApiBilling(urlQuery, 'GET', {}, {}, {});
          const payload = result.data;
          if(result.status === 200){
            countersFromSamIdStateContext.dispatch({type: GET_COUNTER_BY_SAMID_SUCCESS, payload: payload})
          }
          else
          countersFromSamIdStateContext.dispatch({type:GET_COUNTER_BY_SAMID_ERROR,payload : []})
        } catch (error: any) {
          ToastComponent(
            'warning',
            'Error',
            error.message,
            6000
          );
          countersFromSamIdStateContext.dispatch({type: GET_COUNTER_BY_SAMID_ERROR})
        }
      }
      return { getCounterBySamType,getCounterBySamId };
}

import { createContext, useState } from 'react';

interface interfaceContext {
  prevetCloseRightMenu: boolean;
  changeStatusCloseMenu: any;
}

export const LayoutContext = createContext<interfaceContext>(
  {} as interfaceContext
);

export const LayoutContextProvider = (props: any) => {
  /**
   * Este estado controla si el menu de la derecha puede cerrarse
   *  por la funcion de hacer click fuera de su entorno
   */
  const [prevetCloseRightMenu, setPrevetCloseRightMenu] =
    useState<boolean>(false);

  /**
   * Cambiar el valor al estado  prevetCloseRightMenu
   * @param option Valor que se le asigna al estado que controla la prevencion del cerrado del menu
   */
  const changeStatusCloseMenu = (option: boolean) => {
    setPrevetCloseRightMenu(option);
  };

  return (
    <LayoutContext.Provider
      value={{
        prevetCloseRightMenu,
        changeStatusCloseMenu,
      }}>
      {props.children}
    </LayoutContext.Provider>
  );
};

import { useContext } from 'react';

import { ToastComponent } from '../../components/ToastComponent';
import {
  GET_OPERATORS_CATALOG,
  GET_OPERATORS_CATALOG_SUCCESS,
  GET_OPERATORS_CATALOG_ERROR,
} from '../../constants/constantsRed';
import { StoreContext } from '../../context/Provider';
import useApi from '../../services/request.service';

export const useCatalogs = () => {
  const context = useContext(StoreContext);
  const { getOperatorsCatalogState } = context;
  const { genericApiConfiguration } = useApi();

  const getOperatorsCatalog = async () => {
    getOperatorsCatalogState.dispatch({ type: GET_OPERATORS_CATALOG });
    try {
      const result: any = await genericApiConfiguration(
        '/operators',
        'GET',
        {},
        {},
        {}
      );
      if (result.status === 200) {
        const payload = result.data.message;
        getOperatorsCatalogState.dispatch({
          type: GET_OPERATORS_CATALOG_SUCCESS,
          payload: payload,
        });
      }
      return result;
    } catch (error: any) {
      ToastComponent(
        'warning',
        'Error',
        'La operación falló, inetnet más tarde por favor.'
      );
      getOperatorsCatalogState.dispatch({
        type: GET_OPERATORS_CATALOG_ERROR,
        payload: error.message,
      });
      return 'error';
    }
  };

  return { getOperatorsCatalog };
};

import {
  GET_COUNTER_BY_SAMID,
  GET_COUNTER_BY_SAMID_ERROR,
  GET_COUNTER_BY_SAMID_SUCCESS,
} from '../../../constants/constantsRed';

export const getCounterBySamId = (state: any = false, actions: any) => {
  switch (actions.type) {
    case GET_COUNTER_BY_SAMID:
      return {
        ...state,
        loading: true,
      };
    case GET_COUNTER_BY_SAMID_ERROR:
      return {
        data: null,
        loading: false,
        error: null,
      };
    case GET_COUNTER_BY_SAMID_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: actions.payload,
      };
    default:
      return state;
  }
};

import { useContext, useEffect, useState } from "react";
import { Filters } from "./Filters"
import { TableComponent } from '../../../components/Table';
import useApi from '../../../services/request.service';
import { Column } from "primereact/column";
import { LOADING_OFF, LOADING_ON } from "../../../constants/constantsRed";
import { StoreContext } from "../../../context/Provider";
import { useUserHistory } from "../../../hooks/userHistory/useUserHistory";



export const TableUserHistory = () => {
    const [filterGlobal, setFilterGlobal] = useState<string>('');
    const [sort, setSort] = useState([{ field: '', order: -1 }]);
    const [loader, setLoader] = useState<boolean>(false);
    const { genericApiCallApiBilling } = useApi();
    const { getUserHistory, exportUserHistory } = useUserHistory();
    const [histories, setHistories] = useState([]);
    const context = useContext(StoreContext);
    const {
      dispatchLoading,
      userHistoryStateContext
    } = context;

    useEffect(()=>{
      getUserHistory('');
    },[])

    useEffect(()=>{
      setHistories(userHistoryStateContext.state.data)
    },[userHistoryStateContext.state])


      let defaultColumns = [
        {
          field: 'userName',
          header: 'Usuario',
          sortable: true,
          width: '100px',
          isFilter: true,
          dataType: undefined,
        },
        {
        field: 'event',
        header: 'Acciones',
        sortable: true,
        width: '100px',
        isFilter: true,
        dataType: undefined,
        }, 
        {
        field: 'app',
        header: 'Tipo de dispositivo',
        sortable: true,
        width: '100px',
        isFilter: true,
        dataType: undefined,
        },
        {
        field: 'createdDateFormatted',
        header: 'Fecha',
        sortable: true,
        width: '100px',
        isFilter: true,
        dataType: undefined,
        }
      ];


      
  const getColumnsToDisplayIntable = () => {
    let columns = defaultColumns.map((column: any, index: any) => {
      const key = index.toString();
      return (
        <Column
          key={key}
          field={column.field}
          filterField={column.field}
          dataType={column.isFilter ? column.dataType : undefined}
          header={column.header}
          bodyClassName={'!text-black'}
          style={{
            flexGrow: 1,
            flexBasis: '100px',
          }}
          headerClassName="  !bg-tableHeader !text-withe!justify-between "
          sortable={column.sortable}
          filterMenuStyle={{ width: '14rem' }}
          filter={false}
          body={column.body}
        />
      );
    });
  
      return columns;
    }
    return (
        <>
            <Filters
            setFilterGlobal={setFilterGlobal}
            setLoader={setLoader}
            dispatchLoading={dispatchLoading}
            getUserHistory={getUserHistory}
            setHistories={setHistories}
            exportUserHistory={exportUserHistory}
            />
        <div className="h-full flex flex-col w-full overflow-y-auto relative ">
            <TableComponent
            value={histories}
            sortField="name"
            responsiveLayout="scroll"
            globalFilterFields={[]}
            resizableColumns
            setSort={setSort}
            sort={sort}
            reorderableColumns={true}
            OnEmptyMessage="No se encontraron registros disponibles."
            loading={loader}
            >
                {getColumnsToDisplayIntable()}
            </TableComponent>
        </div>
    </>)
}
import { useContext, useEffect, useState } from 'react';
import { BsBellFill } from 'react-icons/bs';
import { Link } from 'react-router-dom';

import userIcon from '../../../assets/images/logo_navbar.png';
import { PermitsList } from '../../../permitsList';
import { validatePermissions } from '../../../utils/utils';
import { UserMenu } from '../user-menu/User-menu';
import { StoreContext } from '../../../context/Provider';

interface INavBar {
  userMenuRef: any;
  userButtonRef: any;
  isMenuOpen: boolean;
  setIsMenuOpen: (any: boolean) => void;
  showPopover: boolean;
  setShowPopover: (any: boolean) => void;
}

export const NavBar = (props: INavBar) => {
  const {
    userMenuRef,
    userButtonRef,
    isMenuOpen,
    setIsMenuOpen,
    showPopover,
    setShowPopover,
  } = props;

  const context = useContext(StoreContext);
  const {
    getSettingsState
  } = context;

  const [isAjustesOpen, setisAjustesOpen] = useState(false);
  const [menuSelected, setMenuSelected] = useState('');

  
  const renderImgNav = () => {
    return (
      <div className="flex h-full w-full ">
        <div className="flex w-full h-full  justify-center items-center  content-center  flex-row   ">
          <img src={userIcon} alt="Usuario" className="h-10 ml-10" />
        </div>
      </div>
    );
  };

  const renderOptionsIcons = () => {
    return (
      <>
        <div className="flex w-1/3 md:w-14 h-full justify-center content-center items-center">
          <BsBellFill className="text-[25px] opacity-25" />
        </div>
      </>
    );
  };

  const renderProfileCard = () => {
    return (
      <UserMenu
        refCloseProfileCard={userMenuRef}
        setShowPopover={setShowPopover}
        showPopover={showPopover}
      />
    );
  };

  useEffect(() => {
    let path: string;
    window.location.pathname === '/'
      ? (path = '/inventario')
      : (path = window.location.pathname);

    setMenuSelected(path);
  }, []);

  return (
    <div className="flex z-40 w-full h-16  bg-official_green drop-shadow-md md:pl-1 md:pr-[24px]  lg:pl-1 lg:pr-[24px]  xl:pl-1 xl:pr-[24px]  2xl:pl-1 2xl:pr-[24px]">
      <div className="flex h-full w-full flex-row justify-between ">
        <div className="flex w-[35%] md:w-[15%] lg:w-[10%] xl:w-[10%] 2xl:w-[10%] ">
          {renderImgNav()}
        </div>
        <div className="flex w-[30%] md:w-[55%] lg:w-[65%] xl:w-[70%] 2xl:w-[75%] truncate justify-center content-center items-center gap-8 text-white text-xl">
          <div
            className={
              'h-full flex justify-center items-center hover:text-official_yellow ' +
              (menuSelected === '/inventario' ? 'text-official_yellow' : '')
            }>
            {validatePermissions(PermitsList.SAM_INVENTORY_LIST) ? (
              <Link
                to="/inventario"
                className="cursor-pointer "
                onClick={() => setMenuSelected('/inventario')}>
                Inventario
              </Link>
            ) : (
              <></>
            )}
          </div>
            { getSettingsState?.data?.allowViewHistorySam?
                 <div
                 className={
                   'h-full flex justify-center items-center hover:text-official_yellow ' +
                   (menuSelected === '/history-sam' ? 'text-official_yellow' : '')
                 }>
                   <Link
                     to="/history-sam"
                     className="cursor-pointer "
                     onClick={() => setMenuSelected('/history-sam')}>
                     Historial SAM
                   </Link>
               </div> : <></>
            }
            { /* getSettingsState?.data?.allowViewHistorySam?
                 <div
                 className={
                   'h-full flex justify-center items-center hover:text-official_yellow ' +
                   (menuSelected === '/history-cards' ? 'text-official_yellow' : '')
                 }>
                   <Link
                     to="/history-cards"
                     className="cursor-pointer "
                     onClick={() => setMenuSelected('/history-cards')}>
                     Historial de tarjetas
                   </Link>
               </div> : <></>
            */}
             { getSettingsState?.data?.allowViewHistoryUser? 
              <div
              className={
                'h-full flex justify-center items-center hover:text-official_yellow ' +
                (menuSelected === '/history-user' ? 'text-official_yellow' : '')
              }>
                <Link
                  to="/history-user"
                  className="cursor-pointer "
                  onClick={() => setMenuSelected('/history-user')}>
                  Historial Usuario
                </Link>
            </div> : <></>
            }
          
          <div
            className={
              'h-full flex justify-center items-center hover:text-official_yellow ' +
              (menuSelected === '/catalogos' ? 'text-official_yellow' : '')
            }>
            {validatePermissions(PermitsList.CATALOGS_LIST) ? (
              <Link
                to="/catalogos"
                className="cursor-pointer "
                onClick={() => setMenuSelected('/catalogos')}>
                Catálogos
              </Link>
            ) : (
              <></>
            )}
          </div>
          <div
            className={
              'h-full flex justify-center items-center hover:text-official_yellow ' +
              (menuSelected === '/administrar-usuarios'
                ? 'text-official_yellow'
                : '')
            }>
            {validatePermissions(PermitsList.SEC_USERS_LIST) ? (
              <Link
                to="/administrar-usuarios"
                className="cursor-pointer "
                onClick={() => setMenuSelected('/administrar-usuarios')}>
                Usuarios
              </Link>
            ) : (
              <></>
            )}
          </div>
          <div
            className={
              'h-full flex justify-center items-center hover:text-official_yellow ' +
              (menuSelected === '/roles' ? 'text-official_yellow' : '')
            }>
            {validatePermissions(PermitsList.SEC_ROLE_LIST) ? (
              <Link
                to="/roles"
                className="cursor-pointer "
                onClick={() => setMenuSelected('/roles')}>
                Roles
              </Link>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className="flex w-[35%]  md:w-[40%] lg:w-[25%] xl:w-[20%]  2xl:w-[15%]  ">
          <div ref={userButtonRef} className="flex h-full w-2/3  justify-end ">
            {renderOptionsIcons()}
          </div>
          <div className="flex h-full w-1/3 ">{renderProfileCard()}</div>
        </div>
      </div>
    </div>
  );
};

import { useFormik } from 'formik';
import { MultiSelectChangeEvent } from 'primereact/multiselect';
import { classNames } from 'primereact/utils';
import { useContext, useEffect, useState } from 'react';

import {
  IServiceMenuComponent,
  RoleInterface,
} from '../../../interfaces/interfaceRole';
import { createObjectAsInterface } from '../../../utils/utils';
import { ButtonComponent } from './../../../components/ButtonComponen';
import { InputComponent } from './../../../components/InputComponent';
import { MultiSelectComponent } from './../../../components/MultiSelectComponent';
import { LayoutContext } from './../../../context/LayoutContext';
import { useRoles } from './../../../hooks/usersManager/useRoles';
import { validationsCreateRole, validationsUpdateRole } from './utils';

export const FormRole = (props: IServiceMenuComponent) => {
  const { dataRow, selectMenu, updateTable } = props;

  const objAsAddRoleInterface: RoleInterface = {
    roleName: '',
    permissionValue: '',
  };

  const objAsEditRoleInterface: RoleInterface = {
    roleName: '',
    permissionValue: '',
  };

  const [inputs, setInputs] = useState<RoleInterface>(objAsAddRoleInterface);
  const [roles, setRoles] = useState<any>();
  const [buttonLabel, setButtonLabel] = useState<string>('Agregar rol');

  const context = useContext(LayoutContext);
  const { changeStatusCloseMenu } = context;

  const { getRoles, postRole, patchRole } = useRoles();

  useEffect(() => {
    const getData = async () => {
      let response = await getRoles();
      if (response.status === 200) {
        setRoles(response.data);
      }
    };
    changeStatusCloseMenu(true);
    getData();
  }, []);

  useEffect(() => {
    if (dataRow !== undefined) {
      setButtonLabel('Actualizar rol');
      let objetToCreateInterface =
        selectMenu !== 'Actualizar' ? objAsEditRoleInterface : {};
      let obj = createObjectAsInterface(dataRow, objetToCreateInterface);
      setInputs(obj);
    }
  }, [dataRow]);

  const handleSubmit = async (values: any) => {
    const total_values = values.permissionValue.reduce(
      (accumulator: number, element: number) => accumulator + element,
      0
    );

    const data = {
      roleName: values.roleName,
      //permissionValue: total_values.toString(2),
      permissionValue: total_values.toString(),
    };

    let response: any = {};

    if (selectMenu === 'Actualizar') {
      let id = values.userId;
      delete values.userId;
      response = await patchRole(values, id);
      if (response.status === 200) {
        response.data['roleName'] = dataRow?.roleName;
      }
    } else {
      response = await postRole(data);
    }
    /* if (response.status === 200) {
      updateTable(response.data);
    } */
  };

  const formik = useFormik({
    initialValues: inputs,
    enableReinitialize: true,
    validationSchema:
      selectMenu !== undefined ? validationsUpdateRole : validationsCreateRole,
    onSubmit: data => {
      handleSubmit(data);
    },
  });

  const isFormFieldValid = (name: 'roleName' | 'permissionValue') =>
    !!(formik.touched[name] && formik.errors[name]);

  const getFormErrorMessage = (name: 'roleName' | 'permissionValue') => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };

  const listPermissions:any[] = [];// Hay que conectar el EP con los permisos actuales. En estas versiones el formulario no se usa
  
  const renderMenu = (): any => {
    return (
      <form
        className="h-full w-full flex flex-col pt-5 px-6"
        onSubmit={formik.handleSubmit}>
        <div className="flex flex-col">
          <InputComponent
            label={'Nombre'}
            type={'roleName'}
            name={'roleName'}
            onChange={formik.handleChange}
            value={formik?.values?.['roleName'] || ''}
            placeholder={'Nombre'}
            className={classNames({
              'p-invalid': isFormFieldValid('roleName'),
            })}
          />
          {getFormErrorMessage('roleName')}
        </div>

        <div className="flex flex-col mt-3">
          <MultiSelectComponent
            label="Permisos"
            options={listPermissions}
            optionLabel={'label'}
            value={formik?.values?.['permissionValue'] || ''}
            className="h-12"
            onChange={(e: MultiSelectChangeEvent) => {
              formik.setFieldValue('permissionValue', e.value);
            }}
            selectedItemsLabel={
              formik?.values?.['permissionValue'].length +
              ' permisos seleccionados'
            }
          />
          {getFormErrorMessage('permissionValue')}
        </div>
        <ButtonComponent className="mt-4" label={buttonLabel} type={'submit'} />
      </form>
    );
  };

  return (
    <div className="flex w-full h-full flex-col items-center bg-white  shadow-[-6px_-1px_5px_#00000024] z-10 right-0">
      <div className="flex w-full h-full overflow-y-auto">{renderMenu()}</div>
    </div>
  );
};

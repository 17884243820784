import { useContext } from 'react';

import { ToastComponent } from '../../components/ToastComponent';
import {
  GET_STATIONS_CATALOG,
  GET_STATIONS_CATALOG_SUCCESS,
  GET_STATIONS_CATALOG_ERROR,
  POST_STATIONS_CATALOG,
  POST_STATIONS_CATALOG_SUCCESS,
  POST_STATIONS_CATALOG_ERROR,
  PUT_STATIONS_CATALOG,
  PUT_STATIONS_CATALOG_SUCCESS,
  PUT_STATIONS_CATALOG_ERROR,
  DELETE_STATIONS_CATALOG,
  DELETE_STATIONS_CATALOG_SUCCESS,
  DELETE_STATIONS_CATALOG_ERROR,
} from '../../constants/constantsRed';
import { StoreContext } from '../../context/Provider';
import useApi from '../../services/request.service';

export const useStationsCatalogs = () => {
  const {
    getStationsCatalogState,
    postStationsCatalogState,
    putStationsCatalogState,
    deleteStationsCatalogState
  } = useContext(StoreContext);
  const { genericApiConfiguration } = useApi();

  const getStationsCatalog = async () => {
    getStationsCatalogState.dispatch({ type: GET_STATIONS_CATALOG });
    try {
      const result: any = await genericApiConfiguration(
        '/stations',
        'GET',
        {},
        {},
        {}
      );
      if (result.status === 200) {
        const payload = result.data.message;
        getStationsCatalogState.dispatch({
          type: GET_STATIONS_CATALOG_SUCCESS,
          payload: payload,
        });
      }
      return result;
    } catch (error: any) {
      ToastComponent(
        'warning',
        'Error',
        'La operación falló, inténtelo más tarde por favor.'
      );
      getStationsCatalogState.dispatch({
        type: GET_STATIONS_CATALOG_ERROR,
        payload: error.message,
      });
      return 'error';
    }
  };

  const postStationsCatalog = async (data: any) => {
    postStationsCatalogState.dispatch({ type: POST_STATIONS_CATALOG });
    try {
      const result: any = await genericApiConfiguration(
        '/stations',
        'POST',
        data,
        {},
        {}
      );
      if (result.status === 200) {
        ToastComponent('success', '', 'Se ha añadido el registro correctamente');
        const payload = result.data.payload;
        postStationsCatalogState.dispatch({
          type: POST_STATIONS_CATALOG_SUCCESS,
          payload: payload,
        });
      }
      return result;
    } catch (error: any) {
      postStationsCatalogState.dispatch({
        type: POST_STATIONS_CATALOG_ERROR,
        payload: error.message,
      });
      return 'error';
    }
  };

  const putStationsCatalog = async (data: any) => {
    putStationsCatalogState.dispatch({ type: PUT_STATIONS_CATALOG });
    try {
      const result: any = await genericApiConfiguration(
        '/stations',
        'PUT',
        data,
        {},
        {}
      );
      if (result.status === 200) {
        ToastComponent('success', '', 'Se ha editado el registro correctamente');
        const payload = result.data.payload;
        putStationsCatalogState.dispatch({
          type: PUT_STATIONS_CATALOG_SUCCESS,
          payload: payload,
        });
      }
      return result;
    } catch (error: any) {
      putStationsCatalogState.dispatch({
        type: PUT_STATIONS_CATALOG_ERROR,
        payload: error.message,
      });
      return 'error';
    }
  };

  const deleteStationsCatalog = async (id: string) => {
    deleteStationsCatalogState.dispatch({ type: DELETE_STATIONS_CATALOG });
    try {
      const result: any = await genericApiConfiguration(
        '/stations/{id}',
        'DELETE',
        {},
        { ids: id },
        {}
      );

      if (result.status === 200) {
        ToastComponent(
          'success',
          'Succes',
          'Se eliminó correctamente el registro.'
        );
        const payload = result.data.payload;
        deleteStationsCatalogState.dispatch({
          type: DELETE_STATIONS_CATALOG_SUCCESS,
          payload: payload,
        });
      }
      return result;
    } catch (error: any) {
      deleteStationsCatalogState.dispatch({
        type: DELETE_STATIONS_CATALOG_ERROR,
        payload: error.message,
      });
      return 'error';
    }
  };

  return {
    getStationsCatalog,
    postStationsCatalog,
    putStationsCatalog,
    deleteStationsCatalog,
  };
};

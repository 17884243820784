
export const OptionPie = () =>{
    return (
        {
            responsive: true,
            maintainAspectRatio: false, 
            plugins: {
                legend: {
                    labels: {
                        usePointStyle: true
                    }
                }
            },
        }
    )
}
import { useContext } from 'react';

import { ToastComponent } from '../../components/ToastComponent';
import {
  DELETE_USER,
  DELETE_USER_ERROR,
  DELETE_USER_SUCCESS,
  GET_USERLIST,
  GET_USERLIST_ERROR,
  GET_USERLIST_SUCCESS,
  PATCH_USER,
  PATCH_USER_ERROR,
  PATCH_USER_SUCCESS,
  POST_USERLIST,
  POST_USERLIST_ERROR,
  POST_USERLIST_SUCCESS,
} from '../../constants/constantsRed';
import { StoreContext } from '../../context/Provider';
import useApi from '../../services/request.service';

export const useUserManager = () => {
  const context = useContext(StoreContext);
  const {
    dispatchGetUserManager,
    dispatchPostUserManager,
    dispatchPatchUserManager,
    dispatchDeleteUserManager,
  } = context;
  const { genericApiCall } = useApi();

  const getUserManager = async () => {
    dispatchGetUserManager({ type: GET_USERLIST });
    try {
      const result: any = await genericApiCall('/users', 'GET', {}, {}, {});
      if (result.status === 200) {
        const payload = result.data;
        dispatchGetUserManager({
          type: GET_USERLIST_SUCCESS,
          payload: payload,
        });
      }
      return result;
    } catch (error: any) {
      ToastComponent(
        'warning',
        'Error',
        'La operación falló, inténtelo más tarde por favor.'
      );
      dispatchGetUserManager({
        type: GET_USERLIST_ERROR,
        payload: error.message,
      });
      return 'error';
    }
  };

  const postUserManager = async (data: any) => {
    dispatchPostUserManager({ type: POST_USERLIST });
    try {
      const result: any = await genericApiCall('/users', 'POST', data, {}, {});
      if (result.status === 200) {
        const payload = result.data.payload;
        dispatchPostUserManager({
          type: POST_USERLIST_SUCCESS,
          payload: payload,
        });
        ToastComponent('success', '', 'Usuario agregado con éxito.');
      }
      return result;
    } catch (error: any) {
      dispatchPostUserManager({
        type: POST_USERLIST_ERROR,
        payload: error.message,
      });
      return 'error';
    }
  };

  const patchUserManager = async (data: any, id: string) => {
    dispatchPatchUserManager({ type: PATCH_USER });
    try {
      const result: any = await genericApiCall(
        '/users/' + id,
        'PATCH',
        data,
        {},
        {}
      );
      if (result.status === 200) {
        const payload = result.data.payload;
        dispatchPatchUserManager({
          type: PATCH_USER_SUCCESS,
          payload: payload,
        });
        await getUserManager();
        ToastComponent('success', '', 'Usuario editado con éxito.');
      }
      return result;
    } catch (error: any) {
      ToastComponent(
        'warning',
        'Error',
        'La operación falló, inténtelo más tarde por favor.'
      );
      dispatchPatchUserManager({
        type: PATCH_USER_ERROR,
        payload: error.message,
      });
      return 'error';
    }
  };

  const deleteUserManager = async (id: string) => {
    dispatchDeleteUserManager({ type: DELETE_USER });
    try {
      const result: any = await genericApiCall(
        '/users/' + id,
        'DELETE',
        {},
        {},
        {}
      );
      if (result.status === 204) {
        const payload = result.data.payload;
        dispatchDeleteUserManager({
          type: DELETE_USER_SUCCESS,
          payload: payload,
        });
        ToastComponent('success', '', 'Usuario eliminado con éxito.');
      }
      return result;
    } catch (error: any) {
      let message = 'La operación falló, inténtelo más tarde por favor.';
      if (error.status === 400) message = error.message;
      ToastComponent('warning', 'Error', message);
      dispatchDeleteUserManager({
        type: DELETE_USER_ERROR,
        payload: error.message,
      });
      return 'error';
    }
  };

  return {
    getUserManager,
    postUserManager,
    patchUserManager,
    deleteUserManager,
  };
};

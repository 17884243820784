import { useContext } from 'react';
import { BsBellFill } from 'react-icons/bs';
import { Link } from 'react-router-dom';

import userIcon from '../../../assets/images/logo_navbar.png';
import { StoreContext } from '../../../context/Provider';
import { PermitsList } from '../../../permitsList';
import { HeaderServiceManager } from '../../../serviceContextManager/layout/HeaderServiceManager';
import { validatePermissions } from '../../../utils/utils';
import { FormProfileDataModal } from '../FormProfileDataModal';
import { UserMenu } from '../user-menu/User-menu';

export const Header = (props: any) => {
  const {} = props;

  const context = useContext(StoreContext);
  const { getSettingsState } = context;

  const serviceHeader = useContext(HeaderServiceManager);

  const renderImgNav = () => {
    return (
      <div className="flex w-[35%] md:w-[15%] lg:w-[10%] xl:w-[10%] 2xl:w-[10%] ">
        <div className="flex h-full w-full ">
          <div className="flex w-full h-full  justify-center items-center  content-center  flex-row   ">
            <img src={userIcon} alt="Usuario" className="h-10 ml-10" />
          </div>
        </div>
      </div>
    );
  };

  const renderOptionsIcons = () => {
    return (
      <div
        ref={serviceHeader.userButtonRef}
        className="flex h-full w-2/3  justify-end ">
        <div className="flex w-1/3 md:w-14 h-full justify-center content-center items-center">
          <BsBellFill className="text-[25px] opacity-25" />
        </div>
      </div>
    );
  };

  const renderProfileCard = () => {
    return (
      <div className="flex h-full w-1/3 ">
        <UserMenu
          refCloseProfileCard={serviceHeader.userMenuRef}
          setShowPopover={serviceHeader.setShowPopover}
          showPopover={serviceHeader.showPopover}
          serviceHeader={serviceHeader}
        />
      </div>
    );
  };

  const renderOptionsMenu = () => {
    return (
      <div className="flex w-[30%] md:w-[55%] lg:w-[65%] xl:w-[70%] 2xl:w-[75%] truncate justify-center content-center items-center gap-8 text-white text-xl">
        <div
          className={
            'h-full flex justify-center items-center hover:text-official_yellow ' +
            (serviceHeader.menuSelected === '/inventario'
              ? 'text-official_yellow'
              : '')
          }>
          {validatePermissions(PermitsList.SAM_INVENTORY_LIST) ? (
            <Link
              to="/inventario"
              className="cursor-pointer "
              onClick={() => serviceHeader.setMenuSelected('/inventario')}>
              Inventario
            </Link>
          ) : (
            <></>
          )}
        </div>
        {getSettingsState?.data?.allowViewHistorySam ? (
          <div
            className={
              'h-full flex justify-center items-center hover:text-official_yellow ' +
              (serviceHeader.menuSelected === '/history-sam'
                ? 'text-official_yellow'
                : '')
            }>
            <Link
              to="/history-sam"
              className="cursor-pointer "
              onClick={() => serviceHeader.setMenuSelected('/history-sam')}>
              Historial SAM
            </Link>
          </div>
        ) : (
          <></>
        )}
        {/*
        getSettingsState?.data?.allowViewHistorySam ? (
          <div
            className={
              'h-full flex justify-center items-center hover:text-official_yellow ' +
              (serviceHeader.menuSelected === '/history-cards'
                ? 'text-official_yellow'
                : '')
            }>
            <Link
              to="/history-cards"
              className="cursor-pointer "
              onClick={() => serviceHeader.setMenuSelected('/history-cards')}>
              Historial de tarjetas
            </Link>
          </div>
        ) : (
          <></>
        )*/
        }
        {getSettingsState?.data?.allowViewHistoryUser ? (
          <div
            className={
              'h-full flex justify-center items-center hover:text-official_yellow ' +
              (serviceHeader.menuSelected === '/history-user'
                ? 'text-official_yellow'
                : '')
            }>
            <Link
              to="/history-user"
              className="cursor-pointer "
              onClick={() => serviceHeader.setMenuSelected('/history-user')}>
              Historial Usuario
            </Link>
          </div>
        ) : (
          <></>
        )}

        <div
          className={
            'h-full flex justify-center items-center hover:text-official_yellow ' +
            (serviceHeader.menuSelected === '/catalogos'
              ? 'text-official_yellow'
              : '')
          }>
          {validatePermissions(PermitsList.CATALOGS_LIST) ? (
            <Link
              to="/catalogos"
              className="cursor-pointer "
              onClick={() => serviceHeader.setMenuSelected('/catalogos')}>
              Catálogos
            </Link>
          ) : (
            <></>
          )}
        </div>
        <div
          className={
            'h-full flex justify-center items-center hover:text-official_yellow ' +
            (serviceHeader.menuSelected === '/administrar-usuarios'
              ? 'text-official_yellow'
              : '')
          }>
          {validatePermissions(PermitsList.SEC_USERS_LIST) ? (
            <Link
              to="/administrar-usuarios"
              className="cursor-pointer "
              onClick={() =>
                serviceHeader.setMenuSelected('/administrar-usuarios')
              }>
              Usuarios
            </Link>
          ) : (
            <></>
          )}
        </div>
        <div
          className={
            'h-full flex justify-center items-center hover:text-official_yellow ' +
            (serviceHeader.menuSelected === '/roles'
              ? 'text-official_yellow'
              : '')
          }>
          {validatePermissions(PermitsList.SEC_ROLE_LIST) ? (
            <Link
              to="/roles"
              className="cursor-pointer "
              onClick={() => serviceHeader.setMenuSelected('/roles')}>
              Roles
            </Link>
          ) : (
            <></>
          )}
        </div>
      </div>
    );
  };

  const renderProfileModal = () => {
    return (
      <FormProfileDataModal
      serviceHeader={serviceHeader}
      />
    );
  };

  return (
    <>
      <div className="flex z-40 w-full h-16  bg-official_green drop-shadow-md md:pl-1 md:pr-[24px]  lg:pl-1 lg:pr-[24px]  xl:pl-1 xl:pr-[24px]  2xl:pl-1 2xl:pr-[24px]">
        <div className="flex h-full w-full flex-row justify-between ">
          {renderImgNav()}
          {renderOptionsMenu()}
          <div className="flex w-[35%]  md:w-[40%] lg:w-[25%] xl:w-[20%]  2xl:w-[15%]  ">
            {renderOptionsIcons()}
            {renderProfileCard()}
          </div>
        </div>
      </div>
      {renderProfileModal()}
    </>
  );
};

import {
  GET_ROLES,
  GET_ROLES_SUCCESS,
  GET_ROLES_ERROR,
} from '../../../constants/constantsRed';

export const getRolesRed = (state: any = false, actions: any) => {
  switch (actions.type) {
    case GET_ROLES:
      return {
        ...state,
        loading: true,
      };
    case GET_ROLES_ERROR:
      return {
        data: null,
        loading: false,
        error: null,
      };
    case GET_ROLES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: actions.payload,
      };
    default:
      return state;
  }
};

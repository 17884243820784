import { Column } from 'primereact/column';
import { useState } from 'react';

import { DialogPopUpComponent } from '../../../components/DialogPopUpComponent';
import { TableComponent } from './../../../components/Table';
import { IInventoryProvider } from '../../../interfaces/service/InventoryServiceManagerInterface';
import { PopUpComponent } from '../../../components/PopUpComponent';
import { ActivePopUp } from '../../../interfaces/interfaceInventory';


interface IWhiteListSamProps {
  serviceInvetory: IInventoryProvider;
}

export const RechargesCounterHistoryModal = (props: IWhiteListSamProps) => {
  const { serviceInvetory } = props;

  const bodyContent = () => {
    return (
      <div className="w-full h-80 mb-8 flex p-6 justify-center relative">
        <TableComponent
          value={serviceInvetory.selectedSamToHistoryRechargeModal}
          // loading={getSamByIdState.loading}
          sortField="name"
          responsiveLayout="scroll"
          globalFilterFields={[]}
          setSort={serviceInvetory.setSort}
          sort={serviceInvetory.sort}
          resizableColumns
          reorderableColumns={true}
          paginator={false}>
          {serviceInvetory.rechargeCounterHistoryColumns.map((column: any, index: any) => {
            const key = index.toString();
            return (
              <Column
                key={key}
                field={column.field}
                header={column.header}
                bodyClassName={'!text-black'}
                style={{
                  flexGrow: 1,
                  flexBasis: '100px',
                }}
                headerClassName="  !bg-tableHeader !text-withe!justify-between "
                sortable={column.sortable}
                filterMenuStyle={{ width: '14rem' }}
                filterElement={column.filterElement}
                filter={column.filter}
                body={column.body}
              />
            );
          })}
        </TableComponent>
      </div>
    );
  };

  return (
    <PopUpComponent
      header={`Historial de contadores para la SAM ${serviceInvetory.samInfo?.SamId}`}
      showPopUp={serviceInvetory.activePopUp === ActivePopUp.RechargeCounterHistory && serviceInvetory.showPopUp}
      onSubmit={() => ({})}
      onClosePopUp={() => serviceInvetory.closePopUp()}
      body={bodyContent()}
      footer={false}
    />
  );
};

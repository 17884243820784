import * as React from 'react';

export default function Edit(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32.181"
      height="32.174"
      viewBox="0 0 32.181 32.174">
      <defs>
        <filter
          id="Trazado_4500"
          width="22.48"
          height="22.565"
          x="5.46"
          y="1.973"
          filterUnits="userSpaceOnUse">
          <feOffset dy="1"></feOffset>
          <feGaussianBlur result="blur" stdDeviation="0.5"></feGaussianBlur>
          <feFlood floodOpacity="0.161"></feFlood>
          <feComposite in2="blur" operator="in"></feComposite>
          <feComposite in="SourceGraphic"></feComposite>
        </filter>
        <filter
          id="Trazado_4501"
          width="9.43"
          height="9.398"
          x="3.708"
          y="16.909"
          filterUnits="userSpaceOnUse">
          <feOffset dy="1"></feOffset>
          <feGaussianBlur result="blur-2" stdDeviation="0.5"></feGaussianBlur>
          <feFlood floodOpacity="0.161"></feFlood>
          <feComposite in2="blur-2" operator="in"></feComposite>
          <feComposite in="SourceGraphic"></feComposite>
        </filter>
        <filter
          id="Trazado_4502"
          width="9.628"
          height="9.591"
          x="20.26"
          y="0"
          filterUnits="userSpaceOnUse">
          <feOffset dy="1"></feOffset>
          <feGaussianBlur result="blur-3" stdDeviation="0.5"></feGaussianBlur>
          <feFlood floodOpacity="0.161"></feFlood>
          <feComposite in2="blur-3" operator="in"></feComposite>
          <feComposite in="SourceGraphic"></feComposite>
        </filter>
        <filter
          id="Rectángulo_594"
          width="32.181"
          height="6.736"
          x="0"
          y="25.438"
          filterUnits="userSpaceOnUse">
          <feOffset dy="1"></feOffset>
          <feGaussianBlur result="blur-4" stdDeviation="0.5"></feGaussianBlur>
          <feFlood floodOpacity="0.161"></feFlood>
          <feComposite in2="blur-4" operator="in"></feComposite>
          <feComposite in="SourceGraphic"></feComposite>
        </filter>
      </defs>
      <g data-name="Grupo 772" transform="translate(1.5 .5)">
        <g data-name="Grupo 716">
          <g
            filter="url(#Trazado_4500)"
            transform="translate(3.708) translate(-5.21 -.5)">
            <path
              fill="#b2b2b2"
              d="M-4203.6-497.358a.447.447 0 01-.067-.039c-.034-.028-.063-.058-.094-.089l-5.365-5.319c-.148-.147-.148-.147 0-.3l13.6-13.712c.142-.144.143-.144.289 0l5.374 5.329c.144.142.144.142 0 .289l-13.6 13.712a.945.945 0 01-.137.129z"
              data-name="Trazado 4500"
              transform="translate(4216.19 519.4)"></path>
          </g>
          <g
            filter="url(#Trazado_4501)"
            transform="translate(3.708) translate(-5.21 -.5)">
            <path
              fill="#b2b2b2"
              d="M-4254.095-130.957c0-.8 0-1.6-.012-2.407a.6.6 0 01.192-.473c.17-.161.336-.326.493-.5.087-.092.141-.093.231 0 .563.565 1.13 1.125 1.7 1.686l3.715 3.681c.135.134.135.134 0 .266-.168.17-.338.337-.5.509a.531.531 0 01-.4.168l-4.914.02a.443.443 0 01-.5-.5 400.17 400.17 0 00-.005-2.45z"
              data-name="Trazado 4501"
              transform="translate(4259.31 151.81)"></path>
          </g>
          <g
            filter="url(#Trazado_4502)"
            transform="translate(3.708) translate(-5.21 -.5)">
            <path
              fill="#b2b2b2"
              d="M-3828.623-567.451a1.528 1.528 0 011.064.419c1.179 1.164 2.36 2.327 3.529 3.5a1.523 1.523 0 01.007 2.136c-.149.161-.312.309-.461.471-.082.09-.136.08-.219 0q-1.308-1.3-2.623-2.6l-2.777-2.754c-.152-.151-.154-.153 0-.307a3.907 3.907 0 01.722-.649 1.679 1.679 0 01.758-.216z"
              data-name="Trazado 4502"
              transform="translate(3851.98 567.95)"></path>
          </g>
        </g>
        <g filter="url(#Rectángulo_594)" transform="translate(-1.5 -.5)">
          <path
            fill="#b2b2b2"
            d="M0 0H29.181V3.736H0z"
            data-name="Rectángulo 594"
            transform="translate(1.5 25.94)"></path>
        </g>
      </g>
    </svg>
  );
}

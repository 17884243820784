import React, { useState } from 'react';
import { ListBox, ListBoxChangeEvent } from 'primereact/listbox';

interface ICityProps {
  options: any[];
  onChange?: any;
  value?: any;
  optionLabel?: string;
  itemTemplate?: any;
}

export const ListBoxComponent = (props: ICityProps) => {
  const { options, onChange, value, optionLabel, itemTemplate } = props;

  return (
    <div className="card flex justify-content-center">
      <ListBox
        value={value}
        onChange={onChange}
        options={options}
        optionLabel={optionLabel}
        itemTemplate={itemTemplate}
        className="w-full md:w-14rem"
      />
    </div>
  );
};

export const PermitsList = {
  SAM_ACTIVITY_LIST_ALL: 1099511627784,
  SAM_INVENTORY_LIST: 1099511627792,
  SAM_INVENTORY_EDIT: 1099511627808,
  SAM_INVENTORY_WL_ADD: 1099511627840,
  SAM_INVENTORY_WL_REMOVE: 1099511627904,
  SAM_INVENTORY_EXPORT: 1099511628032,
  SAM_INVENTORY_INACTIVATE: 1099511628288,
  SAM_INVENTORY_ACTIVATE: 1099511628800,
  SEC_USERS_ADD: 1099513724928,
  SEC_USERS_LIST: 1099515822080,
  SEC_USERS_EDIT: 1099520016384,
  SEC_ROLE_ADD: 1099528404992,
  SEC_ROLE_LIST: 1099545182208,
  SEC_ROLE_DELETE: 1099578736640,
  SEC_ROLE_EDIT: 1099645845504,
  CATALOGS_LIST: 1099780063232,
  CATALOGS_ADD: 1100048498688,
  CATALOGS_DELETE: 1100585369600,
  CATALOGS_EDIT: 1101659111424,
  CATALOGS_IMPORT: 1103806595072,
  CATALOGS_EXPORT: 1108101562368,
};

export const PermitionsWebList: Number[] = [
  5497558138896, //SAM_INVENTORY_LIST
  1099515822080, //SEC_USERS_LIST
  1099545182208, //SEC_ROLE_LIST
  1099780063232, //CATALOGS_LIST
];

import {
  GET_COUNTER_BY_SAMTYPE,
  GET_COUNTER_BY_SAMTYPE_ERROR,
  GET_COUNTER_BY_SAMTYPE_SUCCESS,
} from '../../../constants/constantsRed';

export const getCounterBySamType = (state: any = false, actions: any) => {
  switch (actions.type) {
    case GET_COUNTER_BY_SAMTYPE:
      return {
        ...state,
        loading: true,
      };
    case GET_COUNTER_BY_SAMTYPE_ERROR:
      return {
        data: null,
        loading: false,
        error: null,
      };
    case GET_COUNTER_BY_SAMTYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: actions.payload,
      };
    default:
      return state;
  }
};

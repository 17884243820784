import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

type IDropdowsProps = {
  header?: any;
  body?: any;
  footer?: any;
  show: boolean;
  close: any;
};

export const DialogPopUpComponent = (props: IDropdowsProps) => {
  const [visible, setVisible] = useState<boolean>(false);

  const { header, body, footer, show, close } = props;

  useEffect(() => {
    setVisible(show);
  }, [props]);

  const closeDialogPopUp = () => {
    setVisible(false);
    close(false);
  };

  return (
    <div className="card flex justify-content-center">
      <Dialog
        header={header}
        visible={visible}
        style={{ width: '50vw' }}
        onHide={closeDialogPopUp}
        footer={footer}>
        {body}
      </Dialog>
    </div>
  );
};

import {
  IServiceMenuComponent,
  IPcModules,
} from '@/interfaces/interfaceCatalogs';
import { useFormik } from 'formik';
import { InputSwitchChangeEvent } from 'primereact/inputswitch';
import { classNames } from 'primereact/utils';
import { useEffect, useState } from 'react';

import { ButtonComponent } from '../../../../components/ButtonComponen';
import { InputComponent } from '../../../../components/InputComponent';
import { useRoles } from '../../../../hooks/usersManager/useRoles';
import { useUserManager } from '../../../../hooks/usersManager/useUserManager';
import { createObjectAsInterface } from '../../../../utils/utils';
import { stationValidations } from './validations_form';
import { SwitchComponent } from '../../../../components/SwitchComponent';

export const PcModuleForm = (props: IServiceMenuComponent) => {
  const { dataRow, selectMenu, updateTable } = props;

  /**
   * Crea un objeto vacio para usarse en el initialValues de formik con la interface del formulario
   */
  const objAsTransportCompaniesInterface: IPcModules = {
    pcModuleId: '',
    name: '',
    status: false,
  };

  /**
   * Estado que guarda el objeto creado de acuerdo a la interface del formulario
   */
  const [inputs, setInputs] = useState<IPcModules>(
    objAsTransportCompaniesInterface
  );


  /**
   * Texto del boton.
   * {string} Puede ser Agregar | Actualizar
   */
  const [buttonLabel, setButtonLabel] = useState<string>('Agregar');

  //Hooks

  const { getRoles } = useRoles();
  const { postUserManager, patchUserManager } = useUserManager();


  /**
   * Si es una edicion de un item, se toma el objeto seleccionado y se le da el formato para que se usen los datos en el formik
   */
  useEffect(() => {
    if (dataRow !== undefined) {
      setButtonLabel('Actualizar');
      let obj = createObjectAsInterface(
        dataRow,
        objAsTransportCompaniesInterface
      );
      setInputs(obj);
    }
  }, [dataRow]);

  /**
   * Se manda la peticion al back
   */
  const handleSubmit = async (values: any) => {
    let response: any = {};

    /*     if (selectMenu === 'Actualizar') {
      let id = values.userId;
      delete values.userId;
      response = await patchUserManager(values, id);
      if (response.status === 200) {
        response.data['roleName'] = dataRow?.roleName;
      }
    } else {
      response = await postUserManager(values);
      if (response.status === 200) {
        response.data['roleName'] = response['data']['role']['name'];
      }
    }
    if (response.status === 200) {
      updateTable(response.data);
    } */
  };

  const formik = useFormik({
    initialValues: inputs,
    enableReinitialize: true,
    validationSchema: stationValidations,
    onSubmit: data => {
      handleSubmit(data);
    },
  });

  const isFormFieldValid = (name: 'pcModuleId' | 'name' | 'status') =>
    !!(formik.touched[name] && formik.errors[name]);

  const getFormErrorMessage = (name: 'pcModuleId' | 'name' | 'status') => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };

  const renderMenu = (): any => {
    return (
      <form
        className="h-full w-full flex flex-col pt-5 px-6"
        onSubmit={formik.handleSubmit}>
        <div className="flex flex-col">
          <InputComponent
            label={'Módulo Pc Id'}
            type={'pcModuleId'}
            name={'pcModuleId'}
            onChange={formik.handleChange}
            value={formik?.values?.['pcModuleId'] || ''}
            placeholder={'Módulo pc id'}
            className={classNames({
              'p-invalid': isFormFieldValid('pcModuleId'),
            })}
          />
          {getFormErrorMessage('pcModuleId')}
        </div>

        <div className="flex flex-col">
          <InputComponent
            label={'Nombre'}
            type={'name'}
            name={'name'}
            onChange={formik.handleChange}
            value={formik?.values?.['name'] || ''}
            placeholder={'Nombre'}
            className={classNames({
              'p-invalid': isFormFieldValid('name'),
            })}
          />
          {getFormErrorMessage('name')}
        </div>

        <div className="flex flex-col">
          <SwitchComponent
            checked={formik?.values?.['status'] || 'false'}
            onChange={(e: InputSwitchChangeEvent) => {
              formik.setFieldValue('status', e.value);
            }}
            label={'Estatus'}
            className={classNames({
              'p-invalid': isFormFieldValid('status'),
            })}
          />
          {getFormErrorMessage('status')}
        </div>

        <ButtonComponent className="mt-10" label={buttonLabel} type="submit" />
      </form>
    );
  };

  return (
    <div className="flex w-full h-full flex-col items-center bg-white  shadow-[-6px_-1px_5px_#00000024] z-10 right-0">
      <div className="flex w-full h-full overflow-y-auto">{renderMenu()}</div>
    </div>
  );
};

import * as Yup from 'yup';

export const validationsCreateUser = Yup.object({
  firstName: Yup.string()
    .min(4, 'Debe tener una longitud mayor')
    .required('Campo requerido'),
  lastName: Yup.string()
    .min(4, 'Debe tener una longitud mayor')
    .required('Campo requerido'),
    email: Yup.string().required('Campo requerido'),
    password: Yup.string()
    .required('Campo requerido')
    .min(8, 'La contraseña debe tener al menos 8 caracteres.')
    .matches(
      /(?=.*[a-zA-Z])(?=.*\d)/,
      'El password debe contener al menos una letra minuscula, una mayuscula y un número'
    )
    .matches(
      /(?=.*[@$!%,?"-.°#\/&])/,
      'El password debe contener al menos uno de los siguientes caracteres especiales: @, $, !, %, , , ?, ", -, .°, #, / o &'
    ),
  roleId: Yup.string().required('Campo requerido'),
});

export const validationsUpdateUser = Yup.object({
  firstName: Yup.string()
    .min(4, 'Debe tener una longitud mayor')
    .required('Campo requerido'),
  lastName: Yup.string()
    .min(4, 'Debe tener una longitud mayor')
    .required('Campo requerido'),
});

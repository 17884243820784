import { useContext, useEffect, useState } from "react";
import { Filters } from "./Filters"
import { TableComponent } from '../../../components/Table';
import useApi from '../../../services/request.service';
import { Column } from "primereact/column";
import { LOADING_OFF, LOADING_ON } from "../../../constants/constantsRed";
import { StoreContext } from "../../../context/Provider";



export const TableHistorySam = () => {
  const [filterSamId, setFilterSamId] = useState<string>('');
  const [sort, setSort] = useState([{ field: '', order: -1 }]);
  const [loader, setLoader] = useState<boolean>(false);
  const { genericApiCallApiBilling } = useApi();
  const [histories, setHistories] = useState([]);
  const [temporalHistories, setTemporalHistories] = useState<any[]>([])
  const context = useContext(StoreContext);
  const {
    dispatchLoading,
  } = context;

  useEffect(() => {
    getData();
  }, [])

  useEffect(() => {
    if (filterSamId === '') {
      setTemporalHistories(histories);
    } else {
      let filteredData = histories;
      filteredData = filteredData.filter((data: any) =>
        data?.Sam?.includes(filterSamId)
      )
      setTemporalHistories(filteredData);
    }
  }, [filterSamId]);

  useEffect(() => {
    setTemporalHistories(histories);
  }, [histories])

  const getData = () => {
    const today = new Date();

    const day = String(today.getDate()).padStart(2, '0');
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Los meses empiezan desde 0
    const year = today.getFullYear();

    const formattedDate = `${year}-${month}-${day}`;

    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);

    const dayEnd = String(tomorrow.getDate()).padStart(2, '0');
    const monthEnd = String(tomorrow.getMonth() + 1).padStart(2, '0'); // Los meses empiezan desde 0
    const yearEnd = tomorrow.getFullYear();

    const formattedDateEnd = `${yearEnd}-${monthEnd}-${dayEnd}`;
    const queryRequest = `fromDate=${formattedDate}&toDate=${formattedDateEnd}`;
    let url = `/v1.0/histories?${queryRequest}`;
    dispatchLoading({ type: LOADING_ON });
    setLoader(true)
    genericApiCallApiBilling(url, 'GET', {}, {}, {}).then((response: any) => {

      if (response !== null) {
        setHistories(response.data)
      }

      dispatchLoading({ type: LOADING_OFF });
      setLoader(false)
    }).catch(() => {
      dispatchLoading({ type: LOADING_OFF });
      setLoader(false)
    });
  };

  let defaultColumns = [
    {
      field: 'Sam',
      header: 'SAM ID',
      sortable: true,
      width: '100px',
      isFilter: true,
      dataType: undefined,
    },
    {
      field: 'StatusSam',
      header: 'Estatus',
      sortable: true,
      width: '100px',
      isFilter: true,
      dataType: undefined,
    },
    {
      field: 'DeviceType',
      header: 'Dispositivos',
      sortable: true,
      width: '100px',
      isFilter: true,
      dataType: undefined,
    },
    {
      field: 'Operator',
      header: 'Operadores tecnológicos',
      sortable: true,
      width: '100px',
      isFilter: true,
      dataType: undefined,
    },
    {
      field: 'Eur',
      header: 'EUR',
      sortable: true,
      width: '100px',
      isFilter: true,
      dataType: undefined,
    },
    {
      field: 'Project',
      header: 'Proyectos',
      sortable: true,
      width: '100px',
      isFilter: true,
      dataType: undefined,
    },
    {
      field: 'Description',
      header: 'Descripción',
      sortable: true,
      width: '100px',
      isFilter: true,
      dataType: undefined,
    },
    {
      field: 'User',
      header: 'Usuario',
      sortable: true,
      width: '100px',
      isFilter: true,
      dataType: undefined,
    },
    {
      field: 'Date',
      header: 'Fecha',
      sortable: true,
      width: '100px',
      isFilter: true,
      dataType: undefined,
    },
  ];



  const getColumnsToDisplayIntable = () => {
    let columns = defaultColumns.map((column: any, index: any) => {
      const key = index.toString();
      return (
        <Column
          key={key}
          field={column.field}
          filterField={column.field}
          dataType={column.isFilter ? column.dataType : undefined}
          header={column.header}
          bodyClassName={'!text-black'}
          style={{
            flexGrow: 1,
            flexBasis: '100px',
          }}
          headerClassName="  !bg-tableHeader !text-withe!justify-between "
          sortable={column.sortable}
          filterMenuStyle={{ width: '14rem' }}
          filter={false}
          body={column.body}
        />
      );
    });

    return columns;
  }
  return (
    <>
      <Filters
        setFilterSamId={setFilterSamId}
        setLoader={setLoader}
        dispatchLoading={dispatchLoading}
        genericApiCallApiBilling={genericApiCallApiBilling}
        setHistories={setHistories}
      />
      <div className="h-full flex flex-col w-full overflow-y-auto relative ">
        <TableComponent
          value={temporalHistories}
          sortField="name"
          responsiveLayout="scroll"
          globalFilterFields={[]}
          resizableColumns
          setSort={setSort}
          sort={sort}
          reorderableColumns={true}
          OnEmptyMessage="No se encontraron registros disponibles."
          loading={loader}
        >
          {getColumnsToDisplayIntable()}
        </TableComponent>
      </div>
    </>)
}
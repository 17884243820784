import { useContext, useEffect } from 'react';

import { ToastComponent } from '../../components/ToastComponent';
import {
  GET_SAM_TYPES,
  GET_SAM_TYPES_ERROR,
  GET_SAM_TYPES_SUCCESS,
} from '../../constants/constantsRed';
import { StoreContext } from '../../context/Provider';
import useApi from '../../services/request.service';

export const useSamTypes = () => {
  const { getSamTypesStates } = useContext(StoreContext);

  const { genericApiCallApiBilling } = useApi();

  const getSamTypes = async () => {
    getSamTypesStates.dispatch({ type: GET_SAM_TYPES });
    try {
      const result: any = await genericApiCallApiBilling(
        '/v1.0/sam-types',
        'GET',
        {},
        {},
        {}
      );
      if (result.status === 200) {
        const payload = result.data;
        getSamTypesStates.dispatch({
          type: GET_SAM_TYPES_SUCCESS,
          payload: payload,
        });
      }
      return result;
    } catch (error: any) {
      ToastComponent(
        'warning',
        'Error',
        'La operación falló, inetnet más tarde por favor.'
      );
      getSamTypesStates.dispatch({
        type: GET_SAM_TYPES_ERROR,
        payload: error.message,
      });
      return 'error';
    }
  };

  const fetchAllSamTypes = async () => {
    try {
      await Promise.all([getSamTypes()]);
    } catch (error) {
      console.error("Error fetching sam types", error);
    }
  };

  useEffect(() => {
    if (!getSamTypesStates.state.loading && getSamTypesStates.state.data === null) {
      fetchAllSamTypes();
    }
  }, [getSamTypesStates.state])

  return { getSamTypes };
};

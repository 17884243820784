import {
  GET_SAM_TYPES,
  GET_SAM_TYPES_ERROR,
  GET_SAM_TYPES_SUCCESS,
} from '../../../constants/constantsRed';

export const getSamTypesRed = (state: any, actions: any) => {
  switch (actions.type) {
    case GET_SAM_TYPES:
      return {
        ...state,
        loading: true,
      };
    case GET_SAM_TYPES_ERROR:
      return {
        data: null,
        loading: false,
        error: null,
      };
    case GET_SAM_TYPES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        data: actions.payload,
      };
    default:
      return state;
  }
};

export const DefaultColumns = () =>{
    const bodyVersionOldActiveBoolean = (data:any) => {
        return (<span className="">{data["oldCardVersionActive"] ? "Activo": "Inactivo"}</span>);
    }

    const bodyVersionNewActiveBoolean = (data:any) => {
      return (<span className="">{data["newCardVersionActive"] ? "Activo": "Inactivo"}</span>);
  }

    return(
        [
            {
                field: 'sam',
                header: 'SAM',
                sortable: true,
                width: '100px',
                isFilter: true,
                dataType: undefined,
            },
            {
                field: 'cardNumber',
                header: 'Número de tarjeta',
                sortable: true,
                width: '100px',
                isFilter: true,
                dataType: undefined,
            },
            {
                field: 'device',
                header: 'Dispositivo',
                sortable: true,
                width: '100px',
                isFilter: true,
                dataType: undefined,
            },
              {
                field: 'project',
                header: 'Proyecto',
                sortable: true,
                width: '100px',
                isFilter: true,
                dataType: undefined,
              },
              {
                field: 'transactionDate',
                header: 'Fecha de transacción',
                sortable: true,
                width: '100px',
                isFilter: true,
                dataType: undefined,
              },
              {
                field: 'eventDate',
                header: 'Fecha de evento',
                sortable: true,
                width: '100px',
                isFilter: true,
                dataType: undefined,
              },
              {
                field: 'oldKeyVersion',
                header: 'Versión anterior',
                sortable: true,
                width: '100px',
                isFilter: true,
                dataType: undefined,
              },
              {
                field: 'releaseNewCardVersionDate',
                header: 'Lanzamiento de versión anterior',
                sortable: true,
                width: '100px',
                isFilter: true,
                dataType: undefined,
              },   
              {
                field: 'oldCardVersionActive',
                header: 'Versión anterior activa',
                sortable: true,
                width: '100px',
                isFilter: true,
                dataType: undefined,
                body: bodyVersionOldActiveBoolean
              },  
              {
                field: 'newKeyVersion',
                header: 'Versión nueva',
                sortable: true,
                width: '100px',
                isFilter: true,
                dataType: undefined,
              },   
              {
                field: 'releaseNewCardVersionDate',
                header: 'Lanzamiento de nueva versión',
                sortable: true,
                width: '100px',
                isFilter: true,
                dataType: undefined,
              }, 
              {
                field: 'newCardVersionActive',
                header: 'Versión nueva activa',
                sortable: true,
                width: '100px',
                isFilter: true,
                dataType: undefined,
                body: bodyVersionNewActiveBoolean
              },   


              {
                field: 'status',
                header: 'Estatus',
                sortable: true,
                width: '100px',
                isFilter: true,
                dataType: undefined,
              },  

              {
                field: 'error',
                header: 'Errores',
                sortable: true,
                width: '100px',
                isFilter: true,
                dataType: undefined,
              },  
          ]
    )
}
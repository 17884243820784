import { RadioButton } from 'primereact/radiobutton';

interface RadioButtonProps {
  inputId: string;
  name: string;
  value?: string;
  changeHandler?: any;
  checked?: boolean;
  label?: string;
}

export const RadioButtonComponent = (props: RadioButtonProps) => {
  return (
    <div className="card flex justify-content-center">
      <RadioButton
        inputId={props.inputId}
        name={props.name}
        value={props.value}
        onChange={props.changeHandler}
        checked={props.checked}
      />
      <label htmlFor="ingredient1" className="ml-2">
        {props.label}
      </label>
    </div>
  );
};
